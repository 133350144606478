import React, { useState } from "react";
import "../../styles.scss";
import { CaretLeftIcon } from "../../../../svg-icons";
import { usersActions } from "../../../../../models";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";

const CommentSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const updatedData = (updatedComments) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          commentSection: {
            ...data.commentSection,
            comments: updatedComments,
          },
        },
      })
    );
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Comment Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            placeholder="Title"
            value={data?.commentSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    commentSection: {
                      ...data.commentSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sub Title</label>
          <input
            className="form-control"
            placeholder="Sub Title"
            value={data?.commentSection?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    commentSection: {
                      ...data.commentSection,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <UncontrolledAccordion
          defaultOpen="heading0"
          className="accordion-block-section"
          open={open}
          toggle={toggle}
        >
          {data?.commentSection?.comments?.map((comment, index) => (
            <AccordionItem>
              <AccordionHeader targetId={`heading${index}`}>
                Comment {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`heading${index}`}>
                <>
                  <div className="form-group">
                    <label className="form-label">Rating</label>
                    <input
                      className="form-control"
                      type="text"
                      value={comment.rating}
                      placeholder="Rating 0 to 5"
                      onChange={(e) => {
                        const updatedComments = [
                          ...data.commentSection.comments,
                        ];
                        updatedComments[index] = {
                          ...updatedComments[index],
                          rating: e.target.value,
                        };
                        updatedData(updatedComments);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={comment.comment}
                      placeholder="Enter Comment"
                      onChange={(e) => {
                        const updatedComments = [
                          ...data.commentSection.comments,
                        ];
                        updatedComments[index] = {
                          ...updatedComments[index],
                          comment: e.target.value,
                        };
                        updatedData(updatedComments);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={comment.name}
                      placeholder="Name"
                      onChange={(e) => {
                        const updatedComments = [
                          ...data.commentSection.comments,
                        ];
                        updatedComments[index] = {
                          ...updatedComments[index],
                          name: e.target.value,
                        };
                        updatedData(updatedComments);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={comment.address}
                      placeholder="Enter Address"
                      onChange={(e) => {
                        const updatedComments = [
                          ...data.commentSection.comments,
                        ];
                        updatedComments[index] = {
                          ...updatedComments[index],
                          address: e.target.value,
                        };
                        updatedData(updatedComments);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-xs btn-global-danger"
                    onClick={() => {
                      const updatedComments = [...data.commentSection.comments];
                      updatedComments.splice(index, 1);
                      updatedData(updatedComments);
                    }}
                  >
                    Remove
                  </button>
                </>
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
        <div className="">
          <button
            className="btn btn-xs btn-global-primary"
            onClick={() => {
              const updatedComments = [
                ...data.commentSection.comments,
                {
                  serviceTitle: "",
                  serviceLogo: null,
                  serviceDescription: "",
                },
              ];
              updatedData(updatedComments);
            }}
          >
            Add Comment
          </button>
        </div>
      </div>
    </>
  );
};

export default CommentSection;
