import React, { useState } from "react";
import { CaretLeftIcon } from "../../../svg-icons";
import Dropzone from "react-dropzone";
import "../styles.scss";
const LogoSection = ({ template, handleUpload, setOpenSection }) => {
  const [change, setChange] = useState(false);
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setOpenSection(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Logo</div>
      </div>
      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Logo</h5>
        <p className="e-aside-head-subtitle">300 x 40 recommended</p>
      </div>
      <div className="e-aside-body-content">
        {!change ? (
          <div className="e-preview">
            <div className="e-preview-thumb e-preview-thumb-logo">
              {template?.logo ? (
                <img src={template.logo} alt="Logo" />
              ) : (
                <img
                  src={
                    require("../../../../views/templates/template-1/assets/images/logo.svg")
                      .default
                  }
                  alt="Logo"
                />
              )}
            </div>
            <div className="e-preview-cta">
              <button
                type="button"
                className="btn btn-change"
                onClick={() => setChange(true)}
              >
                Change
              </button>
              <button
                type="button"
                className="btn btn-remove"
                onClick={() => setChange(true)}
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <div className="dropzone dropzone-sm">
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleUpload(acceptedFiles, "logo");
                setChange(false);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone-inner">
                  <div className="dropzone-inner-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-text">
                      Drag & Drop <br /> or
                    </div>
                    <button className="btn btn-dark btn-upload">Upload</button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    </>
  );
};

export default LogoSection;
