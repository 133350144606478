import React from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import { usersActions } from "../../../../../models";
import "../../styles.scss";
const AchievementSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  // const updateData = (updatedIndustries) => {
  //   dispatch(
  //     usersActions.setNavigationData({
  //       key: page,
  //       updatedObject: {
  //         ...data,
  //         industriesSection: {
  //           ...data.industriesSection,
  //           industries: updatedIndustries,
  //         },
  //       },
  //     })
  //   );
  // };

  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Achievement Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            placeholder="Heading"
            value={data?.achievementSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    achievementSection: {
                      ...data.achievementSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Accuracy Data</label>
          <input
            className="form-control"
            placeholder="Accuracy Data"
            value={data?.achievementSection?.accuracy}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    achievementSection: {
                      ...data.achievementSection,
                      accuracy: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Global Users</label>
          <input
            className="form-control"
            placeholder="Global Users"
            value={data?.achievementSection?.globalUsers}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    achievementSection: {
                      ...data.achievementSection,
                      globalUsers: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Accuracy Data In Millions</label>
          <input
            className="form-control"
            placeholder="Accuracy Data In Millions"
            value={data?.achievementSection?.accuracyMill ?? ""}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    achievementSection: {
                      ...data.achievementSection,
                      accuracyMill: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        {/* <button
          className="btn btn-xs btn-global-primary"
          onClick={() => {
            const updatedIndustries = [
              ...data.industriesSection.industries,
              {
                question: "",
                answer: "",
              },
            ];
            updateData(updatedIndustries);
          }}
        >
          Add Resource
        </button> */}
      </div>
    </>
  );
};

export default AchievementSection;
