import Cookies from "universal-cookie";
import { ApolloLink } from "@apollo/client";

const authMiddleware = new ApolloLink((operation, forward) => {
  const cookies = new Cookies();
  //   const tokenKey = process.env.NX_IS_STAGING === "true" ? "stg-token" : "token";
  //add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: "Bearer " + cookies.get("stg-token") || null,
    },
  }));

  return forward(operation);
});

export default authMiddleware;
