import React from "react";
import { CaretRightIcon } from "../../../svg-icons";
import "../styles.scss";
import LogoSection from "./LogoSection";
import FaviconSection from "./FaviconSection";
import NavigationSection from "./NavigationSection";
import FooterSection from "./FooterSection";
const MenuSection = ({
  setOpenSection,
  openSection,
  handleUpload,
  template,
  dispatch,
}) => {
  return (
    <>
      {openSection === null && (
        <div className="e-aside-links">
          <button
            type="button"
            className="btn e-aside-link"
            onClick={() => setOpenSection("Logo")}
          >
            <div className="e-aside-link-title">Logo</div>
            <div className="e-aside-link-icon">
              <CaretRightIcon />
            </div>
          </button>
          <button
            type="button"
            className="btn e-aside-link"
            onClick={() => setOpenSection("Favicon")}
          >
            <div className="e-aside-link-title">Favicon</div>
            <div className="e-aside-link-icon">
              <CaretRightIcon />
            </div>
          </button>
          <button
            type="button"
            className="btn e-aside-link"
            onClick={() => setOpenSection("Navigation")}
          >
            <div className="e-aside-link-title">Navigation</div>
            <div className="e-aside-link-icon">
              <CaretRightIcon />
            </div>
          </button>
          <button
            type="button"
            className="btn e-aside-link"
            onClick={() => setOpenSection("Footer")}
          >
            <div className="e-aside-link-title">Footer</div>
            <div className="e-aside-link-icon">
              <CaretRightIcon />
            </div>
          </button>
        </div>
      )}
      {openSection === "Logo" && (
        <LogoSection
          template={template}
          handleUpload={handleUpload}
          setOpenSection={setOpenSection}
        />
      )}

      {openSection === "Favicon" && (
        <FaviconSection
          template={template}
          handleUpload={handleUpload}
          setOpenSection={setOpenSection}
        />
      )}

      {openSection === "Navigation" && (
        <NavigationSection
          dispatch={dispatch}
          template={template}
          setOpenSection={setOpenSection}
        />
      )}
      {openSection === "Footer" && (
        <FooterSection
          dispatch={dispatch}
          handleUpload={handleUpload}
          template={template}
          setOpenSection={setOpenSection}
        />
      )}
    </>
  );
};

export default MenuSection;
