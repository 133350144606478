// import env from "../../../../config";
import {
  ApolloClient,
  HttpLink,
  gql,
  InMemoryCache,
  concat,
} from "@apollo/client";
import authMiddleware from "./middleware/authMiddleware";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_AUTH_API}/graphql`,
});

const userClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});
//old

const getWorkflowRunsByRepoFullName = gql`
  query getWorkflowRunsByRepoFullName(
    $workspaceId: String!
    $repoFullName: String!
  ) {
    getWorkflowRunsByRepoFullName(
      workspaceId: $workspaceId
      repoFullName: $repoFullName
    ) {
      title
      githubUserId {
        _id
      }
      repositoryId {
        _id
      }
      repoFullName
      branch
      repoName
      commitId
      isPaused
      isRepoLinked
      commits {
        _id
        buildMinutes

        repositoryId {
          _id
        }
        actor {
          username
          avatar
        }
        buildMinutes
        name
        repoFullName
        branch
        branchUrl
        commitUrl
        commitHash
        commitId
        commitName
        conclusion
        isPaused
        createdAt
        updatedAt
      }
    }
  }
`;

const listAuthUserRepos = gql`
  mutation listAuthUserRepos($userAccount: String!, $workspaceId: String!) {
    listAuthUserRepos(userAccount: $userAccount, workspaceId: $workspaceId) {
      owner
      avatar
      type
      repos {
        _id
        userAccount
        full_name
        default_branch
        name
        isRepoLinked
        commitsCount
        latestCommit
        githubUserId {
          _id
        }
        owner {
          login
          avatar_url
          type
        }
        repoUrl
        pushed_at
      }
    }
  }
`;

export const userService = {
  userClient,
  userQuery: {
    getWorkflowRunsByRepoFullName,
    listAuthUserRepos,
  },
};
