import React, { useState } from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { usersActions } from "../../../../../models";
import "../../styles.scss";
const ServiceSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const updateData = (updatedServices) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          serviceSection: {
            ...data.serviceSection,
            services: updatedServices,
          },
        },
      })
    );
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Service Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            placeholder="Title"
            value={data?.serviceSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    serviceSection: {
                      ...data.serviceSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sub Title</label>
          <input
            className="form-control"
            placeholder="Sub Title"
            value={data?.serviceSection?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    serviceSection: {
                      ...data.serviceSection,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <UncontrolledAccordion
          defaultOpen="heading0"
          className="accordion-block-section"
          open={open}
          toggle={toggle}
        >
          {data?.serviceSection?.services?.map((service, index) => (
            <AccordionItem>
              <AccordionHeader targetId={`heading${index}`}>
                Service {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`heading${index}`}>
                <>
                  <div className="form-group">
                    <label className="form-label">Service Title</label>
                    <input
                      className="form-control"
                      // type="text"
                      value={service.serviceTitle}
                      placeholder="Service Title"
                      onChange={(e) => {
                        const updatedServices = [
                          ...data.serviceSection.services,
                        ];
                        updatedServices[index] = {
                          ...updatedServices[index],
                          serviceTitle: e.target.value,
                        };
                        updateData(updatedServices);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Service Description</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={service.serviceDescription}
                      placeholder="Service Description"
                      onChange={(e) => {
                        const updatedServices = [
                          ...data.serviceSection.services,
                        ];
                        updatedServices[index] = {
                          ...updatedServices[index],
                          serviceDescription: e.target.value,
                        };
                        updateData(updatedServices);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-xs btn-global-danger"
                    onClick={() => {
                      const updatedServices = [...data.serviceSection.services];
                      updatedServices.splice(index, 1);
                      updateData(updatedServices);
                    }}
                  >
                    Remove
                  </button>
                </>
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
        <div className="">
          <button
            className="btn btn-global-primary"
            onClick={() => {
              const updatedServices = [
                ...data.serviceSection.services,
                {
                  serviceTitle: "",
                  serviceLogo: null,
                  serviceDescription: "",
                },
              ];
              updateData(updatedServices);
            }}
          >
            Add Service
          </button>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
