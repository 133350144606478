import React, { useState } from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import { BlogData, uploadFile, usersActions } from "../../../../../models";
import "../../styles.scss";
import Dropzone from "react-dropzone";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { htmlToText } from "html-to-text";
import { Modal } from "antd";
const ResourceSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [resourceImage, setResourceImage] = useState(-1);
  const updateData = (updatedResources) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          resourceSection: {
            ...data.resourceSection,
            resources: updatedResources,
          },
        },
      })
    );
  };
  const handleUpload = (acceptedFiles, index) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const imageData = await uploadFile(file);
      const updatedResources = [...data.resourceSection.resources];
      updatedResources[index] = {
        ...updatedResources[index],
        image: imageData,
      };
      updateData(updatedResources);
    };
    reader.readAsDataURL(file);
  };
  const getContent = (content) => {
    const text = htmlToText(content, {
      wordwrap: 130,
    });
    const lines = text.split("\n").filter((line) => line.trim() !== "");
    const top3Lines = lines.slice(0, 3);
    return `${top3Lines.join("\n")}...`;
  };
  const syncBlog = () => {
    Modal.confirm({
      title: "Confirm Blog Sync",
      content:
        "Would you like to proceed with syncing the blog? Please note that this action will reset the existing blog entries.",
      okText: "Sync",
      cancelText: "Cancel",
      cancelButtonProps: {
        type: "default",
      },
      okButtonProps: {
        type: "primary",
      },
      onOk: async () => {
        // console.log("resetting template");
        const blogData = await BlogData();
        let blog = [];
        blogData.forEach((item, index) => {
          blog[index] = {
            // Initialize the object at the current index
            title: item.title,
            shortDesc: getContent(item.content),
            image: item.image_url,
            buttonName: "Read More",
            buttonUrl: `https://linkites.com/${item.slug}/`,
          };
        });
        dispatch(
          usersActions.setNavigationData({
            key: page,
            updatedObject: {
              ...data,
              resourceSection: {
                ...data.resourceSection,
                resources: blog,
              },
            },
          })
        );
      },
      onCancel: () => {},
    });
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Blogs Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group d-flex justify-content-between align-items-center">
          <label className="form-label">Sync Linkites Blog</label>
          <button
            className="btn btn-xs btn-global-primary"
            onClick={() => {
              syncBlog();
            }}
          >
            Sync Now
          </button>
        </div>
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            placeholder="Title"
            value={data?.resourceSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    resourceSection: {
                      ...data.resourceSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sub Title</label>
          <input
            className="form-control"
            placeholder="Sub Title"
            value={data?.resourceSection?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    resourceSection: {
                      ...data.resourceSection,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>

        <UncontrolledAccordion
          defaultOpen="heading0"
          className="accordion-block-section"
          open={open}
          toggle={toggle}
        >
          {data?.resourceSection?.resources?.map((resource, index) => (
            <AccordionItem>
              <AccordionHeader targetId={`heading${index}`}>
                Block {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`heading${index}`}>
                <>
                  <div className="form-group">
                    <label className="form-label">Resource Title</label>
                    <input
                      className="form-control"
                      type="text"
                      value={resource.title}
                      placeholder="Title"
                      onChange={(e) => {
                        const updatedResources = [
                          ...data.resourceSection.resources,
                        ];
                        updatedResources[index] = {
                          ...updatedResources[index],
                          title: e.target.value,
                        };
                        updateData(updatedResources);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Short Description</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      value={resource.shortDesc}
                      placeholder="Short Description"
                      onChange={(e) => {
                        const updatedResources = [
                          ...data.resourceSection.resources,
                        ];
                        updatedResources[index] = {
                          ...updatedResources[index],
                          shortDesc: e.target.value,
                        };
                        updateData(updatedResources);
                      }}
                    />
                  </div>
                  <div className="e-aside-head p-0">
                    <h5 className="e-aside-head-title">Resource Image</h5>
                    <p className="e-aside-head-subtitle">
                      373 x 268 recommended
                    </p>
                  </div>
                  <div className="form-group mb-3">
                    {resourceImage !== index && resource?.image ? (
                      <div className="e-preview">
                        <div className="e-preview-thumb e-preview-thumb-logo">
                          <img src={resource?.image} alt="Resource img" />
                        </div>
                        <div className="e-preview-cta">
                          <button
                            type="button"
                            className="btn btn-change"
                            onClick={() => setResourceImage(index)}
                          >
                            Change
                          </button>
                          <button
                            type="button"
                            className="btn btn-remove"
                            onClick={() => setResourceImage(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="dropzone dropzone-sm">
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleUpload(acceptedFiles, index);
                            setResourceImage(-1);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="dropzone-inner">
                              <div
                                className="dropzone-inner-area"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="upload-text">
                                  Drag & Drop <br /> or
                                </div>
                                <button className="btn btn-dark btn-upload">
                                  Upload
                                </button>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">Learn More</label>
                    <input
                      className="form-control"
                      placeholder="Learn More"
                      value={resource?.buttonName ?? "Read More"}
                      onChange={(e) => {
                        const updatedResources = [
                          ...data.resourceSection.resources,
                        ];
                        updatedResources[index] = {
                          ...updatedResources[index],
                          buttonName: e.target.value,
                        };
                        updateData(updatedResources);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Learn More Url</label>
                    <input
                      className="form-control"
                      placeholder="Button Url"
                      value={resource?.buttonUrl ?? ""}
                      onChange={(e) => {
                        const updatedResources = [
                          ...data.resourceSection.resources,
                        ];
                        updatedResources[index] = {
                          ...updatedResources[index],
                          buttonUrl: e.target.value,
                        };
                        updateData(updatedResources);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-global-danger"
                    onClick={() => {
                      const updatedResources = [
                        ...data.resourceSection.resources,
                      ];
                      updatedResources.splice(index, 1);
                      updateData(updatedResources);
                    }}
                  >
                    Remove
                  </button>
                </>
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>

        {/* 
        {data?.resourceSection?.resources?.map((resource, index) => (
          <>
            <div className="form-group">
              <label className="form-label">Resource Title</label>
              <input
                className="form-control"
                type="text"
                value={resource.title}
                placeholder="Title"
                onChange={(e) => {
                  const updatedResources = [...data.resourceSection.resources];
                  updatedResources[index] = {
                    ...updatedResources[index],
                    title: e.target.value,
                  };
                  updateData(updatedResources);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Short Description</label>
              <textarea
                className="form-control"
                rows={3}
                value={resource.shortDesc}
                placeholder="Short Description"
                onChange={(e) => {
                  const updatedResources = [...data.resourceSection.resources];
                  updatedResources[index] = {
                    ...updatedResources[index],
                    shortDesc: e.target.value,
                  };
                  updateData(updatedResources);
                }}
              />
            </div>
            <div className="e-aside-head">
              <h5 className="e-aside-head-title">Resource Image</h5>
              <p className="e-aside-head-subtitle">373 x 268 recommended</p>
            </div>
            <div className="e-aside-body-content mb-3">
              {resourceImage !== index && resource?.image ? (
                <div className="e-preview">
                  <div className="e-preview-thumb e-preview-thumb-logo">
                    <img src={resource?.image} alt="Resource Image" />
                  </div>
                  <div className="e-preview-cta">
                    <button
                      type="button"
                      className="btn btn-change"
                      onClick={() => setResourceImage(index)}
                    >
                      Change
                    </button>
                    <button
                      type="button"
                      className="btn btn-remove"
                      onClick={() => setResourceImage(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ) : (
                <div className="dropzone dropzone-sm">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleUpload(acceptedFiles, index);
                      setResourceImage(-1);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="dropzone-inner">
                        <div
                          className="dropzone-inner-area"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="upload-text">
                            Drag & Drop <br /> or
                          </div>
                          <button className="btn btn-dark btn-upload">
                            Upload
                          </button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">Learn More</label>
              <input
                className="form-control"
                placeholder="Learn More"
                value={resource?.buttonName ?? "Read More"}
                onChange={(e) => {
                  const updatedResources = [...data.resourceSection.resources];
                  updatedResources[index] = {
                    ...updatedResources[index],
                    buttonName: e.target.value,
                  };
                  updateData(updatedResources);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Learn More Url</label>
              <input
                className="form-control"
                placeholder="Button Url"
                value={resource?.buttonUrl ?? ""}
                onChange={(e) => {
                  const updatedResources = [...data.resourceSection.resources];
                  updatedResources[index] = {
                    ...updatedResources[index],
                    buttonUrl: e.target.value,
                  };
                  updateData(updatedResources);
                }}
              />
            </div>
            <button
              className="btn btn-xs btn-global-danger"
              onClick={() => {
                const updatedResources = [...data.resourceSection.resources];
                updatedResources.splice(index, 1);
                updateData(updatedResources);
              }}
            >
              Remove
            </button>
          </>
        ))} */}
        <div className="">
          <button
            className="btn btn-global-primary"
            onClick={() => {
              const updatedResources = [
                ...data.resourceSection.resources,
                {
                  question: "",
                  answer: "",
                },
              ];
              updateData(updatedResources);
            }}
          >
            Add Block
          </button>
        </div>
      </div>
    </>
  );
};

export default ResourceSection;
