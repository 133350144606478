import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../models/slice/users.slice";
import { get } from "lodash";

const isEmailValid = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const { usersData } = useSelector((state) => ({
    usersData: get(state, "users.userData", {}),
  }));

  React.useEffect(() => {
    if (usersData.firstName) {
      navigate("/dashboard");
    }
  }, [usersData, navigate]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!isEmailValid(value)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setPasswordError("Password is required.");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!emailError && !passwordError && email && password) {
      dispatch(login({ email, password }))
        .then((response) => {
          // console.log(response);
          // console.log(response?.payload?.data?.signIn?.token);
        if(response?.payload?.data?.signIn?.token){
           navigate("/dashboard");
        }
        else if(response?.error?.message==='Invalid password'){
          console.log(response?.error?.message);
          setPasswordError(response.error.message);
        }
        else if(response?.error?.message==='User not found'){
          setEmailError(response?.error?.message);
        }
        })
        .catch((error) => {
          console.error("Login error:", error);
        });
    }
  };

  return (
    <div className="auth-card">
      <div className="auth-wrapper">
        <div className="auth-header">
          <div className="auth-logo">Linkites</div>
          <h1 className="auth-header-title">Sign in</h1>
          <p className="auth-header-subtitle">
            Log in to access your dashboard.
          </p>
        </div>
        <div className="auth-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label">
                Email Address <span className="required">*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && (
                <div className="form-feedback-error">{emailError}</div>
              )}
            </div>

            <div className="form-group">
              <label className="form-label">
                Password <span className="required">*</span>
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
              />
              {passwordError && (
                <div className="form-feedback-error">{passwordError}</div>
              )}
            </div>

            <div className="auth-submit">
              <button
                type="submit"
                className="btn btn-md btn-global-primary w-100"
                disabled={!email || !password || emailError || passwordError}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>

        <div className="auth-agree-conditions">
          By proceeding, you agree to our Terms and Privacy Policy.
        </div>

        <div className="auth-footer">
          Don't have an account?{" "}
          <Link to={"/admin/signup"} className="auth-redirect-link">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
