import * as React from "react";

const SvgComponent = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="17"
      fill="none"
      viewBox="0 0 4 17"
       {...props}
    >
      <path
         fill="currentColor"
        d="M2 13.25a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zM2 7a1.75 1.75 0 110 3.5A1.75 1.75 0 012 7zM2 .75a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5z"
      ></path>
    </svg>
);

export default SvgComponent;
