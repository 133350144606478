import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Import css
import "./App.scss";

import {
  Authentication,
  Dashboard,
  Editor,
  ForgetPassword,
  Layout,
  Login,
  Main,
  Signup,
} from "../views";
import { AboutPage, ContactPage, TemplateOne } from "../views/templates";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthUserTemplates,
  getUsersState,
  loadState,
  usersActions,
} from "../models";
import ServicePage from "../views/templates/template-1/service";
import BlogPage from "../views/templates/template-1/blog";
import { Create } from "../views/create/component";

const App = () => {
  const dispatch = useDispatch();
  const { autoscrollSection, template, autoRoute } = useSelector((rootState) =>
    getUsersState(rootState)
  );
  React.useEffect(() => {
    retrieveUserData();
    window.addEventListener("storage", () => {
      if (
        window.location.href === `${process.env.REACT_APP_HOST_URL}/` ||
        window.location.href === `${process.env.REACT_APP_HOST_URL}/about` ||
        window.location.href === `${process.env.REACT_APP_HOST_URL}/blog` ||
        window.location.href === `${process.env.REACT_APP_HOST_URL}/service` ||
        window.location.href === `${process.env.REACT_APP_HOST_URL}/contact`
      ) {
        const value = loadState()?.users?.template;
        const scrollProgress = loadState()?.users?.autoscrollSection;
        const routesauto = loadState()?.users?.autoRoute;
        if (scrollProgress !== autoscrollSection) {
          dispatch(usersActions.setAutoScroll(scrollProgress));
        }
        if (routesauto !== autoRoute) {
          dispatch(usersActions.setAutoRoute(routesauto));
        }
        if (template !== value) {
          dispatch(usersActions.setInitial(value));
        }
      }
    });
  }, []);
  const retrieveUserData = () => {
    // console.log("Retrieving user data");
    const userData = localStorage.getItem("BuilderUser_data");
    // console.log(userData);
    userData && dispatch(usersActions.setUserData(JSON.parse(userData)));
    userData && dispatch(getAuthUserTemplates());
  };
  return (
    <Router>
      <Routes>
        <Route index path="/" element={<TemplateOne />} />
        <Route path="/admin" element={<Authentication />}>
          <Route index element={<Login />} />
          <Route path="/admin/signup" element={<Signup />} />
          <Route path="/admin/forgot-password" element={<ForgetPassword />} />
        </Route>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create" element={<Create />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/blog" element={<BlogPage />} />

        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Main />}>
            <Route path="/editor/:templateId" element={<Editor />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
