import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const authClient = new ApolloClient({
  uri: `${process.env.REACT_APP_AUTH_API}/graphql`,
  cache: new InMemoryCache({
    typePolicies: {},
  }),
});

const signUp = gql`
  mutation signUp(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    signUp(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    )
  }
`;

const login = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;
const createTemplate = gql`
  mutation createTemplate(
    $domain: String!
    $templateName: String!
    $userId: String!
    $id: Float!
  ) {
    createTemplate(
      domain: $domain
      templateName: $templateName
      userId: $userId
      id: $id
    ) {
      domain
      _id
    }
  }
`;
const getTemplateDetails = gql`
  query {
    getTemplateDetails
  }
`;
const getAuthUserTemplates = gql`
  query getAuthUserTemplates($templateId: String!, $userId: String!) {
    getAuthUserTemplates(templateId: $templateId, userId: $userId) {
      _id
      domain
      userId {
        _id
      }
      templateName
      createdAt
      updatedAt
      imageUrl
    }
  }
`;
const getTemplate = gql`
  query getTemplate($_id: String!) {
    getTemplate(_id: $_id) {
      metadata
      templateName
      domain
    }
  }
`;

const updateTemplate = gql`
  mutation updateTemplate($templateId: String!, $metaData: String!) {
    updateTemplate(templateId: $templateId, metaData: $metaData) {
      _id
      templateName
      metadata
    }
  }
`;
const deleteTemplateById = gql`
  mutation deleteTemplateById($id: String!) {
    deleteTemplateById(id: $id)
  }
`;

const updateDomain = gql`
  mutation updateDomain(
    $templateId: String!
    $domain: String!
    $templateName: String!
  ) {
    updateDomain(
      templateId: $templateId
      domain: $domain
      templateName: $templateName
    ) {
      domain
    }
  }
`;
const resetTemplate = gql`
  mutation resetTemplate($templateId: String!) {
    resetTemplate(templateId: $templateId) {
      domain
    }
  }
`;
const getInTouch = gql`
  mutation getInTouch(
    $firstName: String!
    $lastName: String!
    $email: String!
    $domain: String!
    $message: String!
    $countryCode: Float!
    $mobileNumber: Float!
  ) {
    getInTouch(
      firstName: $firstName
      lastName: $lastName
      email: $email
      domain: $domain
      message: $message
      countryCode: $countryCode
      mobileNumber: $mobileNumber
    )
  }
`;
const subscribeUser = gql`
  mutation subscribeUser($email: String!, $domain: String!) {
    subscribeUser(email: $email, domain: $domain)
  }
`;
const getTemplateDetailsByDomain = gql`
  query getTemplateDetailsByDomain($domain: String!) {
    getTemplateDetailsByDomain(domain: $domain) {
      _id
      templateName
      domain
      createdAt
      updatedAt
      metadata
    }
  }
`;

const getEnquiryData = gql`
  query getEnquiryData {
    getEnquiryData {
      firstName
      lastName
      email
      domain
      message
      countryCode
      mobileNumber
      createdAt
    }
  }
`;
const getSubscribeData = gql`
  query getSubscribeData {
    getSubscribeData {
      domain
      email
      createdAt
    }
  }
`;

export const authService = {
  authClient,
  authQuery: {
    signUp,
    login,
    createTemplate,
    getTemplateDetails,
    getAuthUserTemplates,
    getTemplate,
    updateTemplate,
    deleteTemplateById,
    getTemplateDetailsByDomain,
    updateDomain,
    resetTemplate,
    getInTouch,
    subscribeUser,
    getEnquiryData,
    getSubscribeData,
  },
};
