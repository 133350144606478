import React from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import { usersActions } from "../../../../../models";
import "../../styles.scss";
const AboutUsSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  // const updateData = (updatedIndustries) => {
  //   dispatch(
  //     usersActions.setNavigationData({
  //       key: page,
  //       updatedObject: {
  //         ...data,
  //         industriesSection: {
  //           ...data.industriesSection,
  //           industries: updatedIndustries,
  //         },
  //       },
  //     })
  //   );
  // };

  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">About Us Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <label className="form-label">Heading</label>
          <input
            className="form-control"
            placeholder="Heading"
            value={data?.aboutUsSection?.heading}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    aboutUsSection: {
                      ...data.aboutUsSection,
                      heading: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sub Heading</label>
          <input
            className="form-control"
            placeholder="Sub Heading"
            value={data?.aboutUsSection?.subHeading}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    aboutUsSection: {
                      ...data.aboutUsSection,
                      subHeading: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">First Description</label>
          <textarea
            className="form-control"
            rows={3}
            placeholder="First Description"
            value={data?.aboutUsSection?.firstDescription}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    aboutUsSection: {
                      ...data.aboutUsSection,
                      firstDescription: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Second Description</label>
          <textarea
            className="form-control"
            rows={3}
            placeholder="Second Description"
            value={data?.aboutUsSection?.secondDescription}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    aboutUsSection: {
                      ...data.aboutUsSection,
                      secondDescription: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Learn More</label>
          <input
            className="form-control"
            placeholder="Learn More"
            value={data?.aboutUsSection?.buttonName ?? "Learn More"}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    aboutUsSection: {
                      ...data.aboutUsSection,
                      buttonName: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Learn More Url</label>
          <input
            className="form-control"
            placeholder="Button Url"
            value={data?.aboutUsSection?.buttonUrl ?? ""}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    aboutUsSection: {
                      ...data.aboutUsSection,
                      buttonUrl: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        {/* <button
          className="btn btn-xs btn-global-primary"
          onClick={() => {
            const updatedIndustries = [
              ...data.industriesSection.industries,
              {
                question: "",
                answer: "",
              },
            ];
            updateData(updatedIndustries);
          }}
        >
          Add Resource
        </button> */}
      </div>
    </>
  );
};

export default AboutUsSection;
