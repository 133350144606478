import React from "react";

import "./styles.scss";
import { Outlet } from "react-router-dom";

export const Authentication = () => {
  return (
    <div className="auth">
      <div className="auth-main">
        <div className="auth-col">
          <Outlet />

          {/* <Login />
          <Signup /> */}
          {/* <ForgetPassword />
          <Signup /> */}
        </div>
      </div>
    </div>
  );
};
