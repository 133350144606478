import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    fill="none"
    viewBox="0 0 8 14"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.046 1.906a.952.952 0 00.28.676L4.74 7 .326 11.415a.956.956 0 001.35 1.351l5.09-5.093a.956.956 0 000-1.35L1.676 1.23a.956.956 0 00-1.63.675z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
