import { configureStore } from "@reduxjs/toolkit";
import { USERS_FEATURE_KEY, usersReducer } from "./slice/users.slice";

// Load state from local storage, if available
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Error loading state from local storage:", error);
    return undefined;
  }
};

// Save state to local storage
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (error) {
    console.error("Error saving state to local storage:", error);
  }
};

// Configure Redux store
export const store = configureStore({
  reducer: {
    [USERS_FEATURE_KEY]: usersReducer,
  },
  // preloadedState: loadState(), // Load initial state from local storage
});

// Subscribe to store changes and save state to local storage
store.subscribe(() => {
  saveState(store.getState());
});
