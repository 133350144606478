import React from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import { usersActions } from "../../../../../models";
import "../../styles.scss";
const IndustriesSection = ({ data, setHomeMenu, dispatch, page }) => {
  const updateData = (updatedIndustries) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          industriesSection: {
            ...data.industriesSection,
            industries: updatedIndustries,
          },
        },
      })
    );
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setHomeMenu(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Industries Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            placeholder="Title"
            value={data?.industriesSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    industriesSection: {
                      ...data.industriesSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sub Title</label>
          <input
            className="form-control"
            placeholder="Sub Title"
            value={data?.industriesSection?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    industriesSection: {
                      ...data.industriesSection,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        {data?.industriesSection?.industries?.map((industries, index) => (
          <>
            <div className="form-group">
              <label className="form-label">Heading</label>
              <input
                className="form-control"
                type="text"
                value={industries.heading}
                placeholder="Heading"
                onChange={(e) => {
                  const updatedIndustries = [
                    ...data.industriesSection.industries,
                  ];
                  updatedIndustries[index] = {
                    ...updatedIndustries[index],
                    heading: e.target.value,
                  };
                  updateData(updatedIndustries);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                rows={3}
                value={industries.description}
                placeholder="Description"
                onChange={(e) => {
                  const updatedIndustries = [
                    ...data.industriesSection.industries,
                  ];
                  updatedIndustries[index] = {
                    ...updatedIndustries[index],
                    description: e.target.value,
                  };
                  updateData(updatedIndustries);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Short Description</label>
              <textarea
                className="form-control"
                rows={3}
                value={industries.shortDesc}
                placeholder="Short Description"
                onChange={(e) => {
                  const updatedIndustries = [
                    ...data.industriesSection.industries,
                  ];
                  updatedIndustries[index] = {
                    ...updatedIndustries[index],
                    shortDesc: e.target.value,
                  };
                  updateData(updatedIndustries);
                }}
              />
            </div>
            <button
              className="btn btn-xs btn-global-danger"
              onClick={() => {
                const updatedIndustries = [
                  ...data.industriesSection.industries,
                ];
                updatedIndustries.splice(index, 1);
                updateData(updatedIndustries);
              }}
            >
              Remove
            </button>
          </>
        ))}
        <button
          className="btn btn-xs btn-global-primary"
          onClick={() => {
            const updatedIndustries = [
              ...data.industriesSection.industries,
              {
                question: "",
                answer: "",
              },
            ];
            updateData(updatedIndustries);
          }}
        >
          Add Resource
        </button>
      </div>
    </>
  );
};

export default IndustriesSection;
