import React from "react";
import { Aside, Head, Header } from "../../components/common";
import "./styles.scss";
import { Main } from "../main/component";
import { Outlet } from "react-router-dom";
import EditorAside from "../../components/common/editor-aside";

export const Layout = (props) => {
  return (
    <>
      <div className="app-layout">
        <Head />
        <div className="app-layout-wrapper">
          {/* <Aside /> */}
          <EditorAside />
          <Outlet />
        </div>
      </div>
    </>
  );
};
