import React from "react";
import { Link } from "react-router-dom";

export const ForgetPassword = () => {
  return (
    <>
      <div className="auth-card">
        <div className="auth-wrapper">
          <div className="auth-header">
            <div className="auth-logo">
              {/* <Logo className="main-logo" /> */}
              BuildPan
            </div>
            <h1 className="auth-header-title">Forget Password</h1>
            <p className="auth-header-subtitle">
              Log in to access your dashboard.
            </p>
          </div>
          <div className="auth-form">
            <div className="form-group">
              <label className="form-label">
                Email Address <span className="required">*</span>
              </label>
              <input
                type="email"
                name=""
                placeholder="Enter your email"
                className="form-control"
              />
              <div className="form-feedback-error">error</div>
            </div>

            <div className="auth-submit">
              <button
                type="submit"
                className="btn btn-md btn-global-primary w-100"
              >
                Submit
              </button>
            </div>
          </div>

          <div className="auth-footer">
            Back to{" "}
            <Link to={"/login"} className="auth-redirect-link">
              Log in
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
