import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { CaretLeftIcon } from "../../../svg-icons";
import "../styles.scss";
import { usersActions } from "../../../../models";
const FooterSection = ({
  template,
  setOpenSection,
  dispatch,
  handleUpload,
}) => {
  const [change, setChange] = useState(false);
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setOpenSection(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Footer</div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Footer Logo</h5>
        <p className="e-aside-head-subtitle">300 x 40 recommended</p>
      </div>

      <div className="e-aside-body-content mb-3">
        {!change ? (
          <div className="e-preview">
            <div className="e-preview-thumb e-preview-thumb-logo">
              {/* <img src="" alt="" /> */}
              {template?.footerSection?.footerLogo ? (
                <img
                  src={template?.footerSection?.footerLogo}
                  alt="Footer Logo"
                />
              ) : (
                <img
                  src={
                    require("../../../../views/templates/template-1/assets/images/logo.svg")
                      .default
                  }
                  alt="Footer Logo"
                />
              )}
            </div>
            <div className="e-preview-cta">
              <button
                type="button"
                className="btn btn-change"
                onClick={() => setChange(true)}
              >
                Change
              </button>
              <button
                type="button"
                className="btn btn-remove"
                onClick={() => setChange(true)}
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <div className="dropzone dropzone-sm">
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleUpload(acceptedFiles, "footerLogo");
                setChange(false);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone-inner">
                  <div className="dropzone-inner-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-text">
                      Drag & Drop <br /> or
                    </div>
                    <button className="btn btn-dark btn-upload">Upload</button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Description"
            rows={3}
            value={template?.footerSection?.description}
            onChange={(e) =>
              dispatch(usersActions.setDescription(e.target.value))
            }
          ></textarea>
        </div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Social Links</h5>
        {/* <p className="e-aside-head-subtitle">70 x 70 recommended</p> */}
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Facebook"
            value={template?.footerSection?.socialLink?.facebook}
            onChange={(e) =>
              dispatch(
                usersActions.setSocialLink({
                  key: "facebook",
                  value: e.target.value,
                })
              )
            }
          />
          {/* <div className="form-note">
            Example: https://www.facebook.com/example/
          </div> */}
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Instagram"
            value={template?.footerSection?.socialLink?.instagram}
            onChange={(e) =>
              dispatch(
                usersActions.setSocialLink({
                  key: "instagram",
                  value: e.target.value,
                })
              )
            }
          />
          {/* <div className="form-note">
            Example: https://www.instagram.com/example/
          </div> */}
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="LinkedIn"
            value={template?.footerSection?.socialLink?.linkedin}
            onChange={(e) =>
              dispatch(
                usersActions.setSocialLink({
                  key: "linkedin",
                  value: e.target.value,
                })
              )
            }
          />
          {/* <div className="form-note">
            Example: https://www.linkedin.com/example/
          </div> */}
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="YouTube"
            value={template?.footerSection?.socialLink?.youtube}
            onChange={(e) =>
              dispatch(
                usersActions.setSocialLink({
                  key: "youtube",
                  value: e.target.value,
                })
              )
            }
          />
          {/* <div className="form-note">
            Example: https://www.youtube.com/example/
          </div> */}
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Twitter"
            value={template?.footerSection?.socialLink?.twitter}
            onChange={(e) =>
              dispatch(
                usersActions.setSocialLink({
                  key: "twitter",
                  value: e.target.value,
                })
              )
            }
          />
          {/* <div className="form-note">
            Example: https://www.twitter.com/example/
          </div> */}
        </div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">© Copyright</h5>

        {/* <p className="e-aside-head-subtitle">70 x 70 recommended</p> */}
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Footer @ Name"
            value={template?.footerSection?.copyRight}
            onChange={(e) =>
              dispatch(usersActions.setCopyRight(e.target.value))
            }
          />
        </div>
        <div className="form-group">
          <h5 className="e-aside-head-title">Terms & Conditions Link</h5>
          <input
            className="form-control"
            placeholder="Terms Link"
            value={template?.footerSection?.termUrl}
            onChange={(e) => dispatch(usersActions.setTermsUrl(e.target.value))}
          />
        </div>
        <div className="form-group">
          <h5 className="e-aside-head-title">Privacy Link</h5>
          <input
            className="form-control"
            placeholder="Privacy Link"
            value={template?.footerSection?.privacyUrl}
            onChange={(e) =>
              dispatch(usersActions.setPrivacyUrl(e.target.value))
            }
          />
        </div>
      </div>
    </>
  );
};

export default FooterSection;
