import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";

import "./styles.scss";
import { CloseIcon } from "../../svg-icons";

const Header = (props) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("BuilderUser_data"));
    if (userData) {
      setUserEmail(userData.email);
      setUserName(userData.name);
    }
  }, []);

  const items = [
    {
      label: (
        <div className="client-profile-wrapper">
          <div className="client-profile-name">{userName}</div>
          <div className="client-profile-email">{userEmail}</div>
          <div className="client-profile-role">Admin</div>
        </div>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "1",
    },
  ];
  const handleMenuClick = (e) => {
    if (e.key === "1") {
      // Check if the clicked item is "Logout"
      // Perform logout action or navigate to logout page
      localStorage.removeItem("BuilderUser_data");
      navigate("/admin"); // Navigate to the logout page
    }
  };
  return (
    <>
      <div
        className="client-top-nav"
        role="toolbar"
        aria-label="Top Navigation"
      >
        <div className="client-top-nav-container-wrapper">
          <div className="client-top-nav-left-container">
            {!window.location.href ===
              `${process.env.REACT_APP_HOST_URL}/dashboard` && (
              <div className="head__close">
                <Link
                  type="button"
                  className="btn btn__close"
                  to={"/dashboard"}
                  aria-label="Close"
                >
                  <CloseIcon />
                </Link>
              </div>
            )}
            <div className="client-top-nav-brand">Linkites Builder</div>
          </div>
          <div className="client-top-nav-middle-container"></div>
          <div className="client-top-nav-right-container">
            {!window.location.href ===
              `${process.env.REACT_APP_HOST_URL}/dashboard` && (
              <>
                <div className="client-top-nav-anchor">
                  <button type="button" className="client-top-nav-user-button">
                    <div className="client-top-nav-user">
                      <span className="client-top-nav-user-avatar">
                        Preview
                      </span>
                    </div>
                  </button>
                </div>
                <div className="client-top-nav-anchor">
                  <button type="button" className="client-top-nav-user-button">
                    <div className="client-top-nav-user">
                      <span className="client-top-nav-user-avatar">
                        Publish
                      </span>
                    </div>
                  </button>
                </div>
              </>
            )}
            <div className="client-top-nav-anchor">
              <Dropdown
                menu={{
                  items,
                  onClick: handleMenuClick,
                }}
                trigger={["click"]}
              >
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => e.preventDefault()}
                  style={{
                    padding: 0,
                  }}
                >
                  <Avatar size={30} icon={<UserOutlined />} />
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
