import React from "react";
import { Outlet } from "react-router-dom";

import "./styles.scss";

export const Main = (props) => {
  return (
    <>
      <main className="main">
        <Outlet />
      </main>
    </>
  );
};
