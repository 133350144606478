import React, { useState } from "react";

import "./styles.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  getUsersState,
  updateTemplate,
  uploadFile,
  usersActions,
} from "../../../models";
import { HomeSection, MenuSection, PageMenu } from "./Partial";
import { useParams } from "react-router-dom";
import { message } from "antd";

function EditorAside(props) {
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const { template } = useSelector((rootState) => getUsersState(rootState));
  const [tab, setTab] = useState("Site Editor");
  const [openSection, setOpenSection] = useState(null);
  const [page, setPage] = useState(null);
  const handleUpload = (acceptedFiles, key) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const imageData = await uploadFile(file);
      switch (key) {
        case "logo":
          dispatch(usersActions.setLogo(imageData));
          break;
        case "favIcon":
          dispatch(usersActions.setFavIcon(imageData));
          break;
        case "footerLogo":
          dispatch(usersActions.setFooterLogo(imageData));
          break;
        default:
          // Handle default case if needed
          break;
      }
    };
    reader.readAsDataURL(file);
  };
  const save = () => {
    const parsedData = JSON.stringify(template);
    dispatch(updateTemplate({ templateId: templateId, metaData: parsedData }))
      .then((data) => {
        if (data.payload !== null) {
          message.success("Changes are saved");
        }
      })
      .catch((error) => {
        console.error("Id error:", error);
      });
  };
  return (
    <>
      <aside className="e-aside">
        <div className="e-aside-container">
          {openSection === null && page === null && (
            <div className="e-aside-header">
              <div className="e-aside-header-tabs">
                <button
                  className={`btn btn-e-aside-nav ${
                    tab === "Site Editor" && "active"
                  }`}
                  onClick={() => setTab("Site Editor")}
                >
                  Site Editor
                </button>
                <button
                  className={`btn btn-e-aside-nav ${
                    tab === "Pages" && "active"
                  }`}
                  onClick={() => setTab("Pages")}
                >
                  Sections
                </button>
              </div>
            </div>
          )}

          {tab === "Site Editor" && (
            <MenuSection
              handleUpload={handleUpload}
              template={template}
              dispatch={dispatch}
              openSection={openSection}
              setOpenSection={setOpenSection}
            />
          )}

          {/* {tab === "Pages" && (
            <div className="e-aside-body">
              <PageMenu
                page={page}
                setPage={setPage}
                template={template}
                dispatch={dispatch}
              />
            </div>
          )} */}
          {tab === "Pages" && (
            <HomeSection
              data={template.navigation.find((item) => item.key === "Home")}
              setPage={setPage}
              dispatch={dispatch}
              page={page}
              key={false}
            />
          )}
          {/* <div className="e-aside-footer">
            <button
              type="button"
              className="btn btn-global-primary w-100"
              onClick={() => {
                save();
              }}
            >
              Save changes
            </button>
          </div> */}
        </div>
      </aside>
    </>
  );
}

export default EditorAside;
