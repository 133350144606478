import React from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import { usersActions } from "../../../../../models";
import "../../styles.scss";
const EpilogueSection = ({ data, setHomeMenu, dispatch, page }) => {
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setHomeMenu(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Epilogue Section</div>
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Title"
            value={data?.epilogue?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    epilogue: {
                      ...data.epilogue,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            rows={3}
            placeholder="Sub Title"
            value={data?.epilogue?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    epilogue: {
                      ...data.epilogue,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button Title"
            value={data?.epilogue?.button1Title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    epilogue: {
                      ...data.epilogue,
                      button1Title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button URL"
            value={data?.epilogue?.button1Url}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    epilogue: {
                      ...data.epilogue,
                      button1Url: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button Title"
            value={data?.epilogue?.button2Title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    epilogue: {
                      ...data.epilogue,
                      button2Title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button URL"
            value={data?.epilogue?.button2Url}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    epilogue: {
                      ...data.epilogue,
                      button2Url: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default EpilogueSection;
