import React, { useState } from "react";
import "../../styles.scss";
import HeroSection from "./HeroSection";
import ServiceSection from "./ServiceSection";
import FAQSection from "./FAQSection";
import ResourceSection from "./ResourceSection";
import { CaretLeftIcon, CaretRightIcon } from "../../../../svg-icons";
import CommentSection from "./CommentSection";
import EpilogueSection from "./EpilogueSection";
import IndustriesSection from "./IndustriesSection";
import OrganizationSection from "./OrganizationSection";
import AboutUsSection from "./AboutUsSection";
import AchievementSection from "./AchievementSection";
import { usersActions } from "../../../../../models";
import ContactFormSection from "./ContactFormSection";

const HomeSection = ({ data, setPage, dispatch, page, key }) => {
  const [homeMenu, setHomeMenu] = useState(null);
  return (
    <>
      {homeMenu === null && (
        <>
          {key && (
            <div className="e-aside-header e-aside-subheader">
              <button
                className="btn btn-e-aside-nav"
                onClick={() => setPage(null)}
              >
                <CaretLeftIcon />
              </button>
              <div className="e-aside-header-title">
                {data?.navigationTitle}
              </div>
            </div>
          )}
          <div className="e-aside-links">
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                dispatch(usersActions.setAutoScroll("hero"));
                setHomeMenu("Hero Section");
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Hero Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            {/* <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Organization Section");
                dispatch(usersActions.setAutoScroll("organization"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Organization Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button> */}
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("AboutUs Section");
                dispatch(usersActions.setAutoScroll("about"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">AboutUs Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Service Section");
                dispatch(usersActions.setAutoScroll("service"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Service Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            {/* <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                dispatch(usersActions.setAutoScroll("industries"));
                setHomeMenu("Industries Section");
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Industries Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button> */}
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Achievement Section");
                dispatch(usersActions.setAutoScroll("achievement"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Achievement Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Resource Section");
                dispatch(usersActions.setAutoScroll("resource"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Blogs Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Comment Section");
                dispatch(usersActions.setAutoScroll("comment"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Comment Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("FAQ Section");
                dispatch(usersActions.setAutoScroll("faq"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">FAQ Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
            {/* <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Epilogue Section");
                dispatch(usersActions.setAutoScroll("epilogue"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Epilogue Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button> */}
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setHomeMenu("Contact Section");
                dispatch(usersActions.setAutoScroll("contact"));
                setPage("Home");
              }}
            >
              <div className="e-aside-link-title">Contact Section</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
          </div>
        </>
      )}
      {homeMenu === "Hero Section" && (
        <HeroSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "Organization Section" && (
        <OrganizationSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
        />
      )}
      {homeMenu === "AboutUs Section" && (
        <AboutUsSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "Service Section" && (
        <ServiceSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "Achievement Section" && (
        <AchievementSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "Resource Section" && (
        <ResourceSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "Comment Section" && (
        <CommentSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "FAQ Section" && (
        <FAQSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
      {homeMenu === "Epilogue Section" && (
        <EpilogueSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
        />
      )}
      {homeMenu === "Industries Section" && (
        <IndustriesSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
        />
      )}
      {homeMenu === "Contact Section" && (
        <ContactFormSection
          data={data}
          setHomeMenu={setHomeMenu}
          dispatch={dispatch}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default HomeSection;
