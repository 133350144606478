import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../../models/slice/users.slice";

export const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Local state to manage form inputs
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(signUp(formData))
      .then(response => {
        if(response?.payload?.data?.signUp?.token){
          navigate("/dashboard");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  

  return (
    <div className="auth-card">
      <div className="auth-wrapper">
        <div className="auth-header">
          <div className="auth-logo">
            {/* <Logo className="main-logo" /> */}
            Linkites
          </div>
          <h1 className="auth-header-title">Create your account</h1>
          <p className="auth-header-subtitle">
            Create an account to discuss, publish, and manage all of your
            projects.
          </p>
        </div>
        <div className="auth-form">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="form-label">
                  First name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="First name"
                  className="form-control"
                />
                <div className="form-feedback-error">error</div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="form-label">
                  Last name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last name"
                  className="form-control"
                />
                <div className="form-feedback-error">error</div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label">
              Email address <span className="required">*</span>
            </label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email address"
              className="form-control"
            />
            <div className="form-feedback-error">error</div>
          </div>
          <div className="form-group">
            <label className="form-label">
              Password <span className="required">*</span>
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter your password"
              className="form-control"
            />
            <div className="form-feedback-error">error</div>
          </div>

          <div className="auth-submit">
            <button
              type="button"
              className="btn btn-md btn-global-primary w-100"
              onClick={handleSubmit}
            >
              Continue
            </button>
          </div>
        </div>

        <div className="auth-agree-conditions">
          By creating an account, you agree to Read's Privacy Policy and Terms
          of Service
        </div>

        <div className="auth-footer">
          Already have an account?{" "}
          <Link to={"/admin"} className="auth-redirect-link">
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
};
