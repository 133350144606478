import React, { useState } from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import Dropzone from "react-dropzone";
import { uploadFile, usersActions } from "../../../../../models";
import "../../styles.scss";
const OrganizationSection = ({ data, setHomeMenu, dispatch, page }) => {
  const logo = data?.organizationSection?.organizationLogo ?? [];

  const handleUpload = (acceptedFiles, key) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const imageData = await uploadFile(file);
      const tempData = [...logo];
      tempData.push(imageData);
      dispatch(
        usersActions.setNavigationData({
          key: page,
          updatedObject: {
            ...data,
            organizationSection: {
              ...data.organizationSection,
              organizationLogo: tempData,
            },
          },
        })
      );
    };
    reader.readAsDataURL(file);
  };
  const updateData = (updatedLogo) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          organizationSection: {
            ...data.organizationSection,
            organizationLogo: updatedLogo,
          },
        },
      })
    );
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setHomeMenu(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Organization Section</div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Organization Image</h5>
        <p className="e-aside-head-subtitle">380 x 380 recommended</p>
      </div>
      <div className="e-aside-body-content">
        {logo.length > 0 &&
          logo?.map((img, index) => {
            return (
              <div className="e-preview">
                <div className="e-preview-thumb e-preview-thumb-logo">
                  <img src={img} alt="Organization Image" />
                </div>
                <div className="e-preview-cta">
                  <button
                    type="button"
                    className="btn btn-remove"
                    onClick={() => {
                      const updatedLogo = [
                        ...data.organizationSection.organizationLogo,
                      ];
                      updatedLogo.splice(index, 1);
                      updateData(updatedLogo);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            );
          })}
        <div className="dropzone dropzone-sm">
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleUpload(acceptedFiles, "organizationImg");
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-inner">
                <div className="dropzone-inner-area" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="upload-text">
                    Drag & Drop <br /> or
                  </div>
                  <button className="btn btn-dark btn-upload">Upload</button>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className="form-group">
          <label className="form-label">Description</label>
          <textarea
            className="form-control"
            rows={3}
            value={data?.organizationSection?.description}
            placeholder="Description"
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    organizationSection: {
                      ...data.organizationSection,
                      description: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default OrganizationSection;
