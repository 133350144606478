import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../components/svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Template from "./../../images/template.png";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createTemplate,
  getAuthUserTemplates,
  getTemplateDetails,
  getUsersState,
} from "../../models";

export const Create = () => {
  const [templateName, setTemplateName] = useState("");
  const [domain, setDomain] = useState("");
  const [errors, setErrors] = useState({});
  const [selectBase, setSelectBase] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { defaultTemplate } = useSelector((rootState) =>
    getUsersState(rootState)
  );
  useEffect(() => {
    dispatch(getTemplateDetails());
  }, []);

  const validateInputs = () => {
    const errors = {};
    if (!templateName.trim()) {
      errors.templateName = "Template Name is required";
    }
    if (!domain.trim()) {
      errors.domain = "Domain is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      setLoading(true);
      // Handle form submission
      dispatch(
        createTemplate({
          domain: `${domain}.linkites.com`,
          templateName: templateName,
          metadata: "",
          id: selectBase,
        })
      )
        .then((data) => {
          console.log("data:", data);
          if (data.payload !== null) {
            setTimeout(() => {
              setLoading(false);
              dispatch(getAuthUserTemplates());
              navigate(`/editor/${data.payload.data.createTemplate._id}`);
            }, 5000);
          } else {
            const errors = {};
            errors.domain = "Domain is already exist.";
            setErrors(errors);
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
        });
      console.log("Form submitted successfully!");
    }
  };

  return (
    <>
      <header className="head">
        <div className="head__container">
          <div className="head__row">
            <div className="head__left">
              <div className="head__close">
                <Link
                  type="button"
                  className="btn btn__close"
                  to={"/dashboard"}
                  aria-label="Close"
                >
                  <CloseIcon />
                </Link>
              </div>
              <div className="head__divider"></div>
              <div className="head__title">Create New Landing Page</div>
            </div>
          </div>
        </div>
      </header>

      <div className="create-component">
        <div className="create-component-aside">
          <div className="">
            <div className="form-group">
              <label className="form-label">
                Template Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              {errors.templateName && (
                <div className="form-feedback-error">{errors.templateName}</div>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">
                Sub Domain <span className="required">*</span>
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                />
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: 600,
                    padding: "0 0 0 10px",
                  }}
                >
                  .linkites.com
                </div>
              </div>
              <div className="form-note">
                {`The website name will be your subdomain (i.e.
                ${domain !== "" ? domain : "example"}.linkites.com)`}
              </div>
              {errors.domain && (
                <div className="form-feedback-error">{errors.domain}</div>
              )}
            </div>

            <button
              type="button"
              className="btn btn-md btn-global-primary w-100"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <LoadingOutlined spin style={{ fontSize: "22px" }} />
              ) : (
                "Create"
              )}
            </button>
          </div>
        </div>
        <div className="create-component-main">
          <label className="form-label">Select at least a Template</label>
          <div className="grid-list">
            {defaultTemplate &&
              defaultTemplate?.map((item, index) => {
                return (
                  <div
                    className={
                      selectBase !== item.id
                        ? "grid-list-item"
                        : "grid-list-item active"
                    }
                    onClick={() => {
                      setSelectBase(item?.id);
                    }}
                  >
                    <div className="grid-card">
                      <div className="grid-card-thumb">
                        <img src={item?.img_url} alt="Landing Page Thumbnail" />
                        <div className="grid-card-overlay">
                          <button className="btn btn-sm btn-global-primary">
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="grid-card-info">
                        <div className="grid-card-title">
                          {item.templateName}
                        </div>
                        <div className="grid-card-domain">
                          {domain !== "" ? domain : item?.domain}
                        </div>
                        <div className="grid-card-timestamp">
                          {new Date().toDateString()}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
