import React, { useState } from "react";
import { CaretLeftIcon, CaretRightIcon } from "../../../svg-icons";
import { usersActions } from "../../../../models";
import "../styles.scss";
const NavigationSection = ({ template, setOpenSection, dispatch }) => {
  const [navigation, setNavigation] = useState(null);
  return !navigation ? (
    <>
      <div className="e-aside-header  e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setOpenSection(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Navigation</div>
      </div>
      <div className="e-aside-links">
        {template?.navigation?.map((item, index) => {
          return (
            <button
              type="button"
              className="btn e-aside-link"
              onClick={() => {
                setNavigation({ key: index, value: item });
              }}
            >
              <div className="e-aside-link-title">{item.navigationTitle}</div>
              <div className="e-aside-link-icon">
                <CaretRightIcon />
              </div>
            </button>
          );
        })}
      </div>
    </>
  ) : (
    <>
      <div className="e-aside-header  e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => setNavigation(null)}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">
          {navigation.value.navigationTitle}
        </div>
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Navigation Title"
            value={navigation.value.navigationTitle}
            onChange={(e) => {
              setNavigation({
                key: navigation.key,
                value: { ...navigation.value, navigationTitle: e.target.value },
              });
              dispatch(
                usersActions.setNavigationTitle({
                  key: navigation.key,
                  value: e.target.value,
                })
              );
            }}
          />
          <div className="form-note">
            This navigation title is the section’s name in the navigation menu
          </div>
        </div>
        {/* <div className="form-group">
          <input
            className="form-control"
            placeholder="Browser Title"
            value={navigation.value.browserTitle}
            onChange={(e) => {
              setNavigation({
                key: navigation.key,
                value: { ...navigation.value, browserTitle: e.target.value },
              });
              dispatch(
                usersActions.setBrowserTitle({
                  key: navigation.key,
                  value: e.target.value,
                })
              );
            }}
          />
          <div className="form-note">
            This page title appears in the top of the browser
          </div>
        </div> */}
        {/* <div className="form-group">
          <input
            className="form-control"
            placeholder="URL Slug"
            value={navigation.value.urlSlug}
            disabled={true}
            onChange={(e) => {
              setNavigation({
                key: navigation.key,
                value: { ...navigation.value, urlSlug: e.target.value },
              });
              dispatch(
                usersActions.setUrlSlug({
                  key: navigation.key,
                  value: e.target.value,
                })
              );
            }}
          />
          <div className="form-note">
            The unqiue location slug for this page
          </div>
        </div> */}
      </div>
    </>
  );
};

export default NavigationSection;
