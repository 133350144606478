import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Odometer from "react-odometerjs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./header";
import BackgroundImage from "./assets/images/hero-bg-canvas.png";
import HeroThumb from "./assets/images/hero/hero-1.png";
import Footer from "./footer";
import Logo from "./assets/images/logo.svg";

import "./assets/css/plugins.css";
import "./assets/css/style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getInTouch,
  getTemplateDetailsByDomain,
  getUsersState,
  subscribeUser,
  usersActions,
} from "../../../models";
import { serviceAssets } from "./serviceAsset";
import { Spin, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { validatePhoneNumberLength } from "libphonenumber-js";

const TemplateOne = () => {
  const dispatch = useDispatch();
  const { template, autoscrollSection, autoRoute } = useSelector((rootState) =>
    getUsersState(rootState)
  );
  const navigate = useNavigate();
  const index = 0;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [swiper, setSwiper] = useState(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [subscribeData, setSubscribeData] = useState("");
  const [subscribeLoad, setSubscribeLoad] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const handleInputChange = ({
    isValid,
    value,
    selectedCountryData,
    fullNumber,
    extension,
  }) => {
    if (
      validatePhoneNumberLength(
        value,
        selectedCountryData.iso2.toUpperCase()
      ) !== "TOO_LONG"
    ) {
      setPhoneNumber(value);
      setCountryCode(selectedCountryData.dialCode);
      setPhoneError("");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  useEffect(() => {
    if (autoscrollSection !== "") {
      const section = document.getElementById(autoscrollSection);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [autoscrollSection]);
  useEffect(() => {
    if (autoRoute !== null && autoRoute !== "") {
      navigate(`/${autoRoute}`);
    }
  }, [autoRoute]);
  const scrollToSection = (value) => {
    const section = document.getElementById(value);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // if (
    //   template.templateID == null
    //   // window.location.href === `${process.env.REACT_APP_HOST_URL}/`
    // ) {
    // console.log("window.location.href =>", window.location.host);
    const domainUrl =
      window.location.host !== "localhost:3080" &&
      window.location.host !== "65.2.50.133:3080"
        ? window.location.host
        : "default.linkites.com";
    dispatch(getTemplateDetailsByDomain({ domain: domainUrl }))
      .then(async (data) => {
        if (data.payload !== null) {
          const value = data.payload.data.getTemplateDetailsByDomain.metadata;
          const initial = JSON.parse(value);
          // console.log(initial, typeof initial);
          dispatch(usersActions.setInitial(initial));
        }
      })
      .catch((error) => {
        console.error("get domain:", error);
      });
    // }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email } = formData;
    let error = { ...formErrors };
    if (!firstName) {
      error.firstName = "First name is required";
    }
    if (!lastName) {
      error.lastName = "Last name is required";
    }
    if (!email || !emailRegex.test(email)) {
      error.email = "Valid email is required";
    }
    if (!formData.message) {
      error.message = "Message is required";
    }
    if (!phoneNumber || !countryCode) {
      setPhoneError("Phone number is required");
    }
    setFormErrors(error);
    if (
      firstName &&
      lastName &&
      email &&
      emailRegex.test(email) &&
      formData.message &&
      phoneNumber &&
      countryCode
    ) {
      setSubmitLoad(true);
      try {
        dispatch(
          getInTouch({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            domain: window.location.host,
            message: formData.message,
            countryCode: parseInt(countryCode),
            mobileNumber: parseInt(phoneNumber),
          })
        )
          .then((data) => {
            // console.log("data:", data);
            if (data?.payload?.data?.getInTouch !== null) {
              message.success("Message sent successfully");
              setSubmitLoad(false);
              setFormData({
                firstName: "",
                lastName: "",
                email: "",
                message: "",
              });
              setFormErrors({
                firstName: "",
                lastName: "",
                email: "",
                message: "",
              });
              setPhoneError("");
              setPhoneNumber("");
              setCountryCode("");
            }
          })
          .catch((error) => {
            console.error("submitting error:", error);
            setSubmitLoad(false);
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
            });
            setFormErrors({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
            });
            setPhoneError("");
            setPhoneNumber("");
            setCountryCode("");
          });
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitLoad(false);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
        setFormErrors({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
        setPhoneError("");
        setPhoneNumber("");
        setCountryCode("");
      }
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (template?.favIcon !== null && template?.favIcon !== undefined) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = template?.favIcon;
    }
  }, [template?.favIcon]);
  const valueForScroll = (key) => {
    let value = "";
    switch (key) {
      case "Home":
        value = "hero";
        break;
      case "About":
        value = "about";
        break;
      case "Service":
        value = "service";
        break;
      case "FAQ":
        value = "faq";
        break;
      case "Contact":
        value = "contact";
        break;
      case "Blog":
        value = "resource";
        break;
      default:
        value = "comment";
        break;
    }
    return value;
  };
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <>
      {template.templateID !== undefined ? (
        <main className="template-site">
          <div className="site-wrapper">
            {/* <Header /> */}
            {/* <Helmet
              onChangeClientState={(e) => {
                if (
                  template?.favIcon === null ||
                  template?.favIcon === undefined
                ) {
                  let link = document.querySelector("link[rel~='icon']");
                  if (!link) {
                    link = document.createElement("link");
                    link.rel = "icon";
                    document.getElementsByTagName("head")[0].appendChild(link);
                  }
                  link.href =
                    "https://configure-x.s3.amazonaws.com/c0046f75-8e70-44af-aa94-6f6dd6dec86d.png";
                }
              }}
            >
              <meta charSet="utf-8" />
                            <title>
                AI as a Service (AIaaS) Solutions for Sports, Finance,
                Healthcare, Insurance, Video/Image Analysis, and Fashion -
                Linkites Infotech Pvt Ltd
              </title>
              <meta
                name="description"
                content={
                  "Discover how Linkites Infotech Pvt Ltd leverages AI as a Service (AIaaS) to transform sports, finance, healthcare, insurance, video/image analysis, and fashion industries. Our cutting-edge AI solutions enhance efficiency, drive innovation, and foster growth. Learn more about our industry-specific AI technologies and how they can benefit your business."
                }
              />
              <meta
                name="keywords"
                content="AI as a Service, AIaaS, AI Solutions, Sports AI, Finance AI, Healthcare AI, Insurance AI, Video Analysis AI, Image Analysis AI, Fashion AI, Linkites Infotech Pvt Ltd, Artificial Intelligence, Business Innovation, Industry Transformation, AI Technologies, Player Performance Analysis, Sentiment Analysis, Medical Imaging, Fraud Detection, Object Detection, Trend Forecasting, Predictive Modeling, Customer Insights, Automated Claims Processing, Virtual Styling, Personalized Medicine"
              />
            </Helmet> */}
            <header>
              <div className="menu-area">
                <div className="container">
                  <div className="row align-items-center position-relative">
                    <div className="col-lg-3 hamburger-menu position-relative mean-container">
                      <div className="mean-bar d-md-none">
                        <a
                          href="#nav"
                          className={`meanmenu-reveal ${
                            isMenuOpen ? "meanclose" : ""
                          }`}
                          style={{
                            right: "0px",
                            left: "auto",
                            textAlign: "center",
                            textIndent: "0px",
                            fontSize: "18px",
                          }}
                          onClick={toggleMenu}
                        >
                          {isMenuOpen ? (
                            <>
                              <i className="bi bi-x-lg"></i>
                            </>
                          ) : (
                            <>
                              <span />
                              <span />
                              <span />
                            </>
                          )}
                        </a>
                        {isMenuOpen ? (
                          <>
                            <nav className="mean-nav">
                              <ul className="home-menu">
                                {template.navigation.map((item, index) => {
                                  return (
                                    <li>
                                      <a
                                        onClick={() => {
                                          const section =
                                            document.getElementById(
                                              valueForScroll(item.key)
                                            );
                                          if (section) {
                                            section.scrollIntoView({
                                              behavior: "smooth",
                                            });
                                          }
                                        }}
                                      >
                                        {item.navigationTitle}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </nav>
                          </>
                        ) : null}
                      </div>

                      <div className="menu-logo-wrap">
                        <Link to="/">
                          {template?.logo ? (
                            <img src={template?.logo} alt="" />
                          ) : (
                            <img src={Logo} alt="" />
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-9 d-flex justify-content-end mobile-hide">
                      <div className="nav-wrap d-flex justify-content-between align-items-center">
                        <div className="mainmenu text-right">
                          <ul className="home-menu">
                            {template?.navigation?.map((item, index) => {
                              return (
                                <li>
                                  <a
                                    onClick={() => {
                                      const section = document.getElementById(
                                        valueForScroll(item.key)
                                      );
                                      if (section) {
                                        section.scrollIntoView({
                                          behavior: "smooth",
                                        });
                                      }
                                    }}
                                  >
                                    {item.navigationTitle}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div
              className="hero-area hero-area-canvas overflow-hidden position-relative z-index-one"
              // className="hero-area hero-area-canvas minus-margin overflow-hidden position-relative z-index-one"
              style={{
                backgroundImage: template?.navigation[index]?.heroSection
                  ?.backgroundImg
                  ? `url(${template?.navigation[index]?.heroSection?.backgroundImg})`
                  : `url(${BackgroundImage})`,
              }}
              id="hero"
            >
              <div className="container position-relative z-index-one">
                <div className="row justify-content-between align-items-center ">
                  <div className="col-lg-5 order-1 order-lg-0 mt-5 mt-lg-0">
                    <div className="hero-info">
                      <span className="d-inline-block text_color_white text-uppercase hero-sub-spn">
                        {template?.navigation[index]?.heroSection?.subTitle ??
                          "Soar to New Heights!"}
                      </span>
                      <h1 className="text_color_white">
                        {template?.navigation[index]?.heroSection
                          ?.headingTitle ??
                          "Elevate your experience with Power AI"}
                      </h1>
                      <p className="text_color_light_white hero-text">
                        {template?.navigation[index]?.heroSection?.shortDesc ??
                          `Unleash the next level of innovation and efficiency with
                      Power AI, transforming your digital journey into a realm
                      of unparalleled possibilities.`}
                      </p>
                      <div className="two-btn-wrap d-flex flex-wrap mt-lg-5 mt-4">
                        {template?.navigation[index]?.heroSection
                          ?.button1Url && (
                          <a
                            className="common-btn bg-white-style"
                            href={
                              template?.navigation[index]?.heroSection
                                ?.button1Url ?? ""
                            }
                            target="_blank"
                          >
                            {template?.navigation[index]?.heroSection
                              ?.button1Title ?? "Get Started"}
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                                fill="currentColor"
                              />
                            </svg>
                          </a>
                        )}
                        {template?.navigation[index]?.heroSection
                          ?.button2Url && (
                          <a
                            className="vid common-btn border-style border-style-transparent"
                            href={
                              template?.navigation[index]?.heroSection
                                ?.button2Url ?? ""
                            }
                            target="_blank"
                          >
                            {template?.navigation[index]?.heroSection
                              ?.button2Title ?? "Watch Demo"}
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.283 16.752C9.93296 16.752 9.58301 16.662 9.26001 16.481C8.59301 16.107 8.19495 15.4281 8.19495 14.6641V9.33594C8.19495 8.57194 8.59301 7.89304 9.26001 7.51904C9.93101 7.14404 10.719 7.15906 11.373 7.56006L15.8521 10.3C16.4491 10.666 16.806 11.301 16.806 12.001C16.806 12.701 16.4491 13.3359 15.8521 13.7019L11.373 16.4419C11.034 16.6479 10.659 16.752 10.283 16.752ZM10.285 8.75C10.153 8.75 10.047 8.79688 9.99304 8.82788C9.90304 8.87788 9.69397 9.02894 9.69397 9.33594V14.6641C9.69397 14.9721 9.90304 15.1221 9.99304 15.1721C10.084 15.2231 10.323 15.3249 10.59 15.1609L15.069 12.4209C15.283 12.2899 15.306 12.083 15.306 12C15.306 11.917 15.283 11.7101 15.069 11.5801L10.59 8.84009C10.481 8.77209 10.377 8.75 10.285 8.75ZM12 22.75C6.072 22.75 1.25 17.928 1.25 12C1.25 6.072 6.072 1.25 12 1.25C17.928 1.25 22.75 6.072 22.75 12C22.75 17.928 17.928 22.75 12 22.75ZM12 2.75C6.899 2.75 2.75 6.899 2.75 12C2.75 17.101 6.899 21.25 12 21.25C17.101 21.25 21.25 17.101 21.25 12C21.25 6.899 17.101 2.75 12 2.75Z"
                                fill="currentColor"
                              />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 order-0 order-lg-1">
                    <div className="hero-img-wrap section-middle">
                      {template?.navigation[index]?.heroSection?.heroImg ? (
                        <img
                          src={
                            template?.navigation[index]?.heroSection?.heroImg
                          }
                          alt=""
                        />
                      ) : (
                        <img src={HeroThumb} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className="container custom-container pt-120 position-relative z-index-one"
                id="organization"
              >
                <div className="row">
                  <div className="col-lg-12 pe-lg-0">
                    <Swiper
                      className="swiper brand-slide-wrap"
                      spaceBetween={100}
                      centeredSlides={true}
                      speed={5000}
                      autoplay={{ delay: 1 }}
                      loop={true}
                      slidesPerView="auto"
                      allowTouchMove={false}
                      disableOnInteraction={true}
                      breakpoints={{
                        320: {
                          spaceBetween: 50,
                        },
                        992: {
                          spaceBetween: 70,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <div className="brand-box">
                          <svg
                            width={239}
                            height={37}
                            viewBox="0 0 239 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.387 36.498C8.23136 36.498 0 28.2347 0 18.0396V0.5C10.1557 0.5 18.387 8.76333 18.387 18.9584V36.498Z"
                              fill="currentColor"
                            />
                            <path
                              d="M19.9993 21.5738V12.26C19.9993 5.76533 25.2442 0.5 31.7137 0.5V9.81702C31.7137 16.3117 26.4688 21.5738 19.9993 21.5738Z"
                              fill="currentColor"
                            />
                            <path
                              d="M46.4797 36.5002H33.0472C25.8404 36.5002 20.002 30.6359 20.002 23.4043H33.4345C40.6381 23.4043 46.4797 29.2686 46.4797 36.5002Z"
                              fill="currentColor"
                            />
                            <path
                              d="M92.3104 34.7366H85.2377L76.7192 19.7101L71.9596 25.4706V34.7366H65.2205V2.45073H71.9596V17.2317L83.6808 2.45073H91.1316L81.3677 14.9543L92.3104 34.7366Z"
                              fill="currentColor"
                            />
                            <path
                              d="M96.2826 34.7366V11.6498H102.666V14.9096C103.022 14.344 103.481 13.8007 104.045 13.2797C104.623 12.7438 105.35 12.3047 106.224 11.9623C107.099 11.62 108.137 11.4488 109.338 11.4488C110.747 11.4488 112.059 11.7242 113.275 12.2749C114.506 12.8257 115.499 13.6965 116.255 14.8873C117.012 16.0632 117.39 17.6038 117.39 19.5091V34.7366H110.895V20.3129C110.895 19.0626 110.524 18.1248 109.783 17.4996C109.042 16.8744 108.137 16.5619 107.07 16.5619C106.358 16.5619 105.668 16.6884 105.001 16.9414C104.349 17.1945 103.815 17.5741 103.4 18.0801C102.985 18.5862 102.777 19.2114 102.777 19.9557V34.7366H96.2826Z"
                              fill="currentColor"
                            />
                            <path
                              d="M127.989 24.6892C127.974 25.8651 128.159 26.8922 128.545 27.7704C128.93 28.6337 129.486 29.3036 130.213 29.7799C130.954 30.2413 131.844 30.472 132.882 30.472C133.994 30.472 134.965 30.2339 135.795 29.7576C136.641 29.2812 137.226 28.4998 137.552 27.4131H143.402C143.15 29.0505 142.497 30.4497 141.445 31.6108C140.407 32.7718 139.146 33.6575 137.664 34.2678C136.181 34.8781 134.639 35.1832 133.037 35.1832C130.769 35.1832 128.767 34.7069 127.032 33.7542C125.312 32.7867 123.97 31.4247 123.007 29.6682C122.043 27.9118 121.561 25.8428 121.561 23.4611C121.561 21.1539 121.998 19.0775 122.873 17.2317C123.748 15.371 125.016 13.9049 126.676 12.8331C128.352 11.7465 130.361 11.2032 132.704 11.2032C135.047 11.2032 137.011 11.7019 138.598 12.6992C140.199 13.6816 141.4 15.0585 142.201 16.8298C143.016 18.5862 143.424 20.6181 143.424 22.9253V24.6892H127.989ZM127.966 20.8041H137.352C137.352 19.8813 137.182 19.0328 136.841 18.2588C136.5 17.4847 135.981 16.8744 135.284 16.4279C134.602 15.9813 133.742 15.7581 132.704 15.7581C131.696 15.7581 130.836 16.0037 130.124 16.4949C129.427 16.9712 128.893 17.5964 128.522 18.3704C128.152 19.1444 127.966 19.9557 127.966 20.8041Z"
                              fill="currentColor"
                            />
                            <path
                              d="M178.693 11.6498L171.131 34.7366H165.971L161.679 20.7595L157.408 34.7366H152.382L144.931 11.6498H150.669L155.073 25.9618H155.251L159.477 11.6498H164.103L168.373 25.9618H168.551L172.822 11.6498H178.693Z"
                              fill="currentColor"
                            />
                            <path
                              d="M188.703 11.6498V34.7366H182.497V11.6498H188.703ZM188.769 2.47305V8.34525H182.453V2.47305H188.769Z"
                              fill="currentColor"
                            />
                            <path
                              d="M203.684 35.1832C202.083 35.1832 200.541 34.9227 199.058 34.4017C197.575 33.8659 196.322 33.0323 195.299 31.901C194.276 30.7549 193.624 29.2738 193.342 27.4578H198.969C199.162 28.2765 199.51 28.924 200.015 29.4003C200.533 29.8766 201.119 30.219 201.772 30.4274C202.424 30.6209 203.047 30.7176 203.64 30.7176C204.885 30.7176 205.886 30.5241 206.642 30.1371C207.413 29.7352 207.799 29.1249 207.799 28.3063C207.799 27.6811 207.569 27.1824 207.109 26.8103C206.665 26.4382 205.923 26.1553 204.885 25.9618L200.837 25.1134C198.806 24.6817 197.175 23.9523 195.944 22.9253C194.714 21.8833 194.083 20.4097 194.054 18.5044C194.054 17.1201 194.417 15.8771 195.144 14.7756C195.885 13.6741 196.975 12.8034 198.413 12.1633C199.851 11.5232 201.616 11.2032 203.707 11.2032C206.539 11.2032 208.8 11.8284 210.49 13.0787C212.195 14.3291 213.063 16.093 213.092 18.3704H207.666C207.502 17.4773 207.058 16.7926 206.331 16.3163C205.619 15.825 204.722 15.5794 203.64 15.5794C202.513 15.5794 201.586 15.7804 200.86 16.1823C200.133 16.5842 199.77 17.1945 199.77 18.0132C199.77 18.6235 200.066 19.1072 200.66 19.4645C201.253 19.8068 202.172 20.1045 203.417 20.3576L207.154 21.206C208.444 21.5186 209.489 21.9503 210.29 22.501C211.106 23.0369 211.736 23.6249 212.181 24.2649C212.625 24.905 212.929 25.5525 213.092 26.2074C213.27 26.8475 213.359 27.428 213.359 27.949C213.359 29.4822 212.952 30.7921 212.136 31.8787C211.321 32.9504 210.186 33.7691 208.733 34.3347C207.28 34.9004 205.597 35.1832 203.684 35.1832Z"
                              fill="currentColor"
                            />
                            <path
                              d="M217.915 34.7366V1.55762H224.365V14.9766C224.736 14.4407 225.203 13.9123 225.766 13.3913C226.33 12.8555 227.034 12.4089 227.879 12.0517C228.724 11.6944 229.755 11.5158 230.971 11.5158C232.439 11.5158 233.773 11.8061 234.974 12.3866C236.19 12.9522 237.161 13.7783 237.888 14.865C238.629 15.9516 239 17.2763 239 18.8393V34.7366H232.483V19.7994C232.483 18.7574 232.105 17.9611 231.349 17.4103C230.607 16.8447 229.703 16.5619 228.635 16.5619C227.938 16.5619 227.264 16.6884 226.611 16.9414C225.959 17.1945 225.425 17.5815 225.01 18.1025C224.61 18.6086 224.409 19.2412 224.409 20.0003V34.7366H217.915Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="brand-box">
                          <svg
                            width={239}
                            height={37}
                            viewBox="0 0 239 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.387 36.498C8.23136 36.498 0 28.2347 0 18.0396V0.5C10.1557 0.5 18.387 8.76333 18.387 18.9584V36.498Z"
                              fill="currentColor"
                            />
                            <path
                              d="M19.9993 21.5738V12.26C19.9993 5.76533 25.2442 0.5 31.7137 0.5V9.81702C31.7137 16.3117 26.4688 21.5738 19.9993 21.5738Z"
                              fill="currentColor"
                            />
                            <path
                              d="M46.4797 36.5002H33.0472C25.8404 36.5002 20.002 30.6359 20.002 23.4043H33.4345C40.6381 23.4043 46.4797 29.2686 46.4797 36.5002Z"
                              fill="currentColor"
                            />
                            <path
                              d="M92.3104 34.7366H85.2377L76.7192 19.7101L71.9596 25.4706V34.7366H65.2205V2.45073H71.9596V17.2317L83.6808 2.45073H91.1316L81.3677 14.9543L92.3104 34.7366Z"
                              fill="currentColor"
                            />
                            <path
                              d="M96.2826 34.7366V11.6498H102.666V14.9096C103.022 14.344 103.481 13.8007 104.045 13.2797C104.623 12.7438 105.35 12.3047 106.224 11.9623C107.099 11.62 108.137 11.4488 109.338 11.4488C110.747 11.4488 112.059 11.7242 113.275 12.2749C114.506 12.8257 115.499 13.6965 116.255 14.8873C117.012 16.0632 117.39 17.6038 117.39 19.5091V34.7366H110.895V20.3129C110.895 19.0626 110.524 18.1248 109.783 17.4996C109.042 16.8744 108.137 16.5619 107.07 16.5619C106.358 16.5619 105.668 16.6884 105.001 16.9414C104.349 17.1945 103.815 17.5741 103.4 18.0801C102.985 18.5862 102.777 19.2114 102.777 19.9557V34.7366H96.2826Z"
                              fill="currentColor"
                            />
                            <path
                              d="M127.989 24.6892C127.974 25.8651 128.159 26.8922 128.545 27.7704C128.93 28.6337 129.486 29.3036 130.213 29.7799C130.954 30.2413 131.844 30.472 132.882 30.472C133.994 30.472 134.965 30.2339 135.795 29.7576C136.641 29.2812 137.226 28.4998 137.552 27.4131H143.402C143.15 29.0505 142.497 30.4497 141.445 31.6108C140.407 32.7718 139.146 33.6575 137.664 34.2678C136.181 34.8781 134.639 35.1832 133.037 35.1832C130.769 35.1832 128.767 34.7069 127.032 33.7542C125.312 32.7867 123.97 31.4247 123.007 29.6682C122.043 27.9118 121.561 25.8428 121.561 23.4611C121.561 21.1539 121.998 19.0775 122.873 17.2317C123.748 15.371 125.016 13.9049 126.676 12.8331C128.352 11.7465 130.361 11.2032 132.704 11.2032C135.047 11.2032 137.011 11.7019 138.598 12.6992C140.199 13.6816 141.4 15.0585 142.201 16.8298C143.016 18.5862 143.424 20.6181 143.424 22.9253V24.6892H127.989ZM127.966 20.8041H137.352C137.352 19.8813 137.182 19.0328 136.841 18.2588C136.5 17.4847 135.981 16.8744 135.284 16.4279C134.602 15.9813 133.742 15.7581 132.704 15.7581C131.696 15.7581 130.836 16.0037 130.124 16.4949C129.427 16.9712 128.893 17.5964 128.522 18.3704C128.152 19.1444 127.966 19.9557 127.966 20.8041Z"
                              fill="currentColor"
                            />
                            <path
                              d="M178.693 11.6498L171.131 34.7366H165.971L161.679 20.7595L157.408 34.7366H152.382L144.931 11.6498H150.669L155.073 25.9618H155.251L159.477 11.6498H164.103L168.373 25.9618H168.551L172.822 11.6498H178.693Z"
                              fill="currentColor"
                            />
                            <path
                              d="M188.703 11.6498V34.7366H182.497V11.6498H188.703ZM188.769 2.47305V8.34525H182.453V2.47305H188.769Z"
                              fill="currentColor"
                            />
                            <path
                              d="M203.684 35.1832C202.083 35.1832 200.541 34.9227 199.058 34.4017C197.575 33.8659 196.322 33.0323 195.299 31.901C194.276 30.7549 193.624 29.2738 193.342 27.4578H198.969C199.162 28.2765 199.51 28.924 200.015 29.4003C200.533 29.8766 201.119 30.219 201.772 30.4274C202.424 30.6209 203.047 30.7176 203.64 30.7176C204.885 30.7176 205.886 30.5241 206.642 30.1371C207.413 29.7352 207.799 29.1249 207.799 28.3063C207.799 27.6811 207.569 27.1824 207.109 26.8103C206.665 26.4382 205.923 26.1553 204.885 25.9618L200.837 25.1134C198.806 24.6817 197.175 23.9523 195.944 22.9253C194.714 21.8833 194.083 20.4097 194.054 18.5044C194.054 17.1201 194.417 15.8771 195.144 14.7756C195.885 13.6741 196.975 12.8034 198.413 12.1633C199.851 11.5232 201.616 11.2032 203.707 11.2032C206.539 11.2032 208.8 11.8284 210.49 13.0787C212.195 14.3291 213.063 16.093 213.092 18.3704H207.666C207.502 17.4773 207.058 16.7926 206.331 16.3163C205.619 15.825 204.722 15.5794 203.64 15.5794C202.513 15.5794 201.586 15.7804 200.86 16.1823C200.133 16.5842 199.77 17.1945 199.77 18.0132C199.77 18.6235 200.066 19.1072 200.66 19.4645C201.253 19.8068 202.172 20.1045 203.417 20.3576L207.154 21.206C208.444 21.5186 209.489 21.9503 210.29 22.501C211.106 23.0369 211.736 23.6249 212.181 24.2649C212.625 24.905 212.929 25.5525 213.092 26.2074C213.27 26.8475 213.359 27.428 213.359 27.949C213.359 29.4822 212.952 30.7921 212.136 31.8787C211.321 32.9504 210.186 33.7691 208.733 34.3347C207.28 34.9004 205.597 35.1832 203.684 35.1832Z"
                              fill="currentColor"
                            />
                            <path
                              d="M217.915 34.7366V1.55762H224.365V14.9766C224.736 14.4407 225.203 13.9123 225.766 13.3913C226.33 12.8555 227.034 12.4089 227.879 12.0517C228.724 11.6944 229.755 11.5158 230.971 11.5158C232.439 11.5158 233.773 11.8061 234.974 12.3866C236.19 12.9522 237.161 13.7783 237.888 14.865C238.629 15.9516 239 17.2763 239 18.8393V34.7366H232.483V19.7994C232.483 18.7574 232.105 17.9611 231.349 17.4103C230.607 16.8447 229.703 16.5619 228.635 16.5619C227.938 16.5619 227.264 16.6884 226.611 16.9414C225.959 17.1945 225.425 17.5815 225.01 18.1025C224.61 18.6086 224.409 19.2412 224.409 20.0003V34.7366H217.915Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="brand-box">
                          <svg
                            width={239}
                            height={37}
                            viewBox="0 0 239 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.387 36.498C8.23136 36.498 0 28.2347 0 18.0396V0.5C10.1557 0.5 18.387 8.76333 18.387 18.9584V36.498Z"
                              fill="currentColor"
                            />
                            <path
                              d="M19.9993 21.5738V12.26C19.9993 5.76533 25.2442 0.5 31.7137 0.5V9.81702C31.7137 16.3117 26.4688 21.5738 19.9993 21.5738Z"
                              fill="currentColor"
                            />
                            <path
                              d="M46.4797 36.5002H33.0472C25.8404 36.5002 20.002 30.6359 20.002 23.4043H33.4345C40.6381 23.4043 46.4797 29.2686 46.4797 36.5002Z"
                              fill="currentColor"
                            />
                            <path
                              d="M92.3104 34.7366H85.2377L76.7192 19.7101L71.9596 25.4706V34.7366H65.2205V2.45073H71.9596V17.2317L83.6808 2.45073H91.1316L81.3677 14.9543L92.3104 34.7366Z"
                              fill="currentColor"
                            />
                            <path
                              d="M96.2826 34.7366V11.6498H102.666V14.9096C103.022 14.344 103.481 13.8007 104.045 13.2797C104.623 12.7438 105.35 12.3047 106.224 11.9623C107.099 11.62 108.137 11.4488 109.338 11.4488C110.747 11.4488 112.059 11.7242 113.275 12.2749C114.506 12.8257 115.499 13.6965 116.255 14.8873C117.012 16.0632 117.39 17.6038 117.39 19.5091V34.7366H110.895V20.3129C110.895 19.0626 110.524 18.1248 109.783 17.4996C109.042 16.8744 108.137 16.5619 107.07 16.5619C106.358 16.5619 105.668 16.6884 105.001 16.9414C104.349 17.1945 103.815 17.5741 103.4 18.0801C102.985 18.5862 102.777 19.2114 102.777 19.9557V34.7366H96.2826Z"
                              fill="currentColor"
                            />
                            <path
                              d="M127.989 24.6892C127.974 25.8651 128.159 26.8922 128.545 27.7704C128.93 28.6337 129.486 29.3036 130.213 29.7799C130.954 30.2413 131.844 30.472 132.882 30.472C133.994 30.472 134.965 30.2339 135.795 29.7576C136.641 29.2812 137.226 28.4998 137.552 27.4131H143.402C143.15 29.0505 142.497 30.4497 141.445 31.6108C140.407 32.7718 139.146 33.6575 137.664 34.2678C136.181 34.8781 134.639 35.1832 133.037 35.1832C130.769 35.1832 128.767 34.7069 127.032 33.7542C125.312 32.7867 123.97 31.4247 123.007 29.6682C122.043 27.9118 121.561 25.8428 121.561 23.4611C121.561 21.1539 121.998 19.0775 122.873 17.2317C123.748 15.371 125.016 13.9049 126.676 12.8331C128.352 11.7465 130.361 11.2032 132.704 11.2032C135.047 11.2032 137.011 11.7019 138.598 12.6992C140.199 13.6816 141.4 15.0585 142.201 16.8298C143.016 18.5862 143.424 20.6181 143.424 22.9253V24.6892H127.989ZM127.966 20.8041H137.352C137.352 19.8813 137.182 19.0328 136.841 18.2588C136.5 17.4847 135.981 16.8744 135.284 16.4279C134.602 15.9813 133.742 15.7581 132.704 15.7581C131.696 15.7581 130.836 16.0037 130.124 16.4949C129.427 16.9712 128.893 17.5964 128.522 18.3704C128.152 19.1444 127.966 19.9557 127.966 20.8041Z"
                              fill="currentColor"
                            />
                            <path
                              d="M178.693 11.6498L171.131 34.7366H165.971L161.679 20.7595L157.408 34.7366H152.382L144.931 11.6498H150.669L155.073 25.9618H155.251L159.477 11.6498H164.103L168.373 25.9618H168.551L172.822 11.6498H178.693Z"
                              fill="currentColor"
                            />
                            <path
                              d="M188.703 11.6498V34.7366H182.497V11.6498H188.703ZM188.769 2.47305V8.34525H182.453V2.47305H188.769Z"
                              fill="currentColor"
                            />
                            <path
                              d="M203.684 35.1832C202.083 35.1832 200.541 34.9227 199.058 34.4017C197.575 33.8659 196.322 33.0323 195.299 31.901C194.276 30.7549 193.624 29.2738 193.342 27.4578H198.969C199.162 28.2765 199.51 28.924 200.015 29.4003C200.533 29.8766 201.119 30.219 201.772 30.4274C202.424 30.6209 203.047 30.7176 203.64 30.7176C204.885 30.7176 205.886 30.5241 206.642 30.1371C207.413 29.7352 207.799 29.1249 207.799 28.3063C207.799 27.6811 207.569 27.1824 207.109 26.8103C206.665 26.4382 205.923 26.1553 204.885 25.9618L200.837 25.1134C198.806 24.6817 197.175 23.9523 195.944 22.9253C194.714 21.8833 194.083 20.4097 194.054 18.5044C194.054 17.1201 194.417 15.8771 195.144 14.7756C195.885 13.6741 196.975 12.8034 198.413 12.1633C199.851 11.5232 201.616 11.2032 203.707 11.2032C206.539 11.2032 208.8 11.8284 210.49 13.0787C212.195 14.3291 213.063 16.093 213.092 18.3704H207.666C207.502 17.4773 207.058 16.7926 206.331 16.3163C205.619 15.825 204.722 15.5794 203.64 15.5794C202.513 15.5794 201.586 15.7804 200.86 16.1823C200.133 16.5842 199.77 17.1945 199.77 18.0132C199.77 18.6235 200.066 19.1072 200.66 19.4645C201.253 19.8068 202.172 20.1045 203.417 20.3576L207.154 21.206C208.444 21.5186 209.489 21.9503 210.29 22.501C211.106 23.0369 211.736 23.6249 212.181 24.2649C212.625 24.905 212.929 25.5525 213.092 26.2074C213.27 26.8475 213.359 27.428 213.359 27.949C213.359 29.4822 212.952 30.7921 212.136 31.8787C211.321 32.9504 210.186 33.7691 208.733 34.3347C207.28 34.9004 205.597 35.1832 203.684 35.1832Z"
                              fill="currentColor"
                            />
                            <path
                              d="M217.915 34.7366V1.55762H224.365V14.9766C224.736 14.4407 225.203 13.9123 225.766 13.3913C226.33 12.8555 227.034 12.4089 227.879 12.0517C228.724 11.6944 229.755 11.5158 230.971 11.5158C232.439 11.5158 233.773 11.8061 234.974 12.3866C236.19 12.9522 237.161 13.7783 237.888 14.865C238.629 15.9516 239 17.2763 239 18.8393V34.7366H232.483V19.7994C232.483 18.7574 232.105 17.9611 231.349 17.4103C230.607 16.8447 229.703 16.5619 228.635 16.5619C227.938 16.5619 227.264 16.6884 226.611 16.9414C225.959 17.1945 225.425 17.5815 225.01 18.1025C224.61 18.6086 224.409 19.2412 224.409 20.0003V34.7366H217.915Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="brand-box">
                          <svg
                            width={239}
                            height={37}
                            viewBox="0 0 239 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.387 36.498C8.23136 36.498 0 28.2347 0 18.0396V0.5C10.1557 0.5 18.387 8.76333 18.387 18.9584V36.498Z"
                              fill="currentColor"
                            />
                            <path
                              d="M19.9993 21.5738V12.26C19.9993 5.76533 25.2442 0.5 31.7137 0.5V9.81702C31.7137 16.3117 26.4688 21.5738 19.9993 21.5738Z"
                              fill="currentColor"
                            />
                            <path
                              d="M46.4797 36.5002H33.0472C25.8404 36.5002 20.002 30.6359 20.002 23.4043H33.4345C40.6381 23.4043 46.4797 29.2686 46.4797 36.5002Z"
                              fill="currentColor"
                            />
                            <path
                              d="M92.3104 34.7366H85.2377L76.7192 19.7101L71.9596 25.4706V34.7366H65.2205V2.45073H71.9596V17.2317L83.6808 2.45073H91.1316L81.3677 14.9543L92.3104 34.7366Z"
                              fill="currentColor"
                            />
                            <path
                              d="M96.2826 34.7366V11.6498H102.666V14.9096C103.022 14.344 103.481 13.8007 104.045 13.2797C104.623 12.7438 105.35 12.3047 106.224 11.9623C107.099 11.62 108.137 11.4488 109.338 11.4488C110.747 11.4488 112.059 11.7242 113.275 12.2749C114.506 12.8257 115.499 13.6965 116.255 14.8873C117.012 16.0632 117.39 17.6038 117.39 19.5091V34.7366H110.895V20.3129C110.895 19.0626 110.524 18.1248 109.783 17.4996C109.042 16.8744 108.137 16.5619 107.07 16.5619C106.358 16.5619 105.668 16.6884 105.001 16.9414C104.349 17.1945 103.815 17.5741 103.4 18.0801C102.985 18.5862 102.777 19.2114 102.777 19.9557V34.7366H96.2826Z"
                              fill="currentColor"
                            />
                            <path
                              d="M127.989 24.6892C127.974 25.8651 128.159 26.8922 128.545 27.7704C128.93 28.6337 129.486 29.3036 130.213 29.7799C130.954 30.2413 131.844 30.472 132.882 30.472C133.994 30.472 134.965 30.2339 135.795 29.7576C136.641 29.2812 137.226 28.4998 137.552 27.4131H143.402C143.15 29.0505 142.497 30.4497 141.445 31.6108C140.407 32.7718 139.146 33.6575 137.664 34.2678C136.181 34.8781 134.639 35.1832 133.037 35.1832C130.769 35.1832 128.767 34.7069 127.032 33.7542C125.312 32.7867 123.97 31.4247 123.007 29.6682C122.043 27.9118 121.561 25.8428 121.561 23.4611C121.561 21.1539 121.998 19.0775 122.873 17.2317C123.748 15.371 125.016 13.9049 126.676 12.8331C128.352 11.7465 130.361 11.2032 132.704 11.2032C135.047 11.2032 137.011 11.7019 138.598 12.6992C140.199 13.6816 141.4 15.0585 142.201 16.8298C143.016 18.5862 143.424 20.6181 143.424 22.9253V24.6892H127.989ZM127.966 20.8041H137.352C137.352 19.8813 137.182 19.0328 136.841 18.2588C136.5 17.4847 135.981 16.8744 135.284 16.4279C134.602 15.9813 133.742 15.7581 132.704 15.7581C131.696 15.7581 130.836 16.0037 130.124 16.4949C129.427 16.9712 128.893 17.5964 128.522 18.3704C128.152 19.1444 127.966 19.9557 127.966 20.8041Z"
                              fill="currentColor"
                            />
                            <path
                              d="M178.693 11.6498L171.131 34.7366H165.971L161.679 20.7595L157.408 34.7366H152.382L144.931 11.6498H150.669L155.073 25.9618H155.251L159.477 11.6498H164.103L168.373 25.9618H168.551L172.822 11.6498H178.693Z"
                              fill="currentColor"
                            />
                            <path
                              d="M188.703 11.6498V34.7366H182.497V11.6498H188.703ZM188.769 2.47305V8.34525H182.453V2.47305H188.769Z"
                              fill="currentColor"
                            />
                            <path
                              d="M203.684 35.1832C202.083 35.1832 200.541 34.9227 199.058 34.4017C197.575 33.8659 196.322 33.0323 195.299 31.901C194.276 30.7549 193.624 29.2738 193.342 27.4578H198.969C199.162 28.2765 199.51 28.924 200.015 29.4003C200.533 29.8766 201.119 30.219 201.772 30.4274C202.424 30.6209 203.047 30.7176 203.64 30.7176C204.885 30.7176 205.886 30.5241 206.642 30.1371C207.413 29.7352 207.799 29.1249 207.799 28.3063C207.799 27.6811 207.569 27.1824 207.109 26.8103C206.665 26.4382 205.923 26.1553 204.885 25.9618L200.837 25.1134C198.806 24.6817 197.175 23.9523 195.944 22.9253C194.714 21.8833 194.083 20.4097 194.054 18.5044C194.054 17.1201 194.417 15.8771 195.144 14.7756C195.885 13.6741 196.975 12.8034 198.413 12.1633C199.851 11.5232 201.616 11.2032 203.707 11.2032C206.539 11.2032 208.8 11.8284 210.49 13.0787C212.195 14.3291 213.063 16.093 213.092 18.3704H207.666C207.502 17.4773 207.058 16.7926 206.331 16.3163C205.619 15.825 204.722 15.5794 203.64 15.5794C202.513 15.5794 201.586 15.7804 200.86 16.1823C200.133 16.5842 199.77 17.1945 199.77 18.0132C199.77 18.6235 200.066 19.1072 200.66 19.4645C201.253 19.8068 202.172 20.1045 203.417 20.3576L207.154 21.206C208.444 21.5186 209.489 21.9503 210.29 22.501C211.106 23.0369 211.736 23.6249 212.181 24.2649C212.625 24.905 212.929 25.5525 213.092 26.2074C213.27 26.8475 213.359 27.428 213.359 27.949C213.359 29.4822 212.952 30.7921 212.136 31.8787C211.321 32.9504 210.186 33.7691 208.733 34.3347C207.28 34.9004 205.597 35.1832 203.684 35.1832Z"
                              fill="currentColor"
                            />
                            <path
                              d="M217.915 34.7366V1.55762H224.365V14.9766C224.736 14.4407 225.203 13.9123 225.766 13.3913C226.33 12.8555 227.034 12.4089 227.879 12.0517C228.724 11.6944 229.755 11.5158 230.971 11.5158C232.439 11.5158 233.773 11.8061 234.974 12.3866C236.19 12.9522 237.161 13.7783 237.888 14.865C238.629 15.9516 239 17.2763 239 18.8393V34.7366H232.483V19.7994C232.483 18.7574 232.105 17.9611 231.349 17.4103C230.607 16.8447 229.703 16.5619 228.635 16.5619C227.938 16.5619 227.264 16.6884 226.611 16.9414C225.959 17.1945 225.425 17.5815 225.01 18.1025C224.61 18.6086 224.409 19.2412 224.409 20.0003V34.7366H217.915Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </SwiperSlide>
                    </Swiper>

                    <p className="text_color_light_white mt-4">
                      {" "}
                      <span className="text_color_white">Power AI</span> is used
                      by over <span className="text_color_white">15,000+</span>{" "}
                      companies across the globe
                    </p>
                  </div>
                </div>
              </div> */}
            </div>

            <>
              {/* About */}
              <section className="about-area bg_color_black" id="about">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="about-wrap bg_color_white d-flex flex-column flex-lg-row justify-content-between">
                        <div className="section-title">
                          <span className="sub-title d-inline-block">
                            {template?.navigation[index]?.aboutUsSection
                              ?.heading ?? ""}
                          </span>
                          <h2 className="title">
                            {/* <span>About</span> Power AI */}
                            {template?.navigation[index]?.aboutUsSection
                              ?.subHeading ?? ""}
                          </h2>
                        </div>
                        <div className="about-info">
                          <p className="disc">
                            {template?.navigation[index]?.aboutUsSection
                              ?.firstDescription ?? ""}
                          </p>
                          <p className="disc-2">
                            {template?.navigation[index]?.aboutUsSection
                              ?.secondDescription ?? ""}
                          </p>
                          {template?.navigation[index]?.aboutUsSection
                            ?.buttonUrl && (
                            <a
                              target="_blank"
                              className="border-btn"
                              href={
                                template?.navigation[index]?.aboutUsSection
                                  ?.buttonUrl ?? ""
                              }
                            >
                              {template?.navigation[index]?.aboutUsSection
                                ?.buttonName ?? "Learn More"}
                              <svg
                                className="icon"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* About End */}
              {/* Service */}
              <section
                // className="service-area bg_color_black pt-120 pb-120 position-relative z-index-one overflow-hidden"
                className="service-area bg_color_black pt-120 position-relative z-index-one overflow-hidden"
                id="service"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="section-title text-center">
                        <h2 className="title text_color_white text-anim">
                          {template?.navigation[index]?.serviceSection?.title ??
                            "Our Services"}
                        </h2>
                        <p className="desc text_color_light_white">
                          {template?.navigation[index]?.serviceSection
                            ?.subTitle ??
                            `Embark on a personalized journey of innovation with our
                        transformation AI solutions, tailored to meet your
                        unique needs and drive unparalleled success.`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-60 gy-4">
                    {template?.navigation[index]?.serviceSection?.services?.map(
                      (item, ind) => {
                        return (
                          <div className="col-lg-4 col-md-6">
                            <div className="service-box-wrap">
                              <div className="service-box position-relative overflow-hidden">
                                <h3>
                                  {serviceAssets[ind % 6].svg}
                                  {item?.serviceTitle ?? ""}
                                </h3>
                                <p>{item?.serviceDescription ?? ""}</p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <img
                  className="position-absolute z-index-minus-one translate-middle top-50 start-50"
                  src={require("./assets/images/shapes/service-bg-sp.png")}
                  alt=""
                />
              </section>
              {/* Service End */}
              {/* Industries */}
              {/* <section
                className="industries-area bg_color_black"
                id="industries"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="section-title text-center">
                        <h2 className="title text_color_white d-inline-block text-anim">
                          {template?.navigation[index]?.industriesSection
                            ?.title ?? "Industries We Serve"}
                        </h2>
                        <p className="desc text_color_light_white">
                          {template?.navigation[index]?.industriesSection
                            ?.subTitle ??
                            `We empower diverse sectors with intelligent solutions
                        that seamlessly integrate into a spectrum of industries,
                        driving innovation across the board.`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-60 gy-4">
                    {template?.navigation[
                      index
                    ]?.industriesSection?.industries?.map((item, ind) => (
                      <div
                        className={
                          ind ===
                            template.navigation[index].industriesSection
                              .industries.length -
                              1 &&
                          template.navigation[index].industriesSection
                            .industries.length %
                            2 ===
                            1
                            ? "col-md-12"
                            : "col-md-6"
                        }
                        key={ind}
                      >
                        <div
                          className={`industries-box ${
                            ind ===
                              template.navigation[index].industriesSection
                                .industries.length -
                                1 &&
                            template.navigation[index].industriesSection
                              .industries.length %
                              2 ===
                              1
                              ? "industries-box-design-two d-lg-flex position-relative overflow-hidden z-index-one"
                              : "position-relative overflow-hidden z-index-one"
                          }`}
                        >
                          <div className="industries-img overflow-hidden flex-shrink-0 order-lg-1">
                            <img
                              className="w-100"
                              src={require(`./assets/images/industries/industries-${
                                (ind % 3) + 1
                              }.png`)}
                              alt=""
                            />
                          </div>
                          <div className="industries-info mt-lg-0">
                            <span className="text_color_light_white">
                              {item.heading ?? ""}
                            </span>
                            <h2>{item.description ?? ""}</h2>
                            <p className="text_color_light_white">
                              {item.shortDesc ?? ""}
                            </p>
                            <a
                              className="common-btn border-style"
                              href="service.html"
                            >
                              Learn More
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.7061 12.708L14.707 19.7071C14.512 19.9021 14.256 20 14 20C13.744 20 13.488 19.9021 13.293 19.7071C12.902 19.3161 12.902 18.684 13.293 18.293L18.5859 13H3C2.447 13 2 12.552 2 12C2 11.448 2.447 11 3 11H18.5859L13.293 5.70707C12.902 5.31607 12.902 4.68401 13.293 4.29301C13.684 3.90201 14.316 3.90201 14.707 4.29301L21.7061 11.292C21.7991 11.385 21.8721 11.495 21.9231 11.618C22.0241 11.862 22.0241 12.1381 21.9231 12.3821C21.8721 12.5051 21.7991 12.615 21.7061 12.708Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section> */}
              {/* Industries End */}
              {/* Achievement */}
              <section
                className="achievement-area pt-120 pb-120 bg_color_black"
                id="achievement"
              >
                <div className="container">
                  <div className="row align-items-center gy-4">
                    <div className="col-lg-3">
                      <h2 className="achievement-title text_color_white text-center text-lg-start">
                        {template?.navigation[index]?.achievementSection
                          ?.title ?? "See our Achievement"}
                      </h2>
                    </div>
                    <div className="col-lg-9">
                      <div className="row achievement-line-wrap gy-4">
                        <div className="col-lg-4 achievement-single-line-wrap">
                          <div className="achievement-count-box text-center">
                            <h2 className="counter-item text_color_white">
                              <Odometer
                                className="odometer d-inline-block"
                                value={
                                  template?.navigation[index]
                                    ?.achievementSection?.accuracy ?? "98%"
                                }
                                format="dd"
                              />
                              <em>%</em>
                            </h2>
                            <p className="text_color_light_white">
                              Accuracy in Data
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 achievement-single-line-wrap">
                          <div className="achievement-count-box text-center">
                            <h2 className="counter-item text_color_white">
                              <Odometer
                                className="odometer d-inline-block"
                                value={
                                  template?.navigation[index]
                                    ?.achievementSection?.globalUsers ?? "5"
                                }
                                format="dd"
                              />
                              <em> M+</em>
                            </h2>
                            <p className="text_color_light_white">
                              Global Users
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 achievement-single-line-wrap">
                          <div className="achievement-count-box text-center">
                            <h2 className="counter-item text_color_white">
                              <Odometer
                                className="odometer d-inline-block"
                                value={
                                  template?.navigation[index]
                                    ?.achievementSection?.accuracyMill ?? "2"
                                }
                                format="dd"
                              />
                              <em> M+</em>
                            </h2>
                            <p className="text_color_light_white">
                              Accuracy in Data
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="site-border mt-5" />
                </div>
              </section>
              {/* Achievement End */}
              {/* Data */}
              {/* <section className="data-area bg_color_black pb-120 overflow-hidden">
              <div className="container">
                <div className="row align-items-center justify-content-between gy-4">
                  <div className="col-lg-6 col-xl-5">
                    <div className="data-img-wrap position-relative z-index-one">
                      <img
                        className="w-100"
                        src={require("./assets/images/data/data-1.svg").default}
                        alt=""
                      />
                      <img
                        className="data-img-2 d-none d-lg-block position-absolute"
                        src={require("./assets/images/data/data-2.svg").default}
                        alt=""
                      />
                      <img
                        className="data-sp position-absolute z-index-minus-one"
                        src={require("./assets/images/shapes/data.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <div className="data-info">
                      <span className="text_color_light_white">
                        AI Unleashed Zero-Lag
                      </span>
                      <h2 className="text_color_white text-anim">
                        Lag-Free Real-Time Power for Unmissable Data Mastery
                      </h2>
                      <p className="text_color_light_white">
                        In the realm of cutting-edge AI, our technology takes
                        center stage, offering a lag-free, real-time power that
                        propels data mastery to unparalleled heights.
                      </p>
                      <ul className="listing custom-ul d-flex flex-column">
                        <li>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM16.03 9.13599C16.323 9.42899 16.323 9.90402 16.03 10.197L11.363 14.864C11.217 15.01 11.025 15.084 10.833 15.084C10.641 15.084 10.449 15.011 10.303 14.864L7.97 12.531C7.677 12.238 7.677 11.763 7.97 11.47C8.263 11.177 8.73801 11.177 9.03101 11.47L10.834 13.273L14.97 9.13702C15.263 8.84402 15.737 8.84399 16.03 9.13599Z"
                              fill="currentColor"
                              fillOpacity="0.7"
                            />
                          </svg>
                          Seamless precision for an unmissable data experience.
                        </li>
                        <li>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM16.03 9.13599C16.323 9.42899 16.323 9.90402 16.03 10.197L11.363 14.864C11.217 15.01 11.025 15.084 10.833 15.084C10.641 15.084 10.449 15.011 10.303 14.864L7.97 12.531C7.677 12.238 7.677 11.763 7.97 11.47C8.263 11.177 8.73801 11.177 9.03101 11.47L10.834 13.273L14.97 9.13702C15.263 8.84402 15.737 8.84399 16.03 9.13599Z"
                              fill="currentColor"
                              fillOpacity="0.7"
                            />
                          </svg>
                          Seamless precision for an unmissable data experience.
                        </li>
                        <li>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM16.03 9.13599C16.323 9.42899 16.323 9.90402 16.03 10.197L11.363 14.864C11.217 15.01 11.025 15.084 10.833 15.084C10.641 15.084 10.449 15.011 10.303 14.864L7.97 12.531C7.677 12.238 7.677 11.763 7.97 11.47C8.263 11.177 8.73801 11.177 9.03101 11.47L10.834 13.273L14.97 9.13702C15.263 8.84402 15.737 8.84399 16.03 9.13599Z"
                              fill="currentColor"
                              fillOpacity="0.7"
                            />
                          </svg>
                          Seamless precision for an unmissable data experience.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
              {/* Data End */}
              {/* Blog */}
              <section
                className="blog-area bg_color_black pb-120"
                id="resource"
              >
                <div className="container">
                  <div className="row align-items-center gy-4">
                    <div className="col-lg-7">
                      <div className="section-title">
                        <h2 className="title text_color_white text-anim">
                          {template?.navigation[index]?.resourceSection
                            ?.title ?? "Our Useful Resources"}
                        </h2>
                        <p className="desc text_color_light_white">
                          {template?.navigation[index]?.resourceSection
                            ?.subTitle ??
                            `Dive into the forefront of innovation and thought
                          leadership with our latest resources. Where insightful
                          content meets the pulse of contemporary ideas, keeping
                          you informed and inspired.`}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 d-none d-lg-block">
                      {template?.navigation[index]?.resourceSection?.resources
                        ?.length > 3 && (
                        <div className="slider-button-wrap-arrow d-flex justify-content-lg-end">
                          <div
                            className="resources-button-prev slide-btn slide-btn-prev"
                            onClick={() => swiper?.slidePrev()}
                          >
                            <svg
                              width={72}
                              height={72}
                              viewBox="0 0 72 72"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M36 4.5C18.63 4.5 4.5 18.63 4.5 36C4.5 53.37 18.63 67.5 36 67.5C53.37 67.5 67.5 53.37 67.5 36C67.5 18.63 53.37 4.5 36 4.5ZM36 64.5C20.286 64.5 7.5 51.714 7.5 36C7.5 20.286 20.286 7.5 36 7.5C51.714 7.5 64.5 20.286 64.5 36C64.5 51.714 51.714 64.5 36 64.5ZM49.5 36C49.5 36.828 48.828 37.5 48 37.5H27.6211L34.0591 43.938C34.6441 44.523 34.6441 45.4741 34.0591 46.0591C33.7651 46.3531 33.3811 46.4971 32.9971 46.4971C32.6131 46.4971 32.2291 46.3501 31.9351 46.0591L22.9351 37.0591C22.7971 36.9211 22.6863 36.7528 22.6113 36.5698C22.4583 36.2038 22.4583 35.7903 22.6113 35.4243C22.6863 35.2383 22.7971 35.0731 22.9351 34.9351L31.9351 25.9351C32.5201 25.3501 33.4712 25.3501 34.0562 25.9351C34.6412 26.5201 34.6412 27.4712 34.0562 28.0562L27.6182 34.4941H48C48.828 34.5001 49.5 35.172 49.5 36Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div
                            className="resources-button-next slide-btn slide-btn-next"
                            onClick={() => swiper?.slideNext()}
                          >
                            <svg
                              width={72}
                              height={72}
                              viewBox="0 0 72 72"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M36 4.5C18.63 4.5 4.5 18.63 4.5 36C4.5 53.37 18.63 67.5 36 67.5C53.37 67.5 67.5 53.37 67.5 36C67.5 18.63 53.37 4.5 36 4.5ZM36 64.5C20.286 64.5 7.5 51.714 7.5 36C7.5 20.286 20.286 7.5 36 7.5C51.714 7.5 64.5 20.286 64.5 36C64.5 51.714 51.714 64.5 36 64.5ZM49.3828 36.5728C49.3078 36.7588 49.1971 36.924 49.0591 37.062L40.0591 46.062C39.7651 46.356 39.3811 46.5 38.9971 46.5C38.6131 46.5 38.2291 46.353 37.9351 46.062C37.3501 45.477 37.3501 44.5259 37.9351 43.9409L44.373 37.5029H24C23.172 37.5029 22.5 36.8309 22.5 36.0029C22.5 35.1749 23.172 34.5029 24 34.5029H44.3789L37.9409 28.0649C37.3559 27.4799 37.3559 26.5288 37.9409 25.9438C38.5259 25.3588 39.477 25.3588 40.062 25.9438L49.062 34.9438C49.2 35.0818 49.3107 35.2501 49.3857 35.4331C49.5357 35.7931 49.5358 36.2068 49.3828 36.5728Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row pt-60 gy-4">
                    <div className="col-lg-12">
                      <Swiper
                        spaceBetween={15}
                        className="swiper resources-slider"
                        slidesPerView={3}
                        onSlideChange={() => console.log("slide change")}
                        // onSwiper={(swiper) => console.log(swiper)}
                        onSwiper={setSwiper}
                      >
                        {template?.navigation[
                          index
                        ]?.resourceSection?.resources?.map((item, ind) => {
                          return (
                            <SwiperSlide>
                              <div className="blog-box">
                                <div className="blog-img overflow-hidden">
                                  {item?.image ? (
                                    <img
                                      className="w-100"
                                      src={item?.image}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="w-100"
                                      src={require(`./assets/images/resources/resources-${
                                        (ind % 9) + 1
                                      }.png`)}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="blog-info">
                                  <h2 className="text_color_white">
                                    <a target="_blank"
                                      href={item?.buttonUrl ?? ""}>{item.title}</a>
                                  </h2>
                                  <p className="text_color_light_white">
                                    {item.shortDesc}
                                  </p>
                                  {item?.buttonUrl && (
                                    <a
                                      className="border-btn border-btn-white"
                                      target="_blank"
                                      href={item?.buttonUrl ?? ""}
                                    >
                                      {item?.buttonName ?? "Read More"}
                                      <svg
                                        className="icon"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </a>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                  <div className="row mt-3 d-block d-lg-none">
                    <div className="col-lg-12">
                      <div className="slider-button-wrap-arrow d-flex justify-content-lg-end">
                        <div className="resources-button-prev slide-btn slide-btn-prev">
                          <svg
                            width={72}
                            height={72}
                            viewBox="0 0 72 72"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M36 4.5C18.63 4.5 4.5 18.63 4.5 36C4.5 53.37 18.63 67.5 36 67.5C53.37 67.5 67.5 53.37 67.5 36C67.5 18.63 53.37 4.5 36 4.5ZM36 64.5C20.286 64.5 7.5 51.714 7.5 36C7.5 20.286 20.286 7.5 36 7.5C51.714 7.5 64.5 20.286 64.5 36C64.5 51.714 51.714 64.5 36 64.5ZM49.5 36C49.5 36.828 48.828 37.5 48 37.5H27.6211L34.0591 43.938C34.6441 44.523 34.6441 45.4741 34.0591 46.0591C33.7651 46.3531 33.3811 46.4971 32.9971 46.4971C32.6131 46.4971 32.2291 46.3501 31.9351 46.0591L22.9351 37.0591C22.7971 36.9211 22.6863 36.7528 22.6113 36.5698C22.4583 36.2038 22.4583 35.7903 22.6113 35.4243C22.6863 35.2383 22.7971 35.0731 22.9351 34.9351L31.9351 25.9351C32.5201 25.3501 33.4712 25.3501 34.0562 25.9351C34.6412 26.5201 34.6412 27.4712 34.0562 28.0562L27.6182 34.4941H48C48.828 34.5001 49.5 35.172 49.5 36Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="resources-button-next slide-btn slide-btn-next">
                          <svg
                            width={72}
                            height={72}
                            viewBox="0 0 72 72"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M36 4.5C18.63 4.5 4.5 18.63 4.5 36C4.5 53.37 18.63 67.5 36 67.5C53.37 67.5 67.5 53.37 67.5 36C67.5 18.63 53.37 4.5 36 4.5ZM36 64.5C20.286 64.5 7.5 51.714 7.5 36C7.5 20.286 20.286 7.5 36 7.5C51.714 7.5 64.5 20.286 64.5 36C64.5 51.714 51.714 64.5 36 64.5ZM49.3828 36.5728C49.3078 36.7588 49.1971 36.924 49.0591 37.062L40.0591 46.062C39.7651 46.356 39.3811 46.5 38.9971 46.5C38.6131 46.5 38.2291 46.353 37.9351 46.062C37.3501 45.477 37.3501 44.5259 37.9351 43.9409L44.373 37.5029H24C23.172 37.5029 22.5 36.8309 22.5 36.0029C22.5 35.1749 23.172 34.5029 24 34.5029H44.3789L37.9409 28.0649C37.3559 27.4799 37.3559 26.5288 37.9409 25.9438C38.5259 25.3588 39.477 25.3588 40.062 25.9438L49.062 34.9438C49.2 35.0818 49.3107 35.2501 49.3857 35.4331C49.5357 35.7931 49.5358 36.2068 49.3828 36.5728Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Blog End */}
              {/* Testimonial */}
              <div
                className="testimonial-area bg_color_black pb-120"
                id="comment"
              >
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="section-title text-center">
                        <h2 className="title text_color_white text-anim">
                          {template?.navigation[index]?.commentSection?.title ??
                            "OWhat our users are saying"}
                        </h2>
                        <p className="desc text_color_light_white max-width-728 section-middle">
                          {template?.navigation[index]?.commentSection
                            ?.subTitle ??
                            `Explore the authentic voices of user satisfaction in our
                        testimonial section. Echoing diverse and exceptional
                        experiences with our products.`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-60 gy-4">
                    <div className="col-lg-12 ps-0 pe-0">
                      <Slider className="testimonial-slide-top" {...settings}>
                        {template?.navigation[
                          index
                        ]?.commentSection?.comments.map((item, ind) => {
                          return (
                            <div>
                              <div
                                className="testimonial-box"
                                style={{
                                  margin: "0 12px",
                                }}
                              >
                                <ul className="custom-ul rating d-flex align-items-center">
                                  {[...Array(5).keys()]?.map((index) => (
                                    <li key={index}>
                                      {index < item.rating ? (
                                        <i className="bi bi-star-fill" />
                                      ) : (
                                        <i className="bi bi-star" />
                                      )}
                                    </li>
                                  ))}
                                </ul>
                                <p className="text_color_light_white">
                                  {item.comment}
                                </p>
                                <h3 className="text_color_white">
                                  {item.name}
                                </h3>
                                <span className="text_color_light_white">
                                  {item.address}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
              {/* Testimonial End */}
              {/* FAQ */}
              <section className="faq-area bg_color_black " id="faq">
                <div className="container">
                  <div className="row justify-content-between gy-4">
                    <div className="col-lg-5">
                      <div className="section-title">
                        <h2 className="title text_color_white text-anim">
                          {template?.navigation[index]?.faqSection?.title ??
                            "Frequently Asked questions"}
                        </h2>
                        <p className="desc text_color_light_white mb-lg-3 mb-2">
                          {template?.navigation[index]?.faqSection?.subTitle ??
                            `Explore the answers you seek in our "Frequently Asked
                        Questions" section, your go-to resource for quick
                        insights into the world of artificial intelligence.`}
                        </p>
                        <p className="text_color_light_white">
                          {template?.navigation[index]?.faqSection?.shortDesc ??
                            `From understanding AI applications to learning about our
                        services, we've condensed the information you need to
                        kickstart your exploration of this transformation
                        technology.`}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Accordion
                        className="accordion faq-wrap"
                        open={open}
                        toggle={toggle}
                      >
                        {template?.navigation[index]?.faqSection?.faqs?.map(
                          (item, ind) => {
                            return (
                              <AccordionItem key={ind}>
                                <AccordionHeader targetId={`heading${ind}`}>
                                  {" "}
                                  {item.question}
                                </AccordionHeader>
                                <AccordionBody accordionId={`heading${ind}`}>
                                  {item.answer}
                                </AccordionBody>
                              </AccordionItem>
                            );
                          }
                        )}
                      </Accordion>
                      {/* <div className="accordion faq-wrap" id="accordionExample">
                        {template?.navigation[index]?.faqSection?.faqs?.map(
                          (item, ind) => {
                            return (
                              <div className="accordion-item" key={ind}>
                                <h2
                                  className="accordion-header"
                                  id={`heading${ind}`}
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${ind}`} // Updated target ID
                                    aria-expanded={ind === 0 ? "true" : "false"}
                                    aria-controls={`collapse${ind}`} // Updated control ID
                                  >
                                    {item.question}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${ind}`} // Updated collapse ID
                                  className={`accordion-collapse ${
                                    ind === 0 ? "show" : "collapse"
                                  }`}
                                  aria-labelledby={`heading${ind}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    {item.answer}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
              {/* FAQ End */}
              {/* Subscribe */}
              {/* <section
                className="subscribe-area bg_color_black position-relative z-index-one"
                id="epilogue"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="subscribe-wrap overflow-hidden position-relative z-index-one">
                        <div className="section-title text-center">
                          <h2 className="title text_color_white text-anim">
                            {template?.navigation[index]?.epilogue?.title ??
                              "Start Your Journey with Power AI"}
                          </h2>
                          <p className="desc text_color_light_white">
                            {template?.navigation[index]?.epilogue?.subTitle ??
                              `Embark on a transformation journey into the future of
                          innovation as you kickstart your exploration of Power
                          AI, where limitless possibilities await at every click
                          and command.`}
                          </p>
                        </div>
                        <div className="two-btn-wrap mt-4 mt-lg-5 d-flex flex-wrap justify-content-center">
                          <a
                            className="common-btn bg-white-style"
                            href="service.html"
                          >
                            {template?.navigation[index]?.epilogue
                              ?.button1Title ?? "Get Started"}
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                                fill="currentColor"
                              />
                            </svg>
                          </a>
                          <a
                            className="common-btn border-style border-style-transparent"
                            href="contact.html"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.5521 21.7514C16.0931 21.7514 15.6311 21.6894 15.1751 21.5644C9.01011 19.8724 4.12909 14.9944 2.43609 8.83344C2.05109 7.43244 2.26204 5.97243 3.03204 4.72443C3.80504 3.47043 5.0761 2.58543 6.5191 2.29743C7.4791 2.10543 8.4281 2.53343 8.9151 3.35443L10.4781 5.99442C11.2371 7.27642 10.86 8.92742 9.61896 9.75342L8.4881 10.5054C9.5451 12.6754 11.3229 14.4584 13.4839 15.5144L14.2462 14.3774C15.0772 13.1384 16.7281 12.7674 18.0091 13.5304L20.6522 15.1064C21.4702 15.5944 21.8931 16.5494 21.7071 17.4814C21.4191 18.9244 20.5341 20.1954 19.2811 20.9684C18.4391 21.4864 17.5021 21.7514 16.5521 21.7514ZM6.97809 3.74942C6.92909 3.74942 6.87911 3.75444 6.83111 3.76444C5.77711 3.97544 4.86412 4.61042 4.31012 5.51142C3.76112 6.40142 3.61009 7.44043 3.88409 8.43543C5.43709 14.0884 9.91608 18.5654 15.5721 20.1174C16.5681 20.3904 17.604 20.2384 18.493 19.6904C19.393 19.1354 20.0291 18.2214 20.2361 17.1854C20.2981 16.8744 20.1571 16.5554 19.8831 16.3924L17.241 14.8164C16.646 14.4624 15.878 14.6354 15.492 15.2104L14.377 16.8754C14.177 17.1734 13.7911 17.2874 13.4651 17.1494C10.5021 15.9104 8.09113 13.4944 6.85113 10.5204C6.71313 10.1884 6.82899 9.80642 7.12799 9.60742L8.78912 8.50241C9.36512 8.11941 9.54007 7.35242 9.18707 6.75742L7.62408 4.11841C7.48708 3.88641 7.23909 3.74942 6.97809 3.74942Z"
                                fill="currentColor"
                              />
                            </svg>
                            {template?.navigation[index]?.epilogue
                              ?.button2Title ?? "Contact Us"}
                          </a>
                        </div>
                        <img
                          className="position-absolute z-index-minus-one start-0 top-0 h-100 w-100"
                          src={require("./assets/images/shapes/noise.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="subscribe-sp-left z-index-minus-one position-absolute start-0 top-50 translate-middle-y"
                  src={require("./assets/images/shapes/subscribe-left.png")}
                  alt=""
                />
                <img
                  className="subscribe-sp-right z-index-minus-one position-absolute end-0 top-50 translate-middle-y"
                  src={require("./assets/images/shapes/subscribe-right.png")}
                  alt=""
                />
              </section> */}
              {/* Subscribe end */}

              <section
                className="contact-area bg_color_deep_blue pt-120 pb-120"
                id="contact"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="section-title gradient-sub-title text-center">
                        <h2 className="title text_color_white">
                          {template?.navigation[index]?.contactSection?.title ??
                            "Our team is ready to help you"}
                        </h2>
                        <p className="desc text_color_light_white">
                          {template?.navigation[index]?.contactSection
                            ?.description ??
                            `Our dedicated team is prepared and eager to provide
                          you with the assistance you need, ensuring your
                          success and satisfaction. Feel free to reach out
                          anytime for our support.`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row gy-4 pt-60">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="contact-card-box text-center">
                        <div className="contact-card-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M16.0013 3.33398C9.75197 3.33398 4.66797 8.41798 4.66797 14.6673C4.66797 21.3113 10.84 25.3886 14.924 28.0859L15.6306 28.5553C15.7426 28.63 15.872 28.6673 16 28.6673C16.128 28.6673 16.2573 28.63 16.3693 28.5553L17.076 28.0859C21.16 25.3886 27.332 21.3113 27.332 14.6673C27.3346 8.41798 22.2506 3.33398 16.0013 3.33398ZM16.0013 18.0007C14.16 18.0007 12.668 16.5087 12.668 14.6673C12.668 12.826 14.16 11.334 16.0013 11.334C17.8426 11.334 19.3346 12.826 19.3346 14.6673C19.3346 16.5087 17.8426 18.0007 16.0013 18.0007Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="contact-card-text">
                          <h3 className="contact-card-title text_color_white">
                            {template?.navigation[index]?.contactSection
                              ?.info[0]?.title ?? "Our Address"}
                          </h3>
                          <p className="contact-card-desc text_color_light_white">
                            {template?.navigation[index]?.contactSection
                              ?.info[0]?.description ??
                              "218 Thornirdge Cir. Syracuse, California 208434"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="contact-card-box text-center">
                        <div className="contact-card-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M23.5002 18.8977L27.0243 20.999C27.7523 21.4337 28.1281 22.279 27.9615 23.111C27.2801 26.523 23.8536 28.7097 20.4976 27.7884C12.5789 25.615 6.39345 19.4484 4.21211 11.5124C3.28945 8.15502 5.47359 4.72566 8.88692 4.04299L8.90938 4.03901C9.74271 3.87234 10.5923 4.24967 11.0256 4.98167L13.1096 8.50035C13.8509 9.75235 13.4827 11.3643 12.2707 12.1697L10.0562 13.643C11.6189 17.3857 14.6054 20.3844 18.3374 21.9444L19.8241 19.7243C20.6361 18.515 22.2495 18.1524 23.5002 18.8977Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="contact-card-text">
                          <h3 className="contact-card-title text_color_white">
                            {template?.navigation[index]?.contactSection
                              ?.info[1]?.title ?? "Contact Info"}
                          </h3>
                          {template?.navigation[index]?.contactSection?.info[1]
                            ?.description ? (
                            <p className="contact-card-desc text_color_light_white">
                              {template?.navigation[index]?.contactSection
                                ?.info[1]?.description ?? ""}
                            </p>
                          ) : (
                            <p className="contact-card-desc text_color_light_white">
                              Open a chat or give us a call at <br />{" "}
                              <a target="_blank" href="tel:00642-27-10">
                                +088 (246) 642-27-10
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="contact-card-box text-center">
                        <div className="contact-card-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M24 6.66602H8C5.33333 6.66602 4 7.99935 4 10.666V22.666C4 25.3327 5.33333 26.666 8 26.666H24C26.6667 26.666 28 25.3327 28 22.666V10.666C28 7.99935 26.6667 6.66602 24 6.66602ZM23.9212 12.8074L17.3721 17.57C16.9614 17.8687 16.48 18.018 16 18.018C15.52 18.018 15.0373 17.8687 14.6279 17.5714L8.07878 12.8074C7.63211 12.4834 7.53339 11.8567 7.85872 11.41C8.18272 10.9647 8.80519 10.8634 9.25586 11.19L15.8053 15.9527C15.9227 16.0367 16.0786 16.038 16.196 15.9527L22.7454 11.19C23.1948 10.8634 23.8186 10.9647 24.1426 11.41C24.4679 11.858 24.3679 12.4834 23.9212 12.8074Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="contact-card-text">
                          <h3 className="contact-card-title text_color_white">
                            {template?.navigation[index]?.contactSection
                              ?.info[2]?.title ?? "Live Support"}
                          </h3>
                          {template?.navigation[index]?.contactSection?.info[2]
                            ?.description ? (
                            <p className="contact-card-desc text_color_light_white">
                              {template?.navigation[index]?.contactSection
                                ?.info[2]?.description ?? ""}
                            </p>
                          ) : (
                            <p className="contact-card-desc text_color_light_white">
                              Live chat service <br />
                              <a
                                target="_blank"
                                href="mailto:example@gmail.com"
                              >
                                example@gmail.com
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-between gy-4 pt-60">
                    <div className="col-lg-7 col-md-12">
                      <div className="form-wrap form-wrap-contact w-100">
                        <form className="common-form-box">
                          <h3 className="form-title text_color_white ">
                            Get In touch
                          </h3>
                          <p className="form-info text_color_light_white">
                            Our team would love to hear from you. Write your
                            message to us!
                          </p>
                          {/* <div className="row gy-4">
                            <div className="col-lg-6">
                              <div className="input-group">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  placeholder="write your first name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  placeholder="write your last name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <label>Email</label>
                                <input
                                  type="email"
                                  placeholder="write your email"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <label>Message</label>
                                <textarea
                                  cols={30}
                                  rows={4}
                                  placeholder="write your message"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 pt-3">
                              <div className="form-info-wrap">
                                <button
                                  type="submit"
                                  className="common-btn bg-blue-style w-100 d-flex justify-content-center"
                                >
                                  Submit Now
                                </button>
                              </div>
                            </div>
                          </div> */}

                          <div className="row gy-4">
                            <div className="col-lg-6">
                              <div className="input-group">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  name="firstName"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                  placeholder="write your first name"
                                />
                                {formErrors.firstName && (
                                  <p className="text-danger">
                                    {formErrors.firstName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  name="lastName"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                  placeholder="write your last name"
                                />
                                {formErrors.lastName && (
                                  <p className="text-danger">
                                    {formErrors.lastName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <label>Phone number</label>
                                <IntlTelInput
                                  onPhoneNumberChange={(
                                    isValid,
                                    value,
                                    selectedCountryData,
                                    fullNumber,
                                    extension
                                  ) =>
                                    handleInputChange({
                                      isValid,
                                      value,
                                      selectedCountryData,
                                      fullNumber,
                                      extension,
                                    })
                                  }
                                  value={phoneNumber}
                                  defaultCountry="us"
                                  placeholder="Enter Your Phone Number"
                                  telInputProps={{
                                    onKeyPress: handleKeyPress,
                                  }}
                                />
                                {phoneError && (
                                  <p className="text-danger">{phoneError}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <label>Email</label>
                                <input
                                  type="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  placeholder="write your email"
                                />
                                {formErrors.email && (
                                  <p className="text-danger">
                                    {formErrors.email}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <label>Message</label>
                                <textarea
                                  name="message"
                                  cols={30}
                                  rows={4}
                                  value={formData.message}
                                  onChange={handleChange}
                                  placeholder="write your message"
                                />
                                {formErrors.message && (
                                  <p className="text-danger">
                                    {formErrors.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12 pt-3">
                              <div className="form-info-wrap">
                                <button
                                  type="submit"
                                  className="common-btn bg-blue-style w-100 d-flex justify-content-center"
                                  onClick={(e) => {
                                    handleSubmit(e);
                                  }}
                                  disabled={submitLoad}
                                >
                                  {submitLoad ? (
                                    <div
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    "Submit Now"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      {template?.navigation[index]?.contactSection?.imgUrl ? (
                        <div
                          className="h-100 overflow-hidden"
                          style={{
                            backgroundImage: `url(${template?.navigation[index]?.contactSection?.imgUrl})`,
                            borderRadius: "24px",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        ></div>
                      ) : (
                        <div className="contact-hero h-100 overflow-hidden"></div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </>

            {/* <Footer /> */}
            <section className="footer-area bg_color_black pt-120">
              <div className="container">
                <div className="row justify-content-between footer-widget-wrapper">
                  <div className="col-lg-3">
                    <div className="footer-widget">
                      <div className="footer-desc">
                        {template?.footerSection?.footerLogo ? (
                          <img
                            src={template?.footerSection?.footerLogo}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("./assets/images/logo.svg").default}
                            alt=""
                          />
                        )}
                        <p className="text_color_light_white mt-3">
                          {template.footerSection.description ??
                            `At Power AI, we offer affordable and comprehensive range of AI
                  solutions, that empower drive growth, and enhance efficiency
                  to meet your unique needs.`}
                        </p>
                        <ul className="custom-ul mt-3 social-list d-flex align-items-center flex-wrap">
                          {template?.footerSection?.socialLink?.facebook && (
                            <li>
                              <a
                                href={
                                  template?.footerSection?.socialLink
                                    ?.facebook ?? ""
                                }
                                target="_blank"
                              >
                                <svg
                                  width={32}
                                  height={32}
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M29.3333 16.001C29.3333 22.6543 24.4533 28.1743 18.08 29.1743V20.001H21.3333L21.9333 16.001H18.08V13.4943C18.08 12.4409 18.6 11.4143 20.2533 11.4143H21.9333V8.25429C21.9333 8.25429 20.4133 7.98763 18.9467 7.98763C15.9067 7.98763 13.9066 9.70761 13.9066 13.0543V15.9876H10.68V19.9876H13.9066V29.1609C7.53331 28.1609 2.65332 22.641 2.65332 15.9876C2.65332 8.62763 8.62666 2.6543 15.9867 2.6543C23.3467 2.6543 29.32 8.62763 29.32 15.9876"
                                    fill="currentColor"
                                  />
                                </svg>
                              </a>
                            </li>
                          )}
                          {template?.footerSection?.socialLink?.instagram && (
                            <li>
                              <a
                                href={
                                  template?.footerSection?.socialLink
                                    ?.instagram ?? ""
                                }
                                target="_blank"
                              >
                                <svg
                                  width={32}
                                  height={32}
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M27.92 11.0537C27.8266 9.13365 27.3866 7.427 25.9866 6.027C24.5866 4.627 22.88 4.18701 20.96 4.09367C18.9733 3.98701 13.0399 3.98701 11.0666 4.09367C9.14661 4.18701 7.45329 4.627 6.03996 6.027C4.62663 7.427 4.19994 9.13365 4.10661 11.0537C3.99994 13.0403 3.99994 18.9737 4.10661 20.9603C4.19994 22.8803 4.63996 24.587 6.03996 25.987C7.45329 27.387 9.14661 27.827 11.0666 27.9203C13.0533 28.027 18.9866 28.027 20.96 27.9203C22.88 27.827 24.5866 27.387 25.9866 25.987C27.3866 24.587 27.8266 22.8803 27.92 20.9603C28.0266 18.9737 28.0266 13.0403 27.92 11.067V11.0537ZM16 22.3337C12.5066 22.3337 9.66663 19.4937 9.66663 16.0003C9.66663 12.507 12.5066 9.667 16 9.667C19.4933 9.667 22.3333 12.507 22.3333 16.0003C22.3333 19.4937 19.4933 22.3337 16 22.3337ZM23.3599 10.0003C22.6266 10.0003 22.0266 9.40033 22.0266 8.667C22.0266 7.93366 22.6133 7.33366 23.3599 7.33366C24.0933 7.33366 24.6933 7.93366 24.6933 8.667C24.6933 9.40033 24.0933 10.0003 23.3599 10.0003ZM20.3333 16.0003C20.3333 18.387 18.3866 20.3337 16 20.3337C13.6133 20.3337 11.6666 18.387 11.6666 16.0003C11.6666 13.6137 13.6133 11.667 16 11.667C18.3866 11.667 20.3333 13.6137 20.3333 16.0003Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </a>
                            </li>
                          )}
                          {template?.footerSection?.socialLink?.youtube && (
                            <li>
                              <a
                                href={
                                  template?.footerSection?.socialLink
                                    ?.youtube ?? ""
                                }
                                target="_blank"
                              >
                                <svg
                                  width="34"
                                  height="34"
                                  viewBox="0 0 34 34"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_2517_8349)">
                                    <path
                                      d="M31.0004 16.9071C31.0095 15.7073 30.9731 14.5075 30.864 13.3122C30.7958 12.3987 30.6868 11.4898 30.5095 10.5899C30.1687 8.88112 29.037 7.74493 27.3827 7.50406C26.4193 7.36317 25.4467 7.23592 24.4696 7.20411C21.9245 7.1223 19.3795 7.04504 16.8299 7.01323C16.6935 7.00868 16.5617 7.00414 16.4254 6.99959C16.4254 7.00414 16.4254 7.00868 16.4254 7.00868C16.0663 7.00414 15.7073 6.99959 15.3483 6.99959C12.3487 6.98596 9.34921 7.05867 6.35877 7.37226C5.59071 7.45407 4.82719 7.58132 4.15457 8.01761C3.0502 8.73114 2.54119 9.79461 2.41848 11.049C2.41393 11.0944 2.40939 11.1399 2.40484 11.1853C2.37303 11.3853 2.34122 11.5852 2.31849 11.7852C2.07762 13.6667 1.98673 15.5528 2.00036 17.4434C1.99127 18.6432 2.02763 19.843 2.1367 21.0383C2.20487 21.9518 2.31395 22.8607 2.49119 23.7606C2.83205 25.4694 3.96369 26.6056 5.61798 26.8465C6.58146 26.9874 7.55404 27.1146 8.53116 27.1464C11.0762 27.2282 13.6213 27.3055 16.1709 27.3373C16.3072 27.3419 16.439 27.3464 16.5753 27.3509C16.5753 27.3464 16.5753 27.3419 16.5753 27.3419C16.9344 27.3464 17.2934 27.3509 17.6525 27.3509C20.652 27.3646 23.6515 27.2919 26.642 26.9783C27.4146 26.8965 28.1781 26.7692 28.8507 26.3375C29.9551 25.6239 30.4641 24.5605 30.5868 23.3061C30.5913 23.2607 30.5959 23.2152 30.6004 23.1698C30.6322 22.9698 30.6641 22.7698 30.6868 22.5699C30.9231 20.6884 31.014 18.7977 31.0004 16.9071ZM12.735 21.511C12.735 18.6114 12.735 15.7528 12.735 12.8396C15.2483 14.2848 17.7297 15.7164 20.2611 17.1753C17.7388 18.6296 15.2528 20.0612 12.735 21.511Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2517_8349">
                                      <rect
                                        width="29"
                                        height="20.3559"
                                        fill="white"
                                        transform="translate(2 7)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </a>
                            </li>
                          )}
                          {template?.footerSection?.socialLink?.twitter && (
                            <li>
                              <a
                                href={
                                  template?.footerSection?.socialLink
                                    ?.twitter ?? ""
                                }
                                target="_blank"
                              >
                                <svg
                                  width={32}
                                  height={32}
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M26.5868 10.6527C26.6001 10.8794 26.6001 11.1194 26.6001 11.3594C26.6001 18.4794 21.1067 26.6794 11.0534 26.6794C7.96007 26.6794 5.08008 25.7994 2.66675 24.266C3.10675 24.3194 3.53342 24.3327 3.98676 24.3327C6.54676 24.3327 8.89342 23.4794 10.7734 22.0394C8.37342 21.986 6.36008 20.4394 5.66675 18.306C6.00008 18.3594 6.34675 18.386 6.69342 18.386C7.18675 18.386 7.68009 18.3194 8.13342 18.1994C5.62676 17.6927 3.74674 15.5327 3.74674 12.9194V12.8527C4.48008 13.2527 5.32009 13.506 6.21342 13.5327C4.74675 12.5594 3.77342 10.9194 3.77342 9.05269C3.77342 8.05269 4.04008 7.1327 4.52008 6.3327C7.21341 9.59937 11.2534 11.7327 15.7867 11.9727C15.7067 11.5727 15.6534 11.1594 15.6534 10.746C15.6534 7.7727 18.0934 5.35938 21.1201 5.35938C22.6934 5.35938 24.1201 6.01269 25.1068 7.05269C26.3468 6.82603 27.5201 6.37271 28.5734 5.75938C28.1734 7.01271 27.3068 8.06603 26.1734 8.71936C27.2668 8.59936 28.3334 8.30603 29.3201 7.8927C28.5734 8.95937 27.6401 9.90603 26.5734 10.6794L26.5868 10.6527Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </a>
                            </li>
                          )}
                          {template?.footerSection?.socialLink?.linkedin && (
                            <li>
                              <a
                                href={
                                  template?.footerSection?.socialLink
                                    ?.linkedin ?? ""
                                }
                                target="_blank"
                              >
                                <svg
                                  width={32}
                                  height={32}
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.38659 28.0009H4.33327V12.8409H9.38659V28.0009ZM9.89327 6.94759C9.89327 5.34759 8.62661 4.05425 7.02661 4.00091C5.39994 3.96091 4.03994 5.2409 3.99994 6.86757C3.95994 8.49424 5.23993 9.85424 6.86659 9.89424C8.51993 9.90758 9.8666 8.60092 9.89327 6.94759ZM27.9333 18.8409C27.9333 14.1209 24.8799 12.6809 22.2799 12.6809C20.4533 12.6276 18.7333 13.5209 17.7333 15.0409V12.8542H12.8533V28.0143H17.9066V20.1476C17.9066 20.0676 17.9066 19.9876 17.9066 19.9076C17.9066 19.9076 17.9066 19.9076 17.9066 19.8942C17.7999 18.2809 19.0266 16.8809 20.6399 16.7742C21.9599 16.7742 22.9599 17.6276 22.9599 20.0276V28.0143H28.0133L27.9466 18.8542L27.9333 18.8409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6">
                    <div className="footer-widget d-lg-flex justify-content-center">
                      <div className="footer-link-wrap">
                        <h3 className="footer-title">Company</h3>
                        <ul className="custom-ul footer-link">
                          {template?.navigation?.map((item, index) => {
                            return (
                              <li>
                                <a
                                  onClick={() => {
                                    const section = document.getElementById(
                                      valueForScroll(item.key)
                                    );
                                    if (section) {
                                      section.scrollIntoView({
                                        behavior: "smooth",
                                      });
                                    }
                                  }}
                                >
                                  {item.navigationTitle}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6">
                    <div className="footer-widget d-lg-flex justify-content-center">
                      <div className="footer-link-wrap">
                        <h3 className="footer-title">Use Cases</h3>
                        <ul className="custom-ul footer-link">
                          {template?.navigation[
                            index
                          ]?.serviceSection?.services?.map((item, ind) => {
                            return (
                              <li>
                                <a onClick={() => scrollToSection("service")}>
                                  {item?.serviceTitle ?? ""}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="footer-widget">
                      <h3 className="footer-title">Join our Newsletter</h3>
                      <form
                        action="#"
                        className="footer-subscribe position-relative"
                      >
                        <input
                          className="w-100"
                          type="email"
                          placeholder="Enter your email"
                          value={subscribeData}
                          onChange={(e) => {
                            setSubscribeData(e.target.value);
                          }}
                        />
                        <button
                          className="common-btn border-style border-style-transparent position-absolute top-50 translate-middle-y"
                          disabled={subscribeLoad}
                          onClick={(e) => {
                            e.preventDefault();
                            setSubscribeLoad(true);
                            if (
                              subscribeData.trim() === "" ||
                              !emailRegex.test(subscribeData)
                            ) {
                              message.error(
                                "Please enter a valid email address"
                              );
                              setSubscribeLoad(false);
                            } else {
                              dispatch(
                                subscribeUser({
                                  email: subscribeData,
                                  domain: window.location.hostname,
                                })
                              )
                                .then((data) => {
                                  if (
                                    data?.payload?.data?.subscribeUser !== null
                                  ) {
                                    message.success(
                                      "Subscribed to Newsletter successfully"
                                    );
                                    setSubscribeData("");
                                    setSubscribeLoad(false);
                                  }
                                })
                                .catch((error) => {
                                  console.error("Login error:", error);
                                  setSubscribeData("");
                                  setSubscribeLoad(false);
                                });
                            }
                          }}
                        >
                          {subscribeLoad ? (
                            <LoadingOutlined spin className="me-2" />
                          ) : (
                            "Subscribe"
                          )}
                        </button>
                      </form>
                      <p className="text_color_light_white mt-3">
                        We will send you weekly updates for your better Product
                        management.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="copy-right-wrap d-flex justify-content-between flex-column flex-lg-row">
                      <p className="text_color_light_white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99992 15.1673C4.04792 15.1673 0.833252 11.9527 0.833252 8.00065C0.833252 4.04865 4.04792 0.833984 7.99992 0.833984C11.9519 0.833984 15.1666 4.04865 15.1666 8.00065C15.1666 11.9527 11.9519 15.1673 7.99992 15.1673ZM7.99992 1.83398C4.59925 1.83398 1.83325 4.59998 1.83325 8.00065C1.83325 11.4013 4.59925 14.1673 7.99992 14.1673C11.4006 14.1673 14.1666 11.4013 14.1666 8.00065C14.1666 4.59998 11.4006 1.83398 7.99992 1.83398ZM10.4112 9.61865C10.5679 9.39198 10.5113 9.08001 10.2846 8.92334C10.0573 8.76534 9.74596 8.8233 9.58862 9.04997C9.24862 9.5413 8.68918 9.83463 8.09318 9.83463C7.08185 9.83463 6.25985 9.01264 6.25985 8.0013C6.25985 6.98997 7.08185 6.16797 8.09318 6.16797C8.68985 6.16797 9.24862 6.4613 9.58862 6.95264C9.74596 7.1793 10.0573 7.23793 10.2846 7.07926C10.5119 6.92193 10.5685 6.61062 10.4112 6.38395C9.88388 5.62262 9.0172 5.16862 8.09253 5.16862C6.52986 5.16862 5.2592 6.43995 5.2592 8.00195C5.2592 9.56395 6.52986 10.8353 8.09253 10.8353C9.0172 10.834 9.88388 10.3793 10.4112 9.61865Z"
                            fill="currentColor"
                            fillOpacity="0.7"
                          />
                        </svg>
                        {template?.footerSection?.copyRight ??
                          "Copyright 2023 PowerAI. Inc."}
                      </p>
                      <ul className="custom-ul footer-menu d-flex flex-wrap align-items-center">
                        <li>
                          <a
                            href={`${template?.footerSection?.termUrl ?? ""}`}
                            target="_blank"
                          >
                            Terms of Service
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${
                              template?.footerSection?.privacyUrl ?? ""
                            }`}
                            target="_blank"
                          >
                            Privacy policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      ) : (
        <div>
          <Spin
            spinning={true}
            size="large"
            style={{ width: "100%", marginTop: "20%" }}
          />
        </div>
      )}
    </>
  );
};

export default TemplateOne;
