import React, { useState } from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { usersActions } from "../../../../../models";
import "../../styles.scss";
const FAQSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const updateData = (updatedFaqs) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          faqSection: {
            ...data.faqSection,
            faqs: updatedFaqs,
          },
        },
      })
    );
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">FAQ Section</div>
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            placeholder="Title"
            value={data?.faqSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    faqSection: {
                      ...data.faqSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sub Title</label>
          <textarea
            className="form-control"
            rows={3}
            placeholder="Sub Title"
            value={data?.faqSection?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    faqSection: {
                      ...data.faqSection,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label">Short Description</label>
          <textarea
            className="form-control"
            rows={3}
            placeholder="Sub Title"
            value={data?.faqSection?.shortDesc}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    faqSection: {
                      ...data.faqSection,
                      shortDesc: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <UncontrolledAccordion
          defaultOpen="heading0"
          className="accordion-block-section"
          open={open}
          toggle={toggle}
        >
          {data?.faqSection?.faqs?.map((faq, index) => (
            <AccordionItem>
              <AccordionHeader targetId={`heading${index}`}>
                FAQ {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`heading${index}`}>
                <>
                  <div className="form-group">
                    <label className="form-label">Question</label>
                    <input
                      className="form-control"
                      type="text"
                      value={faq.question}
                      placeholder="Question"
                      onChange={(e) => {
                        const updatedFaqs = [...data.faqSection.faqs];
                        updatedFaqs[index] = {
                          ...updatedFaqs[index],
                          question: e.target.value,
                        };
                        updateData(updatedFaqs);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Answer</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={faq.answer}
                      placeholder="Answer"
                      onChange={(e) => {
                        const updatedFaqs = [...data.faqSection.faqs];
                        updatedFaqs[index] = {
                          ...updatedFaqs[index],
                          answer: e.target.value,
                        };
                        updateData(updatedFaqs);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-xs btn-global-danger"
                    onClick={() => {
                      const updatedFaqs = [...data.faqSection.faqs];
                      updatedFaqs.splice(index, 1);
                      updateData(updatedFaqs);
                    }}
                  >
                    Remove
                  </button>
                </>
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
        <div className="">
          <button
            className="btn btn-xs btn-global-primary"
            onClick={() => {
              const updatedFaqs = [
                ...data.faqSection.faqs,
                {
                  question: "",
                  answer: "",
                },
              ];
              updateData(updatedFaqs);
            }}
          >
            Add FAQ
          </button>
        </div>
      </div>
    </>
  );
};

export default FAQSection;
