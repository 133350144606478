import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 15 15"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.423 7.506l6.014-5.992a.653.653 0 000-.918.64.64 0 00-.911-.018L7.513 6.571 1.577.578a.642.642 0 00-.936 0 .645.645 0 000 .866l5.936 5.984-6.014 5.985a.653.653 0 000 .918c.12.123.285.192.456.19a.658.658 0 00.48-.156l6.014-5.993 6.013 6.062c.12.124.284.193.455.191a.633.633 0 00.456-.19.653.653 0 000-.918l-6.014-6.01z"
    ></path>
  </svg>
);

export default SvgComponent;
