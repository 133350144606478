import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    fill="none"
    viewBox="0 0 8 14"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.046 12.094a.952.952 0 00-.28-.676L2.35 7l4.416-4.416a.956.956 0 00-1.351-1.351L.325 6.327a.956.956 0 000 1.35l5.092 5.092a.957.957 0 001.629-.675z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
