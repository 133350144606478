export const default_template = {
  templateID: "",
  logo: null,
  favIcon: null,
  navigation: [
    {
      key: "Home",
      navigationTitle: "Demo",
      browserTitle: "demo",
      urlSlug: `${process.env.REACT_APP_HOST_URL}/`,
      heroSection: {
        backgroundImg: null,
        subTitle: "SOAR TO NEW HEIGHTS!",
        headingTitle: "Elevate Your Experience With Power AI",
        shortDesc:
          "Unleash the next level of innovation and efficiency with Power AI, transforming your digital journey into a realm of unparalleled possibilities.",
        button1Title: "Get Started",
        button1Url: "",
        button2Title: "Watch Demo",
        button2Url: "",
        heroImg: null,
      },
      organizationSection: {
        organizationLogo: [],
        description:
          "Power AI is used by over 15,000+ companies across the globe",
      },
      aboutUsSection: {
        heading: "WHO WE ARE",
        subHeading: "About Power AI",
        firstDescription:
          "At Power AI, we are on a mission to revolutionize industries and redefine possibilities through Artificial Intelligence.",
        secondDescription:
          "With a team of passionate experts, we combine innovation, expertise, and a commitment to excellence to create intelligent solutions.",
      },
      serviceSection: {
        title: "Our Services",
        subTitle:
          "Embark on a personalized journey of innovation with our transformation AI solutions, tailored to meet your unique needs and drive unparalleled success.",
        services: [
          {
            serviceTitle: "Data Analytics",
            serviceLogo: null,
            serviceDescription:
              "Unlocking insights and patterns from vast datasets, data analytics empowers informed decision-making and drives innovation.",
          },
          {
            serviceTitle: "Training And Workshops",
            serviceLogo: null,
            serviceDescription:
              "We specialize in custom AI development to precisely meet and exceed the unique needs of your organization, ushering in a future.",
          },
          {
            serviceTitle: "Custom AI Design",
            serviceLogo: null,
            serviceDescription:
              "Tailoring artificial intelligence to specific needs, custom AI design merges creativity with cutting-edge technology to address unique challenges.",
          },
          {
            serviceTitle: "AI Integration",
            serviceLogo: null,
            serviceDescription:
              "Seamlessly blending artificial intelligence into existing systems, AI integration optimizes, and opens new frontiers for advanced solving.",
          },
          {
            serviceTitle: "Custom AI Development",
            serviceLogo: null,
            serviceDescription:
              "We specialize in custom AI development to precisely meet and exceed the unique needs of your organization, ushering in a future.",
          },
          {
            serviceTitle: "AI Consulting",
            serviceLogo: null,
            serviceDescription:
              "We specialize in custom AI development to precisely meet and exceed the unique needs of your organization, ushering in a future.",
          },
        ],
      },
      industriesSection: {
        title: "Industries We Serve",
        subTitle:
          "We empower diverse sectors with intelligent solutions that seamlessly integrate into a spectrum of industries, driving innovation across the board.",
        industries: [
          {
            image: null,
            heading: "REVOLUTIONIZING RETAIL",
            description:
              "Elevate Experiences, Forecast Demand, Personalize Marketing",
            shortDesc:
              "Revolutionizing retail with enhanced experiences, demand forecasting, and personalized marketing for dynamic success",
          },
          {
            image: null,
            heading: "FINCH TRIUMPH",
            description:
              "Smart Detection, Algorithmic Trading, And Data Insights",
            shortDesc:
              "In finance, smart detection, algorithmic trading, and data insights converge for seamless decision-making and strategic market moves.",
          },
          {
            image: null,
            heading: "REVOLUTIONIZING HEALTHCARE",
            description:
              "Harnessing AI For Diagnostics And Personalized Treatments",
            shortDesc:
              "Revolutionizing healthcare with AI-driven diagnostics and personalized treatments for a more precise and innovative medical experience. we reliantly strive to bring about transformative changes in the healthcare sector",
          },
        ],
      },
      achievementSection: {
        title: "See Our Achievement",
        accuracy: "98%",
        globalUsers: "5M +",
        secondDescription:
          "With a team of passionate experts, we combine innovation, expertise, and a commitment to excellence to create intelligent solutions.",
      },
      resourceSection: {
        title: "Our Useful Resources",
        subTitle:
          "Dive into the forefront of innovation and thought leadership with our latest resources. Where insightful content meets the pulse of contemporary ideas, keeping you informed and inspired.",
        resources: [
          {
            title: "A Journey Into The Heart Of Innovation",
            image: null,
            shortDesc:
              "Artificial Intelligence (AI) has transcended the realm of science fiction, becoming a pervasive force that...",
          },
          {
            title: "The Intersection between Technology and Art",
            image: null,
            shortDesc:
              "Contrary to popular belief, AI is not just algorithms and data; it's also a powerful tool for unleashing...",
          },
          {
            title: "Tackle Global Challenges with Technology",
            image: null,
            shortDesc:
              "Explore the inspiring initiatives where AI is harnessed for the greater good. From addressing environment...",
          },
          {
            title: "A Journey Into The Heart Of Innovation",
            image: null,
            shortDesc:
              "Artificial Intelligence (AI) has transcended the realm of science fiction, becoming a pervasive force that...",
          },
          {
            title: "The Intersection between Technology and Art",
            image: null,
            shortDesc:
              "Contrary to popular belief, AI is not just algorithms and data; it's also a powerful tool for unleashing...",
          },
          {
            title: "Tackle Global Challenges with Technology",
            image: null,
            shortDesc:
              "Explore the inspiring initiatives where AI is harnessed for the greater good. From addressing environment...",
          },
        ],
      },
      commentSection: {
        title: "What our users are saying",
        subTitle:
          "Explore the authentic voices of user satisfaction in our testimonial section. Echoing diverse and exceptional experiences with our products.",
        comments: [
          {
            rating: 5,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
          {
            rating: 3,
            comment:
              "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
            name: "Adam Zampol",
            address: "CEO Kingoston Corps, USA",
          },
          {
            rating: 2,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
          {
            rating: 5,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
          {
            rating: 3,
            comment:
              "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
            name: "Adam Zampol",
            address: "CEO Kingoston Corps, USA",
          },
          {
            rating: 2,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
        ],
      },
      faqSection: {
        title: "Frequently Asked Questions",
        subTitle:
          'Explore the answers you seek in our "Frequently Asked Questions" section, your go-to resource for quick insights into the world of artificial intelligence.',
        shortDesc:
          "From understanding AI applications to learning about our services, we've condensed the information you need to kickstart your exploration of this transformation technology.",
        faqs: [
          {
            question: "How Can I Get Started With Power AI's Services?",
            answer:
              "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
          },
          {
            question: "What Is Artificial Intelligence (AI)?",
            answer:
              "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
          },
          {
            question: "What Services Does Power AI Provide?",
            answer:
              "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
          },
          {
            question: "Is Power AI Suitable For Small Businesses?",
            answer:
              "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
          },
        ],
      },
      epilogue: {
        title: "Start Your Journey With Power AI",
        subTitle:
          "Embark on a transformation journey into the future of innovation as you kickstart your exploration of Power AI, where limitless possibilities await at every click and command.",
        button1Title: "Get Started",
        button1Url: "",
        button2Title: "Contact Us",
        button2Url: "",
      },
    },
    {
      key: "About",
      navigationTitle: "About",
      browserTitle: "about",
      urlSlug: `${process.env.REACT_APP_HOST_URL}/about`,
      breadcrumbSection: {
        title: "HOME/ABOUT US",
        headingTitle: "About Us",
        description:
          "We will help a client's problems to develop the product s they have with high quality Change the appearance.",
        backgroundImg: null,
      },
      commitmentSection: {
        title: "Our Commitment To Deliver The Best AI Model",
        description:
          "Dedicated to excellence, our commitment echoes through every line of code as we tirelessly strive to deliver the epitome of artificial intelligence models",
        backgroundImg: null,
      },
      commentSection: {
        title: "What our users are saying",
        subTitle:
          "Explore the authentic voices of user satisfaction in our testimonial section. Echoing diverse and exceptional experiences with our products.",
        comments: [
          {
            rating: 5,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
          {
            rating: 3,
            comment:
              "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
            name: "Adam Zampol",
            address: "CEO Kingoston Corps, USA",
          },
          {
            rating: 2,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
          {
            rating: 5,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
          {
            rating: 3,
            comment:
              "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
            name: "Adam Zampol",
            address: "CEO Kingoston Corps, USA",
          },
          {
            rating: 2,
            comment:
              "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
            name: "Sarah Leon",
            address: "Business Owner, Dubai",
          },
        ],
      },
    },
    {
      key: "Blog",
      navigationTitle: "Blog",
      browserTitle: "blog",
      urlSlug: `${process.env.REACT_APP_HOST_URL}/blog`,
    },
    {
      key: "Service",
      navigationTitle: "Service",
      browserTitle: "Service",
      urlSlug: `${process.env.REACT_APP_HOST_URL}/service`,
    },
    {
      key: "Contact",
      navigationTitle: "Contact",
      browserTitle: "contact",
      urlSlug: `${process.env.REACT_APP_HOST_URL}/contact`,
    },
  ],

  footerSection: {
    footerLogo: null,
    description: "",
    socialLink: {
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
      youtube: "",
    },
  },
};
