import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplate,
  getUsersState,
  usersActions,
} from "../../models/slice/users.slice";
import { Spin } from "antd";
import { default_template } from "./template";
// import { saveState, store } from "../../models";

export const Editor = () => {
  const { templateId } = useParams();
  const { template } = useSelector((rootState) => getUsersState(rootState));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dataFetch();
  }, []);
  const dataFetch = () => {
    setLoading(true);
    dispatch(getTemplate({ _id: templateId }))
      .then(async (data) => {
        if (data.payload !== null) {
          const value = data.payload.data.getTemplate.metadata;
          // console.log(value, typeof value);
          const initial = JSON.parse(value);
          // console.log(initial, typeof initial);
          dispatch(usersActions.setInitial(initial));
          dispatch(usersActions.setTemplateId(templateId));
          const tempName = data?.payload?.data?.getTemplate?.templateName;
          dispatch(usersActions.setTemplateName(tempName));
          const domName = data?.payload?.data?.getTemplate?.domain;
          dispatch(usersActions.setDomainName(domName));
          setLoading(false);
          setTimeout(() => {
            setTimeout(() => dispatch(usersActions.setAutoScroll("hero")));
          }, 1500);
        }
      })
      .catch((error) => {
        console.error("Id error:", error);
        setLoading(false);
        dispatch(usersActions.setInitial(default_template));
        dispatch(usersActions.setTemplateId(templateId));
      });
  };
  return (
    <>
      {!loading ? (
        <iframe
          id="iframe_preview"
          className="template-iframe"
          src={`${process.env.REACT_APP_HOST_URL}/`}
          allowFullScreen={true}
          width="100%"
          height="100%"
          // sandbox="allow-scripts allow-forms allow-pointer-lock  allow-popups allow-same-origin allow-top-navigation"
        />
      ) : (
        <div className="loader-container">
          <Spin size="large" style={{ marginRight: 15 }} />
          <p>Loading...</p>
        </div>
      )}
    </>
  );
};
