import React, { useState } from "react";
import { CaretLeftIcon } from "../../../../svg-icons";
import Dropzone from "react-dropzone";
import { uploadFile, usersActions } from "../../../../../models";
import "../../styles.scss";

const HeroSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  const [changeBackground, setChangeBackground] = useState(false);
  const [changeHero, setChangeHero] = useState(false);
  const handleUpload = (acceptedFiles, key) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const imageData = await uploadFile(file);
      switch (key) {
        case "background":
          dispatch(
            usersActions.setNavigationData({
              key: page,
              updatedObject: {
                ...data,
                heroSection: {
                  ...data.heroSection,
                  backgroundImg: imageData,
                },
              },
            })
          );
          break;
        case "heroImg":
          dispatch(
            usersActions.setNavigationData({
              key: page,
              updatedObject: {
                ...data,
                heroSection: {
                  ...data.heroSection,
                  heroImg: imageData,
                },
              },
            })
          );
          break;

        default:
          // Handle default case if needed
          break;
      }
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Hero Section</div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Background Image</h5>
        <p className="e-aside-head-subtitle">1920 x 1080 recommended</p>
      </div>
      <div className="e-aside-body-content mb-3">
        {!changeBackground ? (
          <div className="e-preview">
            <div className="e-preview-thumb e-preview-thumb-logo">
              {data?.heroSection?.backgroundImg ? (
                <img
                  src={data?.heroSection?.backgroundImg}
                  alt="Background Image"
                />
              ) : (
                <img
                  src={require("../../../../../views/templates/template-1/assets/images/hero-bg-canvas.png")}
                  alt="Background Image"
                />
              )}
            </div>
            <div className="e-preview-cta">
              <button
                type="button"
                className="btn btn-change"
                onClick={() => setChangeBackground(true)}
              >
                Change
              </button>
              <button
                type="button"
                className="btn btn-remove"
                onClick={() => setChangeBackground(true)}
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <div className="dropzone dropzone-sm">
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleUpload(acceptedFiles, "background");
                setChangeBackground(false);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone-inner">
                  <div className="dropzone-inner-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-text">
                      Drag & Drop <br /> or
                    </div>
                    <button className="btn btn-dark btn-upload">Upload</button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>

      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Sub Title"
            value={data?.heroSection?.subTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      subTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Heading Title"
            value={data?.heroSection?.headingTitle}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      headingTitle: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Short Description"
            value={data?.heroSection?.shortDesc}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      shortDesc: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button Title"
            value={data?.heroSection?.button1Title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      button1Title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button URL"
            value={data?.heroSection?.button1Url}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      button1Url: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button Title"
            value={data?.heroSection?.button2Title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      button2Title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Button URL"
            value={data?.heroSection?.button2Url}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    heroSection: {
                      ...data.heroSection,
                      button2Url: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Hero Image</h5>
        <p className="e-aside-head-subtitle">380 x 380 recommended</p>
      </div>
      <div className="e-aside-body-content">
        {!changeHero ? (
          <div className="e-preview">
            <div className="e-preview-thumb e-preview-thumb-logo">
              {data?.heroSection?.heroImg ? (
                <img src={data?.heroSection?.heroImg} alt="Hero Image" />
              ) : (
                <img
                  src={require("../../../../../views/templates/template-1/assets/images/hero/hero-1.png")}
                  alt="Hero Image"
                />
              )}
            </div>
            <div className="e-preview-cta">
              <button
                type="button"
                className="btn btn-change"
                onClick={() => setChangeHero(true)}
              >
                Change
              </button>
              <button
                type="button"
                className="btn btn-remove"
                onClick={() => setChangeHero(true)}
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <div className="dropzone dropzone-sm">
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleUpload(acceptedFiles, "heroImg");
                setChangeHero(false);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone-inner">
                  <div className="dropzone-inner-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-text">
                      Drag & Drop <br /> or
                    </div>
                    <button className="btn btn-dark btn-upload">Upload</button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroSection;
