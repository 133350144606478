import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { CaretLeftIcon } from "../../../../svg-icons";
import { uploadFile, usersActions } from "../../../../../models";
const ContactFormSection = ({ data, setHomeMenu, dispatch, page, setPage }) => {
  const [changeImage, setChangeImage] = useState(false);
  const handleUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const imageData = await uploadFile(file);
      dispatch(
        usersActions.setNavigationData({
          key: page,
          updatedObject: {
            ...data,
            contactSection: {
              ...data.contactSection,
              imgUrl: imageData,
            },
          },
        })
      );
    };
    reader.readAsDataURL(file);
  };
  const updateData = (updatedInfo) => {
    dispatch(
      usersActions.setNavigationData({
        key: page,
        updatedObject: {
          ...data,
          contactSection: {
            ...data.contactSection,
            info: updatedInfo,
          },
        },
      })
    );
  };
  return (
    <>
      <div className="e-aside-header e-aside-subheader">
        <button
          className="btn btn-e-aside-nav"
          onClick={() => {
            setHomeMenu(null);
            setPage(null);
          }}
        >
          <CaretLeftIcon />
        </button>
        <div className="e-aside-header-title">Contact Form</div>
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Heading Title"
            value={data?.contactSection?.title}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    contactSection: {
                      ...data?.contactSection,
                      title: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
        <div className="form-group">
          <textarea
            cols={3}
            className="form-control"
            placeholder="Description"
            value={data?.contactSection?.description}
            onChange={(e) =>
              dispatch(
                usersActions.setNavigationData({
                  key: page,
                  updatedObject: {
                    ...data,
                    contactSection: {
                      ...data.contactSection,
                      description: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Info 1</h5>
        <p className="e-aside-head-subtitle">Some suggestion</p>
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Title"
            value={data?.contactSection?.info[0]?.title}
            onChange={(e) => {
              const updatedInfo = [...data.contactSection?.info];
              updatedInfo[0] = {
                ...updatedInfo[0],
                title: e.target.value,
              };
              updateData(updatedInfo);
            }}
          />
        </div>
        <div className="form-group">
          <textarea
            cols={2}
            className="form-control"
            placeholder="Description"
            value={data?.contactSection?.info[0]?.description}
            onChange={(e) => {
              const updatedInfo = [...data?.contactSection?.info];
              updatedInfo[0] = {
                ...updatedInfo[0],
                description: e.target.value,
              };
              updateData(updatedInfo);
            }}
          />
        </div>
      </div>
      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Info 2</h5>
        <p className="e-aside-head-subtitle">Some suggestion</p>
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Title"
            value={data.contactSection?.info[1]?.title}
            onChange={(e) => {
              const updatedInfo = [...data.contactSection?.info];
              updatedInfo[1] = {
                ...updatedInfo[1],
                title: e.target.value,
              };
              updateData(updatedInfo);
            }}
          />
        </div>
        <div className="form-group">
          <textarea
            cols={2}
            className="form-control"
            placeholder="Description"
            value={data?.contactSection?.info[1]?.description}
            onChange={(e) => {
              const updatedInfo = [...data?.contactSection?.info];
              updatedInfo[1] = {
                ...updatedInfo[1],
                description: e.target.value,
              };
              updateData(updatedInfo);
            }}
          />
        </div>
      </div>
      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Info 3</h5>
        <p className="e-aside-head-subtitle">Some suggestion</p>
      </div>
      <div className="e-aside-body-content">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Title"
            value={data?.contactSection?.info[2]?.title}
            onChange={(e) => {
              const updatedInfo = [...data?.contactSection?.info];
              updatedInfo[2] = {
                ...updatedInfo[2],
                title: e.target.value,
              };
              updateData(updatedInfo);
            }}
          />
        </div>
        <div className="form-group">
          <textarea
            cols={2}
            className="form-control"
            placeholder="Description"
            value={data?.contactSection?.info[2]?.description}
            onChange={(e) => {
              const updatedInfo = [...data?.contactSection?.info];
              updatedInfo[2] = {
                ...updatedInfo[2],
                description: e.target.value,
              };
              updateData(updatedInfo);
            }}
          />
        </div>
      </div>

      <div className="e-aside-head">
        <h5 className="e-aside-head-title">Image</h5>
        <p className="e-aside-head-subtitle">540 x 768 recommended</p>
      </div>
      <div className="e-aside-body-content mb-3">
        {!changeImage ? (
          <div className="e-preview">
            <div className="e-preview-thumb e-preview-thumb-logo">
              {data?.contactSection?.imgUrl ? (
                <img src={data?.contactSection?.imgUrl} alt="" />
              ) : (
                <img
                  src={require("../../../../../views/templates/template-1/assets/images/contact.png")}
                  alt=""
                />
              )}
            </div>
            <div className="e-preview-cta">
              <button
                type="button"
                className="btn btn-change"
                onClick={() => {
                  setChangeImage(true);
                }}
              >
                Change
              </button>
              <button
                type="button"
                className="btn btn-remove"
                onClick={() => {
                  setChangeImage(true);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        ) : (
          <div className="dropzone dropzone-sm">
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleUpload(acceptedFiles);
                setChangeImage(false);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone-inner">
                  <div className="dropzone-inner-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-text">
                      Drag & Drop <br /> or
                    </div>
                    <button className="btn btn-dark btn-upload">Upload</button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactFormSection;
