import { Link, useNavigate, useParams } from "react-router-dom";
import { CloseIcon } from "../../svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, message } from "antd";
import {
  getTemplate,
  getUsersState,
  resetTemplate,
  updateTemplate,
  usersActions,
} from "../../../models";
import html2canvas from "html2canvas";
const Head = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { template, templateName, domainName } = useSelector((rootState) =>
    getUsersState(rootState)
  );
  const save = () => {
    const parsedData = JSON.stringify(template);
    dispatch(updateTemplate({ templateId: templateId, metaData: parsedData }))
      .then((data) => {
        if (data.payload !== null) {
          message.success("Changes are saved");
        }
      })
      .catch((error) => {
        console.error("Id error:", error);
      });
  };
  const captureScreenshot = async () => {
    const iframe = document.getElementById("iframe_preview");

    if (!iframe) {
      console.error("Iframe not found");
      return;
    }

    const iframeDocument = iframe.contentWindow.document;

    // Check if the iframe content is loaded
    if (!iframeDocument || !iframeDocument.body) {
      console.error("Iframe content not loaded");
      return;
    }

    try {
      const canvas = await html2canvas(iframeDocument.body, {
        width: 1200,
        height: 900,
      });
      var imgData = canvas.toDataURL("image/png");
      console.log(imgData);
      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        if (!blob) return;

        // You can now handle the blob as needed (e.g., save to file, upload to server)
        console.log(blob);
      }, "image/png");
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  const handleClose = () => {
    // Check if template has changes
    let value = {};
    dispatch(getTemplate({ _id: templateId }))
      .then((data) => {
        if (data.payload !== null) {
          value = JSON.parse(data.payload.data.getTemplate.metadata);
        }
      })
      .catch((error) => {
        console.error("get template", error);
      });
    if (template !== value) {
      Modal.confirm({
        title: "Unsaved Changes",
        content: "Do you want to save changes before closing?",
        okText: "Save and Exit",
        cancelText: "Cancel",
        cancelButtonProps: {
          // Customize the props of the Cancel button
          type: "default",
        },
        okButtonProps: {
          // Customize the props of the Save and Exit button
          type: "primary",
          danger: true,
        },

        // width: 500, // Adjust the width of the modal if needed
        footer: (params) => [
          <Button
            key="cancel"
            type="primary"
            danger={true}
            onClick={() => {
              Modal.destroyAll();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="exitWithoutSaving"
            onClick={() => {
              navigate("/dashboard");
              Modal.destroyAll();
              dispatch(usersActions.setAutoScroll("#"));
              dispatch(usersActions.setTemplateName(""));
              dispatch(usersActions.setDomainName(""));
            }}
          >
            Exit without Saving
          </Button>,
          <Button
            key="link"
            type="primary"
            onClick={() => {
              save();
              navigate("/dashboard");
              Modal.destroyAll();
              dispatch(usersActions.setAutoScroll("#"));
              dispatch(usersActions.setTemplateName(""));
              dispatch(usersActions.setDomainName(""));
            }}
          >
            Save and Exit
          </Button>,
        ],
      });
    } else {
      navigate("/dashboard");
    }
  };
  const handleReset = () => {
    Modal.confirm({
      title: "Reset Template",
      content: "Do you want to reset the template?",
      okText: "Reset",
      cancelText: "Cancel",
      cancelButtonProps: {
        type: "default",
      },
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      onOk: () => {
        // console.log("resetting template");
        dispatch(resetTemplate({ templateId: templateId }))
          .then((dat) => {
            if (dat.payload !== null) {
              message.success("Reset the template successfully");
              dispatch(getTemplate({ _id: templateId })).then(async (data) => {
                if (data.payload !== null) {
                  const value = data.payload.data.getTemplate.metadata;
                  const initial = JSON.parse(value);
                  dispatch(usersActions.setInitial(initial));
                  dispatch(usersActions.setTemplateId(templateId));
                }
              });
            }
          })
          .catch((error) => {
            console.error("Id error:", error);
          });
      },
      onCancel: () => {},
    });
  };
  const handlePreviewClick = () => {
    window.open(`http://${domainName}`, "_blank");
  };
  return (
    <>
      <header className="head">
        <div className="head__container">
          <div className="head__row">
            <div className="head__left">
              <div className="head__close">
                {/* <Link
                  type="button"
                  className="btn btn__close"
                  // to={"/dashboard"}
                  aria-label="Close"
                  onClick={}
                > */}
                <CloseIcon onClick={() => handleClose()} />
                {/* </Link> */}
              </div>
              <div className="head__divider"></div>
              <div className="head__title">Editor</div>
              {templateName !== "" && (
                <>
                  <div className="head__divider"></div>
                  <div className="head__title">{templateName}</div>
                </>
              )}
            </div>
            <div className="head__right">
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-sm btn-global-primary"
                onClick={() => {
                  handlePreviewClick();
                }}
              >
                Preview
              </button>
              {/* <button type="button" className="btn btn-sm btn-global-primary">
                Publish
              </button> */}
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={() => {
                  save();
                  // captureScreenshot();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Head;
