import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { useSelector } from "react-redux";
import { getUsersState } from "../../../models";
import { useNavigate } from "react-router-dom";
function BlogPage() {
  const { autoRoute } = useSelector((rootState) => getUsersState(rootState));
  const navigate = useNavigate();
  useEffect(() => {
    if (autoRoute !== null && autoRoute !== "blog") {
      navigate(`/${autoRoute}`);
    }
  }, [autoRoute]);
  return (
    <>
      <main className="template-site">
        <div className="site-wrapper">
          <Header />
          {/* Breadcrumb */}
          <section className="breadcrumb-area minus-margin overflow-hidden position-relative z-index-one">
            <div className="container">
              <div className="row justify-content-center position-relative z-index-one">
                <div className="col-lg-5">
                  <div className="breadcrumb-info text-center">
                    <p className="breadcrumb-nav text_color_white">
                      <a href="index.html">Home </a>
                      <i className="bi bi-slash-lg" />
                      Blog
                    </p>
                    <h1 className="text_color_white">Blog</h1>
                    <p className="breadcrumb-desc text_color_light_white">
                      We will help a client's problems to develop the products
                      they have with high quality Change the appearance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <video
              className="breadcrumb-video-bg z-index-minus-one position-absolute top-0 start-0 h-100 w-100"
              playsInline="playsinline"
              autoPlay="autoplay"
              muted="muted"
              loop="loop"
            >
              <source
                src={require("./assets/images/video/breadcrumb.mp4")}
                type="video/mp4"
              />
            </video>
          </section>
          {/* Breadcrumb End */}
          {/* Blog */}
          <div className="blog-area bg_color_deep_blue pt-120 pb-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title gradient-sub-title text-center">
                    <h2 className="title text_color_white">
                      Our Useful Resources
                    </h2>
                    <p className="desc text_color_light_white max-width-728 section-middle">
                      Dive into the forefront of innovation and thought
                      leadership with our latest resources. Where insightful
                      content meets the pulse of contemporary ideas, keeping you
                      informed and inspired.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row pt-60 gy-4">
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-1.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        A Journey into the Heart of Innovation
                      </h2>
                      <p className="text_color_light_white">
                        Artificial Intelligence (AI) has transcended the realm
                        of science fiction, becoming a pervasive force that...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-3.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        Tackle Global Challenges with Technology
                      </h2>
                      <p className="text_color_light_white">
                        Explore the inspiring initiatives where AI is harnessed
                        for the greater good. From addressing environment...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-2.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        The Intersection between Technology and Art
                      </h2>
                      <p className="text_color_light_white">
                        Contrary to popular belief, AI is not just algorithms
                        and data; it's also a powerful tool for unleashing...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-4.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        Closing the design gap for optical devices
                      </h2>
                      <p className="text_color_light_white">
                        Photolithography involve manipulating light precisely
                        etch features onto a surface, and is commonly...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-5.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        A computer scientist pushes the boundaries of geometry
                      </h2>
                      <p className="text_color_light_white">
                        More than 2,000 years ago, the Greek mathematician
                        Euclid, known to many as the...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-6.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        AI Magazine considers the top 10 blogs on AI
                      </h2>
                      <p className="text_color_light_white">
                        With the AI industry rapidly hurtling towards an
                        innovative future across those keen to keep up ...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-7.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        Putting the art in artificial intelligence
                      </h2>
                      <p className="text_color_light_white">
                        Like many kids, Antonio Torralba began playing around
                        with computers when he was 13 years old....
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-8.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        The creative future of generative AI
                      </h2>
                      <p className="text_color_light_white">
                        Few technologies have shown as much potential to shape
                        our future as artificial intelligence. Specialist....
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="blog-box">
                    <div className="blog-img overflow-hidden">
                      <a href="blog-details.html">
                        <img
                          className="w-100"
                          src={require("./assets/images/resources/resources-9.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="blog-info">
                      <h2 className="text_color_white">
                        If art is how we express our humanity, where does AI fit
                      </h2>
                      <p className="text_color_light_white">
                        The rapid advance of artificial intelligence has
                        generated a lot of buzz, with some predicting it...
                      </p>
                      <a
                        className="border-btn border-btn-white"
                        href="blog-details.html"
                      >
                        Learn More
                        <svg
                          className="icon"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <a
                    className="common-btn bg-purple-style mt-lg-5 mt-3"
                    href="blog-details.html"
                  >
                    Load More
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Blog End */}
          {/* Subscribe */}
          <section className="subscribe-area bg_color_deep_blue position-relative z-index-one">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="subscribe-wrap subscribe-wrap-two overflow-hidden position-relative z-index-one">
                    <div className="section-title text-center">
                      <h2 className="title text_color_white">
                        Start Your Journey with Power AI
                      </h2>
                      <p className="desc text_color_light_white">
                        Unleash the power of AI-driven creativity and elevate
                        your projects to new heights.
                      </p>
                    </div>
                    <div className="two-btn-wrap d-flex flex-wrap justify-content-center">
                      <a
                        className="common-btn bg-blue-style"
                        href="service.html"
                      >
                        Get Started Now
                      </a>
                    </div>
                    <img
                      className="position-absolute z-index-minus-one start-0 top-0 h-100 w-100"
                      src={require("./assets/images/shapes/noise.png")}
                      alt=""
                    />
                    <svg
                      className="arrow-left-img position-absolute z-index-minus-one start-0"
                      xmlns="http://www.w3.org/2000/svg"
                      width={487}
                      height={282}
                      viewBox="0 0 487 282"
                      fill="none"
                    >
                      <path
                        d="M-75.0925 0.559411C-52.8054 39.8517 -31.3707 61.2603 11.3733 76.1087C49.9737 89.5177 98.4011 87.9239 138.902 88.8366C189.046 89.9666 279.836 111.385 284.626 173.218C288.346 221.23 239.694 257.51 198.414 225.938C182.499 213.767 182.76 186.158 197.302 172.721C213.01 158.207 242.078 159.764 261.448 162.682C313.47 170.52 327.438 227.731 367.395 252.704C404.725 276.036 441.783 269.902 481.958 257.65C485.4 256.601 478.533 269.963 477.15 273.438C472.081 286.177 476.878 281.537 480.711 271.905C483.176 265.71 491.681 253.838 481.924 254.03C474.884 254.17 461.982 249.7 455.217 247.008"
                        stroke="white"
                        strokeOpacity="0.15"
                        strokeLinecap="round"
                      />
                    </svg>
                    <svg
                      className="arrow-right-img position-absolute z-index-minus-one end-0"
                      width={517}
                      height={195}
                      viewBox="0 0 517 195"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 157C61.5 125.5 318.5 141.5 360.051 30.6766C362.168 20.542 365.235 4.6987 351.721 1.61396C331.419 -3.02024 306.932 17.8011 293.133 29.3808C268.503 50.0502 247.589 75.6353 231.63 103.564C221.681 120.975 206.183 149.763 211.545 170.853C264.5 265.5 547.766 34.1925 558.5 24.5015"
                        stroke="white"
                        strokeOpacity="0.15"
                        strokeLinecap="round"
                      />
                      <path
                        d="M49.4261 161.822L1.5 159.502L34.0002 129.502"
                        stroke="white"
                        strokeOpacity="0.15"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Subscribe end */}
          <Footer />
        </div>
      </main>
    </>
  );
}

export default BlogPage;
