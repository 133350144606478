import React, { useEffect } from "react";
import { Button, Modal, Form, Input } from "antd";

const UpdateDomainModal = ({ visible, onCancel, onUpdate, initialValues }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(initialValues); // Reset form with new initial values
  }, [initialValues]);
  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        onUpdate(values);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  return (
    <Modal
      open={visible}
      title="Update Domain"
      onCancel={onCancel}
      styles={{ mask: { opacity: 0.3 } }}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="update" type="primary" onClick={handleUpdate}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="templateName"
          label="Template Name"
          rules={[{ required: true, message: "Please enter template name" }]}
        >
          <Input placeholder="Enter template name" />
        </Form.Item>
        <Form.Item
          name="domainName"
          label="Domain Name"
          rules={[{ required: true, message: "Please enter domain name" }]}
        >
          <Input placeholder="Enter domain name" />
        </Form.Item>
        {/* <Form.Item
          name="imageUrl"
          label="Image URL"
          rules={[{ required: true, message: "Please enter image URL" }]}
        >
          <Input placeholder="Enter image URL" />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default UpdateDomainModal;
