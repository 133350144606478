import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { authService } from "../api-service/authService";
import { get } from "lodash";
import Cookies from "universal-cookie";
import { userService } from "../api-service/userService";
import axios from "axios";
export const USERS_FEATURE_KEY = "users";
export const usersAdapter = createEntityAdapter();

export const login = createAsyncThunk(
  "users/login",
  async (input, thunkAPI) => {
    const { authClient, authQuery } = authService;
    input = { ...input, isMobile: false };
    const response = await authClient.mutate({
      mutation: authQuery.login,
      variables: input,
    });
    localStorage.setItem(
      "BuilderUser_data",
      JSON.stringify(get(response, "data.signIn", {}))
    );
    console.log("login Data", get(response, "data.signIn", {}));
    return response;
  }
);

export const signUp = createAsyncThunk(
  "users/signUp",
  async (input, thunkAPI) => {
    const { authClient, authQuery } = authService;
    console.log(input, "input slice");
    const response = await authClient.mutate({
      mutation: authQuery.signUp,
      variables: input,
    });
    localStorage.setItem(
      "BuilderUser_data",
      JSON.stringify(get(response, "data.signUp", {}))
    );
    console.log(response.data);
    return response;
  }
);

export const verifyOtp = createAsyncThunk(
  "users/verifyOtp",
  async (createAuthInput, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const data = await authClient.mutate({
      mutation: authQuery.verifyOtp,
      variables: { createAuthInput },
    });
    localStorage.setItem(
      "OnboardUser_id",
      get(data, "data.getToken.user._id", "")
    );
    return data;
  }
);
export const getWorkflowRunsByRepoFullName = createAsyncThunk(
  "users/getWorkflowRunsByRepoFullName",
  async (repoFullName, thunkAPI) => {
    // const workspaceId = get(
    //   thunkAPI.getState(),
    //   "workspace.currentWorkspace",
    //   ""
    // );
    const { userClient, userQuery } = userService;
    const workflowRuns = await userClient.query({
      query: userQuery.getWorkflowRunsByRepoFullName,
      variables: {
        repoFullName: repoFullName ?? "",
        workspaceId: "6598090975cacaf7c04cb11b",
      },
      fetchPolicy: "no-cache",
    });
    return workflowRuns;
  }
);
export const listAuthUserRepos = createAsyncThunk(
  "users/listAuthUserRepos",
  async (repoFullName, thunkAPI) => {
    // const workspaceId = get(
    //   thunkAPI.getState(),
    //   "workspace.currentWorkspace",
    //   ""
    // );
    const { userClient, userQuery } = userService;
    const repo = await userClient.mutate({
      mutation: userQuery.listAuthUserRepos,
      variables: {
        userAccount: "Github",
        workspaceId: "6598090975cacaf7c04cb11b",
      },
      fetchPolicy: "no-cache",
    });
    return repo;
  }
);
export const createTemplate = createAsyncThunk(
  "users/createTemplate",
  async (data, thunkAPI) => {
    try {
      const userId = get(thunkAPI.getState(), "users.userData._id", "");
      const { authClient, authQuery } = authService;
      const repo = await authClient.mutate({
        mutation: authQuery.createTemplate,
        variables: {
          userId: userId,
          templateName: data.templateName,
          domain: data.domain,
          id: data.id,
        },
      });
      console.log("users/createTemplate", repo);
      return repo;
    } catch (error) {
      console.log("my error", error);
      return null;
    }
  }
);
export const deleteTemplateById = createAsyncThunk(
  "users/deleteTemplateById",
  async (data, thunkAPI) => {
    try {
      const { authClient, authQuery } = authService;
      const repo = await authClient.mutate({
        mutation: authQuery.deleteTemplateById,
        variables: data,
      });
      console.log("users/deleteTemplateById", repo);
      thunkAPI.dispatch(getAuthUserTemplates());
      return repo;
    } catch (error) {
      console.log("my error", error);
      return null;
    }
  }
);
export const getTemplateDetails = createAsyncThunk(
  "users/getTemplateDetails",
  async (data, thunkAPI) => {
    console.log("users/getTemplateDetails");
    try {
      const { authClient, authQuery } = authService;
      const repo = await authClient.query({
        query: authQuery.getTemplateDetails,
        fetchPolicy: "no-cache",
      });
      console.log("users/getTemplateDetails", repo);
      return repo;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getAuthUserTemplates = createAsyncThunk(
  "users/getAuthUserTemplates",
  async (data, thunkAPI) => {
    const userId = get(thunkAPI.getState(), "users.userData._id", "");
    // console.log("users/getAuthUserTemplates", userId, thunkAPI.getState());
    if (userId !== "") {
      const { authClient, authQuery } = authService;
      const repo = await authClient.query({
        query: authQuery.getAuthUserTemplates,
        variables: { templateId: "", userId: userId },
        fetchPolicy: "no-cache",
      });
      return repo;
    } else {
      return null;
    }
  }
);
export const getTemplate = createAsyncThunk(
  "users/getTemplate",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.query({
      query: authQuery.getTemplate,
      variables: data,
      fetchPolicy: "no-cache",
    });
    // console.log("users/getTemplate", repo);
    return repo;
  }
);
export const getTemplateDetailsByDomain = createAsyncThunk(
  "users/getTemplateDetailsByDomain",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.query({
      query: authQuery.getTemplateDetailsByDomain,
      variables: data,
      fetchPolicy: "no-cache",
    });
    // console.log("users/getTemplateDetailsByDomain", repo);
    return repo;
  }
);
export const updateTemplate = createAsyncThunk(
  "users/updateTemplate",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.mutate({
      mutation: authQuery.updateTemplate,
      variables: data,
    });
    console.log("users/getTemplate", repo);
    return repo;
  }
);
export const updateDomain = createAsyncThunk(
  "users/updateDomain",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.mutate({
      mutation: authQuery.updateDomain,
      variables: data,
    });
    console.log("users/updateDomain", repo);
    return repo;
  }
);
export const resetTemplate = createAsyncThunk(
  "users/resetTemplate",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.mutate({
      mutation: authQuery.resetTemplate,
      variables: data,
    });
    console.log("users/resetTemplate", repo);
    return repo;
  }
);
export const uploadFile = async (files) => {
  const formData = new FormData();
  formData.append("file", files);
  const response = await axios.post(
    `${process.env.REACT_APP_AUTH_API}/upload/file-upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};
export const BlogData = async () => {
  try {
    const response = await axios.get(
      "https://linkites.com/wp-json/custom/v1/posts"
    );
    console.log("BlogData", response);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getInTouch = createAsyncThunk(
  "users/getInTouch",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.mutate({
      mutation: authQuery.getInTouch,
      variables: data,
    });
    console.log("users/getInTouch", repo);
    return repo;
  }
);
export const subscribeUser = createAsyncThunk(
  "users/subscribeUser",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const repo = await authClient.mutate({
      mutation: authQuery.subscribeUser,
      variables: data,
    });
    console.log("users/subscribeUser", repo);
    return repo;
  }
);
export const getEnquiryData = createAsyncThunk(
  "users/getEnquiryData",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const enquiry = await authClient.mutate({
      mutation: authQuery.getEnquiryData,
      variables: data,
    });
    console.log("users/getEnquiryData", enquiry);
    return enquiry;
  }
);

export const getSubscribeData = createAsyncThunk(
  "users/getSubscribeData",
  async (data, thunkAPI) => {
    const { authClient, authQuery } = authService;
    const enquiry = await authClient.mutate({
      mutation: authQuery.getSubscribeData,
      variables: data,
    });
    console.log("users/getSubscribeData", enquiry);
    return enquiry;
  }
);
export const initialUsersState = usersAdapter.getInitialState({
  loadingStatus: "not loaded",
  error: "",
  userData: {},
  pipelineList: [],
  repolist: [],
  userTemplate: [],
  autoscrollSection: "",
  autoRoute: null,
  template: {},
  templateName: "",
  // template: {
  //   templateID: null,
  //   logo: null,
  //   favIcon: null,
  //   navigation: [
  //     {
  //       key: "Home",
  //       navigationTitle: "Demo",
  //       browserTitle: "demo",
  //       urlSlug: `${process.env.REACT_APP_HOST_URL}/`,
  //       heroSection: {
  //         backgroundImg: null,
  //         subTitle: "SOAR TO NEW HEIGHTS!",
  //         headingTitle: "Elevate Your Experience With Power AI",
  //         shortDesc:
  //           "Unleash the next level of innovation and efficiency with Power AI, transforming your digital journey into a realm of unparalleled possibilities.",
  //         button1Title: "Get Started",
  //         button1Url: "",
  //         button2Title: "Watch Demo",
  //         button2Url: "",
  //         heroImg: null,
  //       },
  //       organizationSection: {
  //         organizationLogo: [],
  //         description:
  //           "Power AI is used by over 15,000+ companies across the globe",
  //       },
  //       aboutUsSection: {
  //         heading: "WHO WE ARE",
  //         subHeading: "About Power AI",
  //         firstDescription:
  //           "At Power AI, we are on a mission to revolutionize industries and redefine possibilities through Artificial Intelligence.",
  //         secondDescription:
  //           "With a team of passionate experts, we combine innovation, expertise, and a commitment to excellence to create intelligent solutions.",
  //       },
  //       serviceSection: {
  //         title: "Our Services",
  //         subTitle:
  //           "Embark on a personalized journey of innovation with our transformation AI solutions, tailored to meet your unique needs and drive unparalleled success.",
  //         services: [
  //           {
  //             serviceTitle: "Data Analytics",
  //             serviceLogo: null,
  //             serviceDescription:
  //               "Unlocking insights and patterns from vast datasets, data analytics empowers informed decision-making and drives innovation.",
  //           },
  //           {
  //             serviceTitle: "Training And Workshops",
  //             serviceLogo: null,
  //             serviceDescription:
  //               "We specialize in custom AI development to precisely meet and exceed the unique needs of your organization, ushering in a future.",
  //           },
  //           {
  //             serviceTitle: "Custom AI Design",
  //             serviceLogo: null,
  //             serviceDescription:
  //               "Tailoring artificial intelligence to specific needs, custom AI design merges creativity with cutting-edge technology to address unique challenges.",
  //           },
  //           {
  //             serviceTitle: "AI Integration",
  //             serviceLogo: null,
  //             serviceDescription:
  //               "Seamlessly blending artificial intelligence into existing systems, AI integration optimizes, and opens new frontiers for advanced solving.",
  //           },
  //           {
  //             serviceTitle: "Custom AI Development",
  //             serviceLogo: null,
  //             serviceDescription:
  //               "We specialize in custom AI development to precisely meet and exceed the unique needs of your organization, ushering in a future.",
  //           },
  //           {
  //             serviceTitle: "AI Consulting",
  //             serviceLogo: null,
  //             serviceDescription:
  //               "We specialize in custom AI development to precisely meet and exceed the unique needs of your organization, ushering in a future.",
  //           },
  //         ],
  //       },
  //       industriesSection: {
  //         title: "Industries We Serve",
  //         subTitle:
  //           "We empower diverse sectors with intelligent solutions that seamlessly integrate into a spectrum of industries, driving innovation across the board.",
  //         industries: [
  //           {
  //             image: null,
  //             heading: "REVOLUTIONIZING RETAIL",
  //             description:
  //               "Elevate Experiences, Forecast Demand, Personalize Marketing",
  //             shortDesc:
  //               "Revolutionizing retail with enhanced experiences, demand forecasting, and personalized marketing for dynamic success",
  //           },
  //           {
  //             image: null,
  //             heading: "FINCH TRIUMPH",
  //             description:
  //               "Smart Detection, Algorithmic Trading, And Data Insights",
  //             shortDesc:
  //               "In finance, smart detection, algorithmic trading, and data insights converge for seamless decision-making and strategic market moves.",
  //           },
  //           {
  //             image: null,
  //             heading: "REVOLUTIONIZING HEALTHCARE",
  //             description:
  //               "Harnessing AI For Diagnostics And Personalized Treatments",
  //             shortDesc:
  //               "Revolutionizing healthcare with AI-driven diagnostics and personalized treatments for a more precise and innovative medical experience. we reliantly strive to bring about transformative changes in the healthcare sector",
  //           },
  //         ],
  //       },
  //       achievementSection: {
  //         title: "See Our Achievement",
  //         accuracy: "98%",
  //         globalUsers: "5M +",
  //         accuracyMill:"2M +",
  //         secondDescription:
  //           "With a team of passionate experts, we combine innovation, expertise, and a commitment to excellence to create intelligent solutions.",
  //       },
  // resourceSection: {
  //   title: "Our Useful Resources",
  //   subTitle:
  //     "Dive into the forefront of innovation and thought leadership with our latest resources. Where insightful content meets the pulse of contemporary ideas, keeping you informed and inspired.",
  //   resources: [
  //     {
  //       title: "A Journey Into The Heart Of Innovation",
  //       image: null,
  //       shortDesc:
  //         "Artificial Intelligence (AI) has transcended the realm of science fiction, becoming a pervasive force that...",
  //       buttonName: "Read More",
  //       buttonUrl: "",
  //     },
  //     {
  //       title: "The Intersection between Technology and Art",
  //       image: null,
  //       shortDesc:
  //         "Contrary to popular belief, AI is not just algorithms and data; it's also a powerful tool for unleashing...",
  //       buttonName: "Read More",
  //       buttonUrl: "",
  //     },
  //     {
  //       title: "Tackle Global Challenges with Technology",
  //       image: null,
  //       shortDesc:
  //         "Explore the inspiring initiatives where AI is harnessed for the greater good. From addressing environment...",
  //       buttonName: "Read More",
  //       buttonUrl: "",
  //     },
  //     {
  //       title: "A Journey Into The Heart Of Innovation",
  //       image: null,
  //       shortDesc:
  //         "Artificial Intelligence (AI) has transcended the realm of science fiction, becoming a pervasive force that...",
  //       buttonName: "Read More",
  //       buttonUrl: "",
  //     },
  //     {
  //       title: "The Intersection between Technology and Art",
  //       image: null,
  //       shortDesc:
  //         "Contrary to popular belief, AI is not just algorithms and data; it's also a powerful tool for unleashing...",
  //       buttonName: "Read More",
  //       buttonUrl: "",
  //     },
  //     {
  //       title: "Tackle Global Challenges with Technology",
  //       image: null,
  //       shortDesc:
  //         "Explore the inspiring initiatives where AI is harnessed for the greater good. From addressing environment...",
  //       buttonName: "Read More",
  //       buttonUrl: "",
  //     },
  //   ],
  // },
  //       commentSection: {
  //         title: "What our users are saying",
  //         subTitle:
  //           "Explore the authentic voices of user satisfaction in our testimonial section. Echoing diverse and exceptional experiences with our products.",
  //         comments: [
  //           {
  //             rating: 5,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //           {
  //             rating: 3,
  //             comment:
  //               "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
  //             name: "Adam Zampol",
  //             address: "CEO Kingoston Corps, USA",
  //           },
  //           {
  //             rating: 2,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //           {
  //             rating: 5,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //           {
  //             rating: 3,
  //             comment:
  //               "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
  //             name: "Adam Zampol",
  //             address: "CEO Kingoston Corps, USA",
  //           },
  //           {
  //             rating: 2,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //         ],
  //       },
  //       faqSection: {
  //         title: "Frequently Asked Questions",
  //         subTitle:
  //           'Explore the answers you seek in our "Frequently Asked Questions" section, your go-to resource for quick insights into the world of artificial intelligence.',
  //         shortDesc:
  //           "From understanding AI applications to learning about our services, we've condensed the information you need to kickstart your exploration of this transformation technology.",
  //         faqs: [
  //           {
  //             question: "How Can I Get Started With Power AI's Services?",
  //             answer:
  //               "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
  //           },
  //           {
  //             question: "What Is Artificial Intelligence (AI)?",
  //             answer:
  //               "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
  //           },
  //           {
  //             question: "What Services Does Power AI Provide?",
  //             answer:
  //               "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
  //           },
  //           {
  //             question: "Is Power AI Suitable For Small Businesses?",
  //             answer:
  //               "Getting started is easy. Simply contact our team through our website or reach out to our customer support. We'll discuss your specific needs and tailor a solution that aligns with your business goals.",
  //           },
  //         ],
  //       },
  //       epilogue: {
  //         title: "Start Your Journey With Power AI",
  //         subTitle:
  //           "Embark on a transformation journey into the future of innovation as you kickstart your exploration of Power AI, where limitless possibilities await at every click and command.",
  //         button1Title: "Get Started",
  //         button1Url: "",
  //         button2Title: "Contact Us",
  //         button2Url: "",
  //       },
  //       contactSection: {
  //         title: "Our Team Is Ready To Help You",
  //         description:
  //           "Our dedicated team is prepared and eager to provide you with the assistance you need, ensuring your success and satisfaction. Feel free to reach out anytime for our support.",
  //         info: [
  //           {
  //             title: "Our Address",
  //             description: "218 Thornirdge Cir. Syracuse, California 208434",
  //           },
  //           {
  //             title: "Contact Info",
  //             description:
  //               "Open a chat or give us a call at +088 (246) 642-27-10",
  //           },
  //           {
  //             title: "Live Support",
  //             description: "Live chat service example@gmail.com",
  //           },
  //         ],
  //         imageUrl: null,
  //       },
  //     },
  //     {
  //       key: "About",
  //       navigationTitle: "About",
  //       browserTitle: "about",
  //       urlSlug: `${process.env.REACT_APP_HOST_URL}/about`,
  //       breadcrumbSection: {
  //         title: "HOME/ABOUT US",
  //         headingTitle: "About Us",
  //         description:
  //           "We will help a client's problems to develop the product s they have with high quality Change the appearance.",
  //         backgroundImg: null,
  //       },
  //       commitmentSection: {
  //         title: "Our Commitment To Deliver The Best AI Model",
  //         description:
  //           "Dedicated to excellence, our commitment echoes through every line of code as we tirelessly strive to deliver the epitome of artificial intelligence models",
  //         backgroundImg: null,
  //       },
  //       commentSection: {
  //         title: "What our users are saying",
  //         subTitle:
  //           "Explore the authentic voices of user satisfaction in our testimonial section. Echoing diverse and exceptional experiences with our products.",
  //         comments: [
  //           {
  //             rating: 5,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //           {
  //             rating: 3,
  //             comment:
  //               "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
  //             name: "Adam Zampol",
  //             address: "CEO Kingoston Corps, USA",
  //           },
  //           {
  //             rating: 2,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //           {
  //             rating: 5,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //           {
  //             rating: 3,
  //             comment:
  //               "Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!",
  //             name: "Adam Zampol",
  //             address: "CEO Kingoston Corps, USA",
  //           },
  //           {
  //             rating: 2,
  //             comment:
  //               "Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!",
  //             name: "Sarah Leon",
  //             address: "Business Owner, Dubai",
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       key: "Service",
  //       navigationTitle: "Use Cases",
  //       browserTitle: "Service",
  //       urlSlug: `${process.env.REACT_APP_HOST_URL}/service`,
  //     },
  //     {
  //       key: "Blog",
  //       navigationTitle: "Blog",
  //       browserTitle: "blog",
  //       urlSlug: `${process.env.REACT_APP_HOST_URL}/blog`,
  //     },
  //     {
  //       key: "FAQ",
  //       navigationTitle: "FAQ",
  //       browserTitle: "faq",
  //       urlSlug: `${process.env.REACT_APP_HOST_URL}/faq`,
  //     },
  //     {
  //       key: "Contact",
  //       navigationTitle: "Contact",
  //       browserTitle: "contact",
  //       urlSlug: `${process.env.REACT_APP_HOST_URL}/contact`,
  //     },
  //   ],

  // footerSection: {
  //   footerLogo: null,
  //   description: `At Power AI, we offer affordable and comprehensive range of AI
  //   solutions, that empower drive growth, and enhance efficiency
  //   to meet your unique needs.`,
  //   socialLink: {
  //     facebook: "https://www.facebook.com/Linkites/",
  //     twitter: "https://twitter.com/linkites_T",
  //     linkedin: "https://www.linkedin.com/company/linkites-infotech-pvt-ltd?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A6599630%2Cidx%3A3-2-4%2CtarId%3A1455168175039%2Ctas%3Alinkites",
  //     instagram: "https://www.instagram.com/linkites_in/",
  //     youtube: "https://youtube.com/@linkitescorporations4030",
  //   },
  //   copyRight:"© Copyright 2024 Linkites. Inc.",
  //   termUrl:"https://linkites.com/terms-and-conditions/",
  //   privacyUrl:"https://linkites.com/privacy-policy/"
  // },
  // },
  defaultTemplate: [],
  domainName: "",
});

export const usersSlice = createSlice({
  name: USERS_FEATURE_KEY,
  initialState: initialUsersState,
  reducers: {
    add: usersAdapter.addOne,
    remove: usersAdapter.removeOne,
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setInitial: (state, action) => {
      state.template = action.payload;
    },
    setTemplateName: (state, action) => {
      state.templateName = action.payload;
    },
    setDomainName: (state, action) => {
      state.domainName = action.payload;
    },
    setTemplateId: (state, action) => {
      state.template.templateID = action.payload;
    },
    setNavigationTitle: (state, action) => {
      state.template.navigation[action.payload?.key].navigationTitle =
        action.payload.value;
    },
    setBrowserTitle: (state, action) => {
      state.template.navigation[action.payload?.key].browserTitle =
        action.payload.value;
    },
    setUrlSlug: (state, action) => {
      state.template.navigation[action.payload?.key].urlSlug =
        action.payload.value;
    },
    setLogo: (state, action) => {
      state.template.logo = action.payload;
    },
    setFavIcon: (state, action) => {
      state.template.favIcon = action.payload;
    },
    setNavigationData: (state, action) => {
      const { key, updatedObject } = action.payload;
      const index = state.template.navigation.findIndex(
        (item) => item.key === key
      );
      if (index !== -1) {
        state.template.navigation[index] = updatedObject;
      }
    },
    setFooterLogo: (state, action) => {
      state.template.footerSection.footerLogo = action.payload;
    },
    setDescription: (state, action) => {
      state.template.footerSection.description = action.payload;
    },
    setSocialLink: (state, action) => {
      state.template.footerSection.socialLink[action.payload.key] =
        action.payload.value;
    },
    setAutoScroll: (state, action) => {
      state.autoscrollSection = action.payload;
    },
    setAutoRoute: (state, action) => {
      state.autoRoute = action.payload;
    },
    setCopyRight: (state, action) => {
      state.template.footerSection.copyRight = action.payload;
    },
    setTermsUrl: (state, action) => {
      state.template.footerSection.termUrl = action.payload;
    },
    setPrivacyUrl: (state, action) => {
      state.template.footerSection.privacyUrl = action.payload;
    },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // testRedux: (state, action) => {
    //   console.log("test>>>>>>>", action.payload);
    // },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(login.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loadingStatus = "loaded";
        state.userData = get(action, "payload.data.signIn");
      })
      .addCase(login.rejected, (state, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loadingStatus = "loaded";
        const cookies = new Cookies();
        cookies.set(
          "stg-token",
          get(action, "payload.data.getToken.token", ""),
          {
            maxAge: 60 * 60 * 24 * 364,
            // domain: `.${env.NX_ONETAB_DOMAIN}`,
            sameSite: "none",
            secure: true,
          }
        );
        state.userData = get(action, "payload.data.getToken.user", "");
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message;
      })
      .addCase(getWorkflowRunsByRepoFullName.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(getWorkflowRunsByRepoFullName.fulfilled, (state, action) => {
        state.loadingStatus = "loaded";
        state.pipelineList = get(
          action,
          "payload.data.getWorkflowRunsByRepoFullName",
          ""
        );
      })
      .addCase(getWorkflowRunsByRepoFullName.rejected, (state, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(listAuthUserRepos.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(listAuthUserRepos.fulfilled, (state, action) => {
        state.loadingStatus = "loaded";
        console.log(get(action, "payload.data.listAuthUserRepos", ""));
        state.repolist = get(action, "payload.data.listAuthUserRepos", "");
      })
      .addCase(listAuthUserRepos.rejected, (state, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(getTemplateDetails.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(getTemplateDetails.fulfilled, (state, action) => {
        state.loadingStatus = "loaded";
        state.defaultTemplate = get(
          action,
          "payload.data.getTemplateDetails",
          ""
        );
      })
      .addCase(getTemplateDetails.rejected, (state, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message;
      })
      .addCase(getAuthUserTemplates.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(getAuthUserTemplates.fulfilled, (state, action) => {
        state.loadingStatus = "loaded";
        state.userTemplate = get(
          action,
          "payload.data.getAuthUserTemplates",
          ""
        );
      })
      .addCase(getAuthUserTemplates.rejected, (state, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const usersReducer = usersSlice.reducer;
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(usersActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const usersActions = usersSlice.actions;
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllUsers);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = usersAdapter.getSelectors();
export const getUsersState = (rootState) => rootState[USERS_FEATURE_KEY];
export const selectAllUsers = createSelector(getUsersState, selectAll);
export const selectUsersEntities = createSelector(
  getUsersState,
  selectEntities
);
