import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/common";
import Template from "./../../images/template.png";

import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTemplateById,
  getAuthUserTemplates,
  getEnquiryData,
  getSubscribeData,
  getUsersState,
  updateDomain,
} from "../../models/slice/users.slice";
import { DotsVertical } from "../../components/svg-icons";
import { Button, Popconfirm, Popover } from "antd";
import UpdateDomainModal from "./updateModal";
import { Table } from "antd";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { userTemplate } = useSelector((rootState) => getUsersState(rootState));
  const [visible, setVisible] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTab, setSelectedTab] = useState("landing");
  const [enquiry, setEnquiry] = useState([]);
  const [subscribe, setSubscribe] = useState([]);
  const navigate = useNavigate();
  const popoverRef = useRef();

  useEffect(() => {
    dispatch(getAuthUserTemplates());
  }, []);

  useEffect(() => {
    if (selectedTab === "enquiry") {
      dispatch(getEnquiryData()).then((response) => {
        if (response?.payload?.data?.getEnquiryData) {
          setEnquiry(response?.payload?.data?.getEnquiryData);
        }
      });
    } else {
      dispatch(getSubscribeData()).then((response) => {
        if (response?.payload?.data?.getSubscribeData) {
          setSubscribe(response?.payload?.data?.getSubscribeData);
        }
      });
    }
  }, [selectedTab]);

  const confirm = (_id) => {
    dispatch(deleteTemplateById({ id: _id }));
  };
  const handleCancel = () => {
    setSelectedTemplate(null);
    setModalVisible(false);
  };

  const handleUpdate = (values) => {
    if (
      values.templateName !== selectedTemplate.templateName ||
      values.domainName !== selectedTemplate.domainName
    ) {
      dispatch(
        updateDomain({
          templateId: selectedTemplate.templateId,
          domain: values.domainName,
          templateName: values.templateName,
        })
      )
        .then((response) => {
          if (response?.payload?.data?.updateDomain) {
            dispatch(getAuthUserTemplates());
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setSelectedTemplate(null);
    setModalVisible(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !event.target.closest(".ant-popover-content")
      ) {
        setVisible(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderLandingPages = () => (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row">
            <div className="page__header--left">
              <h1 className="page__header--title">Your Landing Pages</h1>
              <p className="page__header--subtitle">
                Explore and manage your landing pages
              </p>
            </div>
            <div className="page__header--right">
              <Link to="/create" className="btn btn-md btn-global-primary">
                Create New Template
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-wrapper">
        <div className="grid-list">
          {userTemplate.length > 0 ? (
            userTemplate.map((item, index) => (
              <div className="grid-list-item" key={item._id}>
                <div className="grid-card">
                  <div className="grid-card-thumb">
                    <img
                      src={item?.imageUrl ?? Template}
                      alt="Landing Page Thumbnail"
                    />
                  </div>
                  <div className="grid-card-info">
                    <div style={{ position: "absolute", right: "20px" }}>
                      <div ref={popoverRef}>
                        <Popover
                          open={visible === index}
                          content={
                            <div>
                              <p
                                onClick={() => {
                                  navigate(`/editor/${item._id}`);
                                }}
                              >
                                Edit Template
                              </p>
                              <p
                                onClick={() => {
                                  setSelectedTemplate({
                                    templateName: item.templateName,
                                    domainName: item.domain,
                                    templateId: item._id,
                                  });
                                  setVisible(-1);
                                  setModalVisible(true);
                                }}
                              >
                                Update Domain
                              </p>
                              <Popconfirm
                                title="Delete the Template"
                                description="Are you sure to delete this Template?"
                                onConfirm={() => confirm(item._id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <p>Delete Template</p>
                              </Popconfirm>
                            </div>
                          }
                          trigger="click"
                        >
                          <Button
                            type="text"
                            icon={<DotsVertical />}
                            onClick={() => setVisible(index)}
                          />
                        </Popover>
                      </div>
                    </div>
                    <div className="grid-card-title">
                      {item?.templateName ?? ""}
                    </div>
                    <a
                      className="grid-card-domain"
                      href="#"
                      onClick={() =>
                        window.open(`http://${item.domain}`, "_blank")
                      }
                    >
                      {item?.domain ?? ""}
                    </a>
                    <div className="grid-card-timestamp">
                      {`Created at ${
                        new Date(item?.createdAt).toLocaleString() ?? ""
                      }`}
                    </div>
                  </div>
                </div>
                <UpdateDomainModal
                  visible={modalVisible}
                  onCancel={handleCancel}
                  onUpdate={handleUpdate}
                  initialValues={selectedTemplate}
                />
              </div>
            ))
          ) : (
            <div>No Recent Templates Found</div>
          )}
        </div>
      </div>
    </>
  );

  const renderEnquiries = () => (
    <div className="container-wrapper enquiry-wrapper">
      <Table
        bordered
        dataSource={enquiry}
        pagination={{ pageSize: 10 }}
        tableLayout="fixed"
      >
        <Table.Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
          width={150}
        />
        <Table.Column
          title="Last Name"
          dataIndex="lastName"
          key="lastName"
          width={150}
        />
        <Table.Column
          title="Email"
          dataIndex="email"
          key="email"
          width={200}
        />
        <Table.Column
          title="Domain"
          dataIndex="domain"
          key="domain"
          width={150}
        />
        <Table.Column
          title="Mobile Number"
          dataIndex="mobileNumber"
          key="mobileNumber"
          width={150}
          render={(text, record) => `+${record.countryCode} ${text}`}
        />
        <Table.Column
          title="Message"
          dataIndex="message"
          key="message"
          width={300}
        />
        <Table.Column
          title="Created At"
          dataIndex="createdAt"
          key="createdAt"
          width={200}
          render={(text) => new Date(text).toLocaleString()}
        />
      </Table>
    </div>
  );

  const renderSubscribe = () => (
    <div className="container-wrapper subscribe-wrapper">
      <Table
        bordered
        dataSource={subscribe}
        pagination={{ pageSize: 10 }}
        tableLayout="fixed"
      >
        <Table.Column
          title="Email"
          dataIndex="email"
          key="email"
          width={200}
        />
        <Table.Column
          title="Domain"
          dataIndex="domain"
          key="domain"
          width={150}
        />
        <Table.Column
          title="Date Subscribed"
          dataIndex="createdAt"
          key="createdAt"
          width={200}
          render={(text) => new Date(text).toLocaleString()}
        />
      </Table>
    </div>
  );

  return (
    <>
      <Header />
      <div className="tabs__links--wrapper">
        <div className="tabs__links">
          <button
            className={`tabs__link ${
              selectedTab === "landing" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("landing")}
          >
            My Landing Pages
          </button>
          <button
            className={`tabs__link ${
              selectedTab === "enquiry" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("enquiry")}
          >
            Enquiries
          </button>
          <button
            className={`tabs__link ${
              selectedTab === "subscribe" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("subscribe")}
          >
            Subscribers
          </button>
        </div>
      </div>

      {selectedTab === "landing"
        ? renderLandingPages()
        : selectedTab === "enquiry"
        ? renderEnquiries()
        : renderSubscribe()}
    </>
  );
};
